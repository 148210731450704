import React, { useState } from 'react';

import ChangePassword from './ChangePassword/ChangePassword';
import CommunicationPreferences from './CommunicationPreferences/CommunicationPreferences';
import styles from './ProfileSettings.module.scss';
import CommunicationDevice from './CommunicationDevice/CommunicationDevice';
import {
  ENABLE_CHANGE_EMAIL,
  NOTIFICATIONS_ENABLE,
} from '../../../utils/environments_variables';
import { isIssuerDomain } from '../../../utils/banks';
import ChangeEmail from './ChangeEmail/ChangeEmail';

const ProfileSettings = () => {
  const [communicationDevices, setCommunicationDevices] = useState([]);
  const [preferences, setPreferences] = useState({
    MARKETING: false,
    PROMOTION: false,
    TIPS: false,
    CUSTOMER_NOTIFICATION: false,
    WHATSAPP: false,
  });
  const [checkBoxLoading, setCheckBoxLoading] = useState({
    MARKETING: true,
    PROMOTION: true,
    CUSTOMER_NOTIFICATION: true,
    WHATSAPP: true,
  });

  const isBmp = isIssuerDomain();

  return (
    <div className={styles.container}>
      {!isBmp && <ChangePassword />}

      {!isBmp && ENABLE_CHANGE_EMAIL && <ChangeEmail />}
      {/* <ChangePhoneNumber /> */}

      {NOTIFICATIONS_ENABLE && (
        <CommunicationPreferences
          communicationDevices={communicationDevices}
          preferences={preferences}
          setPreferences={setPreferences}
          checkBoxLoading={checkBoxLoading}
          setCheckBoxLoading={setCheckBoxLoading}
        />
      )}

      {NOTIFICATIONS_ENABLE && (
        <CommunicationDevice
          communicationDevices={communicationDevices}
          setCommunicationDevices={setCommunicationDevices}
          preferences={preferences}
          setPreferences={setPreferences}
        />
      )}
    </div>
  );
};

export default ProfileSettings;
