export const INSTRUCTION_1_TITLE = {
  en_US: 'Visible name and photo ',
  pt_BR: 'Nome e foto visíveis',
  es_ES: 'Nombre y foto visibles',
};

export const INSTRUCTION_1_DESCRIPTION = {
  en_US: 'Ensure the document clearly shows your photo and name.',
  pt_BR: 'Certifique-se de que o documento mostre claramente sua foto e nome.',
  es_ES: 'Asegúrate de que el documento muestre claramente tu foto y nombre.',
};

export const INSTRUCTION_2_TITLE = {
  en_US: 'Keep you face visible',
  pt_BR: 'Deixe o rosto visível',
  es_ES: 'Haz visible tu cara',
};

export const INSTRUCTION_2_DESCRIPTION = {
  en_US: 'Do not use accessories like hats, sunglasses, or masks.',
  pt_BR: 'Não use acessórios como bonés, óculos de sol ou máscara.',
  es_ES: 'No uses accesorios como gorras, lentes de sol o máscaras.',
};

export const INSTRUCTION_3_TITLE = {
  en_US: 'Choose a well-lit place',
  pt_BR: 'Procure um local iluminado',
  es_ES: 'Elige un lugar iluminado',
};

export const INSTRUCTION_3_DESCRIPTION = {
  en_US: 'Choose a well-lit place, free from shadows or direct sunlight',
  pt_BR: 'Escolha um local bem iluminado para tirar a foto.',
  es_ES: 'Elige un lugar bien iluminado, sin sombras ni luz solar directa.',
};

export const LABEL_BUTTON = {
  en_US: 'Submit photo',
  pt_BR: 'Enviar foto',
  es_ES: 'Enviar foto',
};

export const UPLOAD_TITLE = {
  en_US: 'Upload photo',
  pt_BR: 'Escolha sua foto',
  es_ES: 'Elige tu foto',
};

export const UPLOAD_DESCRIPTION = {
  en_US:
    'The chosen photo must be in JPG format. The maximum file size is 5MB.',
  pt_BR:
    'Os arquivos aceitos são PNG e JPG. O tamanho máximo do arquivo é 5MB.',
  es_ES:
    'Los archivos aceptados son PNG y JPG. El tamaño máximo del archivo es de 5MB.',
};
