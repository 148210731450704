export const es_nationalities = `
<option value=""> Nacionalidad *</option>
<option value="AF"> Afgano </option>
<option value="AL"> Albanés </option>
<option value="DE"> Alemán </option>
<option value="AD"> Andorrano </option>
<option value="AO"> Angoleño </option>
<option value="AI"> Anguilense </option>
<option value="AQ"> Antártida </option>
<option value="AG"> Antiguano </option>
<option value="TF"> Antillas Francesas </option>
<option value="AN"> Antillas Neerlandesas </option>
<option value="DZ"> Argelino </option>
<option value="AR"> Argentino </option>
<option value="AM"> Armeno </option>
<option value="AW"> Arubeño </option>
<option value="AC"> Ascension </option>
<option value="AU"> Australiano </option>
<option value="AT"> Austriaco </option>
<option value="AZ"> Azerbaiyano </option>
<option value="BS"> Bahamés </option>
<option value="BB"> Barbadense </option>
<option value="BH"> Bareiní </option>
<option value="BE"> Belga </option>
<option value="BZ"> Beliceño </option>
<option value="BJ"> Beninés </option>
<option value="BM"> Bermudeño </option>
<option value="BY"> Bielorruso </option>
<option value="MM"> Birmano </option>
<option value="GW"> Bisauguineano </option>
<option value="BD"> Blangladeshí </option>
<option value="BO"> Boliviano </option>
<option value="BA"> Bosnio </option>
<option value="BW"> Botsuano </option>
<option value="BR"> Brasileño </option>
<option value="GB"> Británico </option>
<option value="BN"> Bruneano </option>
<option value="BG"> Búlgaro </option>
<option value="BF"> Burkinés </option>
<option value="BI"> Burundés </option>
<option value="BT"> Butanés </option>
<option value="CV"> Caboverdiano </option>
<option value="KY"> Caimanés </option>
<option value="KH"> Camboyano </option>
<option value="CM"> Camerunés </option>
<option value="CA"> Canadiense </option>
<option value="CF"> Centroafricano </option>
<option value="TD"> Chadiano </option>
<option value="MP"> Chamorro </option>
<option value="CZ"> Checo </option>
<option value="CL"> Chileno </option>
<option value="CN"> Chino </option>
<option value="CY"> Chipriota </option>
<option value="LK"> Cingalés </option>
<option value="CC"> Cocano </option>
<option value="CO"> Colombiano </option>
<option value="KM"> Comorense </option>
<option value="CG"> Congoleño </option>
<option value="CK"> Cookiano </option>
<option value="CR"> Costarricense </option>
<option value="HR"> Croata </option>
<option value="CU"> Cubano </option>
<option value="CW"> Curazoleño </option>
<option value="DK"> Danés </option>
<option value="DO"> Dominicano </option>
<option value="DM"> Dominiqués </option>
<option value="GQ"> Ecuatoguineano </option>
<option value="EC"> Ecuatoriano </option>
<option value="EG"> Egipcio </option>
<option value="AE"> Emiratí </option>
<option value="ER"> Eritreo </option>
<option value="SK"> Eslovaco </option>
<option value="SI"> Esloveno </option>
<option value="ES"> Español </option>
<option value="US"> Estadounidense </option>
<option value="ET"> Etíope </option>
<option value="EE"> Estonio </option>
<option value="PH"> Filipino </option>
<option value="FI"> Finlandés </option>
<option value="FJ"> Fiyiano </option>
<option value="FR"> Francés </option>
<option value="GF"> Francoguayanés </option>
<option value="PF"> Francopolinesio </option>
<option value="FO"> Feroés </option>
<option value="GA"> Gabonés </option>
<option value="GM"> Gambiano </option>
<option value="GE"> Georgiano </option>
<option value="GH"> Ghanés </option>
<option value="GI"> Gibraltareño </option>
<option value="GD"> Granadino </option>
<option value="GR"> Griego </option>
<option value="GL"> Groenlandés </option>
<option value="GP"> Guadalupeño </option>
<option value="GU"> Guameño </option>
<option value="GT"> Guatemalteco </option>
<option value="GN"> Guineano </option>
<option value="GY"> Guyanés </option>
<option value="HT"> Haitiano </option>
<option value="HN"> Hondureño </option>
<option value="HK"> Hongkonés </option>
<option value="HU"> Húngaro </option>
<option value="IN"> Indiano </option>
<option value="ID"> Indonesio </option>
<option value="IQ"> Iraquí </option>
<option value="IE"> Irlandés </option>
<option value="IS"> Islandés </option>
<option value="YT"> Isla Mayotte </option>
<option value="WK"> Isla Wake </option>
<option value="FK"> Islas Falkland </option>
<option value="UM"> Islas Midway </option>
<option value="IL"> Israelí </option>
<option value="IT"> Italiano </option>
<option value="JM"> Jamaicano </option>
<option value="JP"> Japonés </option>
<option value="JO"> Jordano </option>
<option value="KZ"> Kazajo </option>
<option value="KE"> Keniano </option>
<option value="KG"> Kirguís </option>
<option value="KI"> Kiribatiano </option>
<option value="XK"> Kosovar </option>
<option value="KW"> Kuwaití </option>
<option value="LA"> Laosiano </option>
<option value="LS"> Lesotense </option>
<option value="LV"> Letón </option>
<option value="LB"> Libanés </option>
<option value="LR"> Liberiano </option>
<option value="LY"> Libio </option>
<option value="LI"> Liechtensteiniano </option>
<option value="LT"> Lituano </option>
<option value="LU"> Luxemburgués </option>
<option value="MO"> Macaense </option>
<option value="MK"> Macedonio </option>
<option value="MY"> Malasio </option>
<option value="MW"> Malauí </option>
<option value="MV"> Maldivo </option>
<option value="MG"> Malgache </option>
<option value="ML"> Maliense </option>
<option value="MT"> Maltés </option>
<option value="IM"> Manés </option>
<option value="CI"> Marfileño </option>
<option value="MA"> Marroquí </option>
<option value="MH"> Marshalés </option>
<option value="MQ"> Martiniqués </option>
<option value="MU"> Mauriciano </option>
<option value="MR"> Mauritano </option>
<option value="MX"> Mexicano </option>
<option value="FM"> Micronesio </option>
<option value="MD"> Moldavo </option>
<option value="MC"> Monegasco </option>
<option value="MN"> Mongol </option>
<option value="MS"> Monserratino </option>
<option value="MZ"> Mozambiqueño </option>
<option value="NA"> Namibio </option>
<option value="NR"> Nauruano </option>
<option value="CX"> Navideño </option>
<option value="NL"> Neerlandés </option>
<option value="NC"> Neocaledonio </option>
<option value="NZ"> Neozelandés </option>
<option value="NP"> Nepalí </option>
<option value="NI"> Nicaragüense </option>
<option value="NG"> Nigeriano </option>
<option value="NE"> Nigerino </option>
<option value="NU"> Niueño </option>
<option value="NF"> Norfolkense </option>
<option value="NO"> Noruego </option>
<option value="OM"> Omanés </option>
<option value="PK"> Pakistaní </option>
<option value="PW"> Palauano </option>
<option value="PA"> Panameño </option>
<option value="PG"> Papú </option>
<option value="PY"> Paraguayo </option>
<option value="PE"> Peruano </option>
<option value="PL"> Polaco </option>
<option value="PT"> Portugués </option>
<option value="PR"> Puertorriqueño </option>
<option value="QA"> Qatarí </option>
<option value="RE"> Reunionés </option>
<option value="RW"> Ruandés </option>
<option value="RO"> Rumano </option>
<option value="RU"> Ruso </option>
<option value="SP"> Saipanés </option>
<option value="SB"> Salomonense </option>
<option value="SV"> Salvadoreño </option>
<option value="AS"> Samoamericano </option>
<option value="SW"> Samoano </option>
<option value="KN"> San Cristóbal y Nieves </option>
<option value="SM"> Sanmarinense </option>
<option value="PM"> Sanpedrino</option>
<option value="LC"> Santaluciano </option>
<option value="ST"> Santotomense </option>
<option value="VC"> Sanvicentino </option>
<option value="SA"> Saudita </option>
<option value="SN"> Senegalés </option>
<option value="RS"> Serbio </option>
<option value="SC"> Seychellense </option>
<option value="SL"> Sierraleonés </option>
<option value="SG"> Singapurense </option>
<option value="SO"> Somalí </option>
<option value="SH"> St Helena </option>
<option value="SZ"> Suazi </option>
<option value="ZA"> Sudafricano </option>
<option value="SE"> Sueco </option>
<option value="CH"> Suizo </option>
<option value="KR"> Surcoreano </option>
<option value="SR"> Surinamés </option>
<option value="TH"> Tailandés </option>
<option value="TW"> Taiwanés </option>
<option value="TZ"> Tanzano </option>
<option value="TJ"> Tayiko </option>
<option value="TG"> Togolés </option>
<option value="TK"> Tokelauense </option>
<option value="TO"> Tongano </option>
<option value="TT"> Trinitario </option>
<option value="TR"> Turco </option>
<option value="TC"> Turcocaiqueño </option>
<option value="TM"> Turcomano </option>
<option value="TN"> Tunecino </option>
<option value="TV"> Tuvaluano </option>
<option value="UA"> Ucraniano </option>
<option value="UG"> Ugandés </option>
<option value="UY"> Uruguayo </option>
<option value="UZ"> Uzbeko </option>
<option value="VU"> Vanuatuense </option>
<option value="VA"> Vaticano </option>
<option value="VE"> Venezolano </option>
<option value="VG"> Virgenense británico </option>
<option value="VI"> Virgenense estadounidense </option>
<option value="VN"> Vietnamita </option>
<option value="WF"> Walisiano </option>
<option value="YE"> Yemení </option>
<option value="DJ"> Yibutiano </option>
<option value="YU"> Yugoslavo </option>
<option value="ZR"> Zaire </option>
<option value="ZM"> Zambiano </option>
<option value="ZT"> Zanzibarí </option>
<option value="ZW"> Zimbabuense </option>
`;
