import { Formik } from 'formik';
import {
  AccountDetailOCT,
  getInicialValuesOCT,
} from './OCTInitialValues/OCTInitialValues';
import { validationOctSchema } from './OCTValidationForm/OCTValidationForm';
import { IntlContext } from '../../../../../../../../intl';
import { RefObject, useContext, useEffect, useMemo, useState } from 'react';
import * as translations from './intl';
import styles from './oct.module.scss';
import Input from '../../../../../../../../components/Input/Input';
import { OCTSubmit } from './OCTSubmit/OCTSubmit';
import { StateContext } from '../../../../../../../../components/StateContextParent/StateContextParent';
import { AppInsightTrackContext } from '../../../../../../../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';
import useScript from './../../../../../../../../hooks/useScript';
import { API_TOKENEX_ENDPOINT } from '../../../../../../../../utils/environments_variables';
import { octService, userServices } from '../../../../../../../../services';
import ButtonWithIcon from '../../../../../../../../components/ButtonWithIcon/ButtonWithIcon';
import AddIcon from '../../../../../../../../assets/icons/AddIcon';
import InProcess from '../../../../../../../../assets/icons/InProcess';
import OCTChooseCard from './OCTChooseCard/OCTChooseCard';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import iconProcess_master from '../../../../../../../../assets/images/icon/svg_icon_process_master.svg';
import iconProcess_afluent from '../../../../../../../../assets/images/icon/svg_icon_process_afluent.svg';
import Loading from '../../../../../../../../components/Loading/Loading';
import ModalInformation from '../../../../../../../../components/ModalInformation/ModalInformation';
import { Payment } from '../../../../../../../../@types/APIs/Palla/PaymentMethod';
import HighLightNote from '../../../../../../../../components/HighlightNote/HighlightNote';
import { checkClaimsToReplacePaymentMethod } from '../oct-const';
import InfoLabelValue from '../../../../../../../../components/InfoLabelValue/InfoLabelValue';
import { formatCustomDate } from '../../../../../../../../utils/date';
import DashedContainer from '../../../../../../../../components/DashedContainer/DashedContainer';
import { removeRequiredCharLabel } from '../../../../../../../../utils/stringUtils';

interface Props {
  updateParent?: any;
  reimbursementSubmitRef: RefObject<HTMLButtonElement>;
  profileFlow?: boolean;
  profileSetPaymentID?: VoidFunction;
}

const OCTForm = ({
  updateParent,
  reimbursementSubmitRef,
  profileFlow,
  profileSetPaymentID,
}: Props) => {
  useScript(`${API_TOKENEX_ENDPOINT}/inpage/js/TokenEx-Lite.js`);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const { translate, idiomForApi, idiom } = useContext(IntlContext);
  const { actions, utils } = useContext(StateContext);
  const { isAfluent, getGlobalTheme } = useContext(ThemeContext);

  const [accountDetail, setAccountDetail] = useState<AccountDetailOCT>();
  const [paymentsDetail, setPaymentsDetail] = useState([]);
  const [paymentID, setPaymentID] = useState();
  const [loading, setLoading] = useState<boolean>(false);
  const [addCard, setAddCard] = useState<boolean>(false);
  const [canChangePaymentMethod, setCanChangePaymentMethod] = useState(false);

  const intl = translate(translations);
  const date = new Date();
  const theme = getGlobalTheme();
  const tokenexPublicKey = process.env.REACT_APP_TOKENEX_PUBLIC_KEY;
  const iconProcess = isAfluent() ? iconProcess_afluent : iconProcess_master;
  const binOrPan = utils.getBin(true);
  const idiomAPI = idiomForApi();

  const initialValues = useMemo(
    () => getInicialValuesOCT(accountDetail, paymentID, profileFlow),
    [accountDetail, paymentID]
  );

  const validationSchema = useMemo(
    () => validationOctSchema(intl),
    [accountDetail, intl]
  );

  const modalChooseCard = () => {
    actions.modal.showModal(
      intl.TEXT_CHANGE_CARD,
      true,
      <OCTChooseCard
        paymentID={paymentID}
        setPaymentID={setPaymentID}
        canChangePaymentMethod={canChangePaymentMethod}
      />,
      false,
      false,
      null,
      null,
      iconProcess
    );
  };

  useEffect(() => {
    const getAccountDetailPalla = async () => {
      const accountDetail = {} as AccountDetailOCT;
      let accountPayments = [] as Payment[];
      setLoading(true);

      try {
        const {
          data: { person },
        } = await userServices.getCHInfo(utils.getCn());

        trackEventUserAction('#### PERSON DETAIL  ###', {
          person,
        });

        accountDetail.person = person;
      } catch (error) {
        accountDetail.person = null;

        trackEventUserAction('#### ERROR - PERSON DETAIL  ###', {
          data: error && error.response && error.response.data,
          response: error && error.response,
          error,
        });
      }

      try {
        const {
          data: { user_detail, payments },
        } = await octService.getAccountDetailPalla();

        const defaultPayment = payments.find(payment => payment.default);

        accountDetail.user_detail = user_detail;
        accountDetail.payments = defaultPayment;
        accountPayments = JSON.parse(JSON.stringify(payments));

        trackEventUserAction('#### ACCOUNT DETAIL PALLA  ###', {
          palla: {
            user_detail,
            payments: defaultPayment,
          },
        });
      } catch (error) {
        accountDetail.user_detail = null;
        accountDetail.payments = null;

        trackEventUserAction('#### ERROR - ACCOUNT DETAIL PALLA  ###', {
          data: error && error.response && error.response.data,
          response: error && error.response,
          error,
        });
      }

      setAccountDetail({
        user_detail: accountDetail.user_detail,
        payments: accountDetail.payments,
        person: accountDetail.person,
      });
      setPaymentsDetail(accountPayments);
      setLoading(false);
    };

    getAccountDetailPalla();
    setAddCard(false);
  }, [paymentID]);

  useEffect(() => {
    checkClaimsToReplacePaymentMethod(
      utils.getCn(),
      binOrPan,
      idiomAPI,
      setCanChangePaymentMethod,
      trackEventUserAction
    );
  }, []);

  return (
    <div className={styles.container}>
      {loading ? (
        <Loading />
      ) : (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async values => {
            if (profileFlow) {
              await OCTSubmit(
                values,
                actions,
                intl,
                trackEventUserAction,
                tokenexPublicKey,
                setPaymentID,
                profileFlow,
                profileSetPaymentID
              );
            }
          }}
        >
          {props => {
            const { values, handleSubmit } = props;

            if (!profileFlow) {
              updateParent(values, validationSchema);
            }

            const paymentIdOCT = values.paymentIdOCT;
            return (
              <div>
                {!profileFlow && (
                  <>
                    {canChangePaymentMethod &&
                      paymentsDetail &&
                      paymentsDetail.length > 0 && (
                        <>
                          <div className={`${styles.controlArea}`}>
                            <div className={styles.btnOct}></div>
                            <div className={styles.btnOct}>
                              <ButtonWithIcon
                                type="blueWithoutBorder"
                                id="btnChooseCardOCT"
                                onClick={modalChooseCard}
                                value={intl.TEXT_CHANGE_CARD}
                                isSVGComponent={true}
                                img={<InProcess width={24} height={24} />}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    {!canChangePaymentMethod && (
                      <div className={styles.wrapperNote}>
                        <HighLightNote
                          note={intl.YOU_CANT_CHANGE_DIRECT_PAYMENT_NOW}
                        />
                      </div>
                    )}
                  </>
                )}

                <DashedContainer dashed={!profileFlow}>
                  {(addCard || profileFlow) && (
                    <div className={`${styles.marginContainer} row`}>
                      <div className="col-12">
                        <HighLightNote note={intl.NOTE_NEW_CARD} />
                      </div>
                    </div>
                  )}

                  <form onSubmit={handleSubmit}>
                    <button
                      ref={reimbursementSubmitRef}
                      type="submit"
                      style={{ display: 'none' }}
                    ></button>
                    <div>
                      <div className="row">
                        <div className="col-12 col-md-4">
                          <InfoLabelValue
                            label={intl.LABEL_FIRST_NAME}
                            value={values.name.first}
                          />
                        </div>

                        <div className="col-12 col-md-4">
                          <InfoLabelValue
                            label={intl.LABEL_LAST_NAME}
                            value={values.name.last}
                          />
                        </div>
                        <div className="col-12 col-md-4">
                          <InfoLabelValue
                            label={intl.LABEL_BIRTH_DATE}
                            value={formatCustomDate(
                              values.birth_date,
                              'YYYY-MM-DD',
                              idiom
                            )}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-4">
                          {paymentIdOCT ? (
                            <InfoLabelValue
                              label={intl.TEXT_LABEL_ADD_CARD}
                              value={values.card.number}
                            />
                          ) : (
                            <Input
                              value={values.card.number}
                              label={`${intl.TEXT_LABEL_ADD_CARD} *`}
                              name="card.number"
                              formikProps={props}
                              mask={
                                paymentIdOCT ? null : 'maskedCreditCard16Digits'
                              }
                              labelErrorClassName={styles.alertMessage}
                            />
                          )}
                        </div>

                        <div className="col-12 col-md-4">
                          {paymentIdOCT ? (
                            <InfoLabelValue
                              label={removeRequiredCharLabel(
                                intl.LABEL_EXPIRATION_DATE
                              )}
                              value={values.card.expirationDate}
                            />
                          ) : (
                            <Input
                              value={values.card.expirationDate}
                              label={intl.LABEL_EXPIRATION_DATE}
                              name="card.expirationDate"
                              type="text"
                              formikProps={props}
                              mask="maskedDate"
                            />
                          )}
                        </div>
                        <div className="col-12 col-md-4">
                          {paymentIdOCT ? (
                            <InfoLabelValue
                              label={'CVV'}
                              value={values.card.cvv}
                            />
                          ) : (
                            <Input
                              value={values.card.cvv}
                              label={'CVV *'}
                              name="card.cvv"
                              type="text"
                              formikProps={props}
                              mask={paymentIdOCT ? null : 'maskedCVC'}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </DashedContainer>
              </div>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default OCTForm;
