export const BUTTON_ADD_FILE = {
  en_US: 'Add file',
  pt_BR: 'Adicionar arquivo',
  es_ES: 'Agregar archivo',
};

export const LABEL_REQUIRED = {
  en_US: 'Required',
  pt_BR: 'Obrigatório',
  es_ES: 'Obligatorio',
};

export const LABEL_OPTIONAL = {
  en_US: 'Optional',
  pt_BR: 'Opcional',
  es_ES: 'Opcional',
};
