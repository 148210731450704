export const trip_assistance = 'Travel_Kit';
export const isTripAssistance = benefit =>
  benefit?.external_reference?.toLowerCase() === trip_assistance.toLowerCase();

export const findBenefitsByExternalReference = (claimType, benefits) => {
  return benefits.find(benefit => benefit.external_reference === claimType);
};

export const isVDBPBenefits = benefits => {
  const benefit = benefits[0];

  if (!benefit) {
    return false;
  }
  if (
    String(benefit.benefit_id).match(/[a-zA-Z]/g) &&
    typeof benefit.categories === 'string'
  ) {
    return false;
  }

  return true;
};

export const CINEPOLIS_GOLD = 'Cinepolis_Pass_169';
export const hasCinepolis = benefits =>
  benefits.find(benefit => benefit.benefit_id === CINEPOLIS_GOLD);
