export const NOTE_NEW_CARD = {
  en_US:
    'Your new Visa direct payment will automatically be considered as the primary payment method.',
  pt_BR:
    'Seu novo pagamento direto Visa será automaticamente considerado como o método de pagamento principal.',
  es_ES: `Tu nueva tarjeta de débito Visa se actualizará como el pago principal para recibir los depósitos de tus reclamos.`,
};

export const TEXT_REQUIRED_FORM = {
  en_US: 'Required field.',
  pt_BR: 'Campo obrigatório.',
  es_ES: 'Campo obligatorio.',
};

export const VALIDATE_DATE_VALID = {
  en_US: 'Please enter a valid date.',
  pt_BR: 'Por favor, insira uma data válida.',
  es_ES: 'Por favor, introduzca una fecha válida.',
};

export const TEXT_FORMAT_INVALID = {
  en_US: 'Invalid format, please check and try again.',
  pt_BR: 'Formato inválido, por favor verifique.',
  es_ES: 'El formato no es válido, por favor verifica.',
};

export const TEXT_NOT_VISA_PRODUCT = {
  en_US: 'The 16 digits provided do not correspond to a Visa card.',
  pt_BR: 'Os 16 dígitos informados não correspondem a um cartão Visa.',
  es_ES: 'Los 16 digitos ingresados no corresponden a una tarjeta Visa.',
};

export const TEXT_LABEL_ADD_CARD = {
  en_US: 'Visa Card number (16 digits)',
  pt_BR: 'Número do cartão Visa (16 dígitos)',
  es_ES: 'Número de la tarjeta de débito Visa (16 dígitos)',
};

export const LABEL_EXPIRATION_DATE = {
  en_US: 'Expiration date (MM/YY) *',
  es_ES: 'Fecha de vencimiento (MM/AA) *',
  pt_BR: 'Data de validade (MM/AA) *',
};

export const EXPIRATION_DATE = {
  en_US: 'Expiration date',
  es_ES: 'Fecha de vencimiento',
  pt_BR: 'Data de validade',
};

export const LABEL_FIRST_NAME = {
  en_US: 'Name',
  pt_BR: 'Nome',
  es_ES: 'Nombre',
};

export const LABEL_LAST_NAME = {
  en_US: 'Last name',
  pt_BR: 'Sobrenome',
  es_ES: 'Apellido',
};

export const LABEL_BIRTH_DATE = {
  en_US: 'Date of birth',
  pt_BR: 'Data de nascimento',
  es_ES: 'Fecha de nacimiento',
};

export const LOADING = {
  en_US: 'Loading...',
  pt_BR: 'Carregando...',
  es_ES: 'Cargando...',
};

export const LOADING_NUEVA_TARJETA = {
  en_US: 'Saving new Visa direct payment...',
  pt_BR: 'Salvando novo pagamento direto Visa...',
  es_ES: 'Guardando nueva tarjeta de débito Visa...',
};

export const SAVE = {
  en_US: 'Save',
  pt_BR: 'Salvar',
  es_ES: 'Guardar',
};

export const TEXT_CHANGE_CARD = {
  en_US: 'Choose another Visa direct payment',
  pt_BR: 'Escolha outro pagamento direto Visa',
  es_ES: 'Elige otra tarjeta de débito Visa',
};

export const BTN_SET_MAIN_CARD = {
  en_US: 'Set as main Visa direct payment',
  pt_BR: 'Definir como pagamento direto Visa principal',
  es_ES: 'Establecer como tarjeta principal',
};

export const CARD = {
  en_US: 'Card',
  pt_BR: 'Cartão',
  es_ES: 'Tarjeta',
};

export const SAVING = {
  en_US: 'Saving...',
  pt_BR: 'Salvando...',
  es_ES: 'Guardando...',
};

export const TEXT_ADD_NEW_CARD = {
  en_US: 'Add a new card',
  es_ES: 'Agregar una nueva tarjeta',
  pt_BR: 'Adicionar um novo cartão',
};

export const MAIN_ACCOUNT = {
  en_US: 'Main Visa direct payment',
  pt_BR: 'Pagamento direto Visa principal',
  es_ES: 'Tarjeta principal',
};

export const YOU_DONT_HAVE_CARD_PALLA = {
  en_US: `You don't have any registered Visa direct payment.`,
  pt_BR: `Você não possui nenhum pagamento direto Visa cadastrado.`,
  es_ES: `No tienes ninguna tarjeta de débito Visa registrada.`,
};

export const ERROR_409 = {
  en_US: `This card is already registered.`,
  pt_BR: `Este cartão já está cadastrado.`,
  es_ES: `Esta tarjeta de débito ya se encuentra registrada.`,
};

export const ERROR_409_SUBTITLE = {
  en_US: `Register a different card or select the one that is already registered.`,
  pt_BR: `Cadastre um cartão diferente ou selecione o que já está cadastrado.`,
  es_ES: `Registra una tarjeta diferente o selecciona la que ya está registrada.`,
};

export const UNEXPECTED_ERROR_OCCURRED_CARD = {
  en_US: 'An error occurred while registering your card.',
  pt_BR: 'Ocorreu um erro ao cadastrar o cartão.',
  es_ES: 'Se produjo un error al registrar tu tarjeta de débito Visa.',
};

export const UNEXPECTED_ERROR_OCCURRED_CARD_SUBTITLE = {
  en_US: 'Please, try again later.',
  pt_BR: 'Por favor, tente novamente mais tarde.',
  es_ES: 'Por favor, inténtalo de nuevo más tarde.',
};

export const UNEXPECTED_ERROR_OCCURRED_ACCOUNT = {
  en_US: 'An error occurred while registering your Visa direct payment.',
  pt_BR: 'Ocorreu um erro ao cadastrar seu pagamento direto Visa.',
  es_ES: 'Se produjo un error al registrar tu tarjeta de débito Visa.',
};

export const UNEXPECTED_ERROR_OCCURRED_ACCOUNT_SUBTITLE = {
  en_US: 'Please, try again later.',
  pt_BR: 'Por favor, tente novamente mais tarde.',
  es_ES: 'Por favor, inténtalo de nuevo más tarde.',
};

export const UNEXPECTED_ERROR_OCCURRED = {
  en_US:
    'An error occurred while updating your Visa direct payment information. Please try again later.',
  pt_BR:
    'Ocorreu um erro ao atualizar as informações do pagamento direto Visa. Por favor tente novamente mais tarde.',
  es_ES:
    'Se produjo un error al actualizar la información de tu tarjeta de débito Visa. Por favor, inténtalo de nuevo más tarde.',
};

export const ERROR_OFAC = {
  en_US: 'Registration failed due to restrictions.',
  pt_BR: 'Falha ao cadastrar devido a restrições.',
  es_ES: 'Error al registrar tu tarjeta de débito.',
};

export const ERROR_OFAC_SUBTITLE = {
  en_US:
    'We could not register your card due to US Department of the Treasury’s Office of Foreign Assets Control (OFAC) restrictions. If you have any questions or believe this is incorrect, please contact our support team.',
  pt_BR:
    'Não foi possível registrar o seu cartão devido às restrições do Escritório de Controle de Ativos Estrangeiros (OFAC) do Departamento do Tesouro dos EUA. Se você tiver alguma dúvida ou acreditar que isso está incorreto, entre em contato com nossa equipe de suporte.',
  es_ES:
    'No pudimos registrar tu tarjeta de débito Visa. Por favor, contacta a nuestro equipo de soporte para ayudarte.',
};

export const ERROR_INVALID_CARD = {
  en_US: 'An error occurred while registering your card.',
  pt_BR: 'Ocorreu um erro cadastrar o cartão.',
  es_ES: 'Se produjo un error al registrar tu tarjeta de débito Visa.',
};

export const ERROR_INVALID_CARD_SUBTITLE = {
  en_US: 'Please, verify the card information and try again.',
  pt_BR: 'Por favor, verifique as informações do cartão e tente novamente.',
  es_ES: 'Por favor, verifica la información e inténtalo de nuevo.',
};

export const TEXT_INVALID_DATE = {
  en_US: 'Please enter a valid date that is later than the current date. ',
  pt_BR: 'Por favor, informe uma data válida que seja posterior à data atual.',
  es_ES: 'Por favor, introduzca una fecha válida posterior a la fecha actual. ',
};

export const ADD_PAYMENT_METHOD_SUCCESS = {
  en_US: 'New Visa direct payment added successfully.',
  pt_BR: 'Novo pagamento direto Visa adicionado com sucesso.',
  es_ES: 'Tu tarjeta de débito Visa se agregó correctamente.',
};

export const ERROR_INVALID_NAME_ACCOUNT = {
  en_US: 'Your name is in an invalid format.',
  pt_BR: 'Seu nome está em um formato inválido.',
  es_ES: 'Tu nombre está en un formato no válido.',
};

export const ERROR_INVALID_NAME_ACCOUNT_SUBTITLE = {
  en_US: 'Please, verify it in the profile section and try again.',
  pt_BR: 'Por favor, verifique-o no seu perfil e tente novamente.',
  es_ES: 'Verifica en tu perfil e inténtalo de nuevo.',
};

export const BTN_REMOVE = {
  en_US: 'Remove',
  pt_BR: 'Remover',
  es_ES: 'Eliminar',
};

export const DELETE_CARD_TITLE = {
  en_US: 'Are you sure you want to remove the selected Visa direct payment? ',
  pt_BR:
    'Você está certo de que deseja remover o pagamento direto Visa selecionado?',
  es_ES: '¿Estás seguro de que deseas eliminar la tarjeta seleccionada?',
};

export const DELETE_CARD_SUBTITLE = {
  en_US: 'This action cannot be reversed.',
  pt_BR: 'Essa ação não pode ser revertida.',
  es_ES: 'No se podrá revertir esta acción.',
};

export const TEXT_NO_KEEP = {
  en_US: 'No, keep the Visa direct payment',
  pt_BR: 'Não, manter o pagamento direto Visa',
  es_ES: 'Mantener tarjeta',
};

export const CONFIRM_DELETE_CARD = {
  en_US: 'Visa direct payment removed successfully.',
  pt_BR: 'Pagamento direto Visa removido com sucesso.',
  es_ES: 'Tu tarjeta de débito Visa fue eliminada correctamente.',
};

export const LOADING_REMOVING_CARD = {
  en_US: 'Removing Visa direct payment...',
  es_ES: 'Eliminando tarjeta...',
  pt_BR: 'Removendo pagamento direto Visa...',
};

export const SOMETHING_WENT_WRONG = {
  en_US: 'Something went wrong!',
  es_ES: '¡Algo salió mal!',
  pt_BR: 'Algo deu errado!',
};

export const BUTTON_DENY_CONFIRM_CHANGE_MAIN_CARD = {
  en_US: 'Cancel',
  pt_BR: 'Cancelar',
  es_ES: 'Cancelar',
};

export const BUTTON_CONFIRM_CHANGE_MAIN_CARD = {
  en_US: 'Continue with another Visa direct payment',
  pt_BR: 'Continuar com outro pagamento direto Visa',
  es_ES: 'Continuar con otra tarjeta',
};

export const SUBTITLE_CONFIRM_CHANGE_MAIN_CARD = {
  en_US: `You've changed to another Visa direct payment. By continuing, your Visa direct payment will be updated for all ongoing payments.`,
  pt_BR:
    'Você trocou para outro pagamento direto Visa. Ao continuar, seu pagamento direto Visa será alterado em todos os pagamentos em andamento.',
  es_ES:
    'Tu tarjeta de débito Visa se actualizará como principal para recibir los depósitos de tus reclamos.',
};

export const TITLE_CONFIRM_CHANGE_MAIN_CARD = {
  en_US: 'Continue with another Visa direct payment?',
  pt_BR: 'Continuar com outro pagamento direto Visa?',
  es_ES: '¿Continuar con otra tarjeta de débito Visa?',
};

export const TITLE_ADD_CONFIRM_CHANGE_MAIN_CARD = {
  en_US: 'Continue with new Visa direct payment?',
  pt_BR: 'Continuar com novo pagamento direto Visa?',
  es_ES: '¿Continuar con una nueva tarjeta de débito Visa?',
};

export const SUBTITLE_ADD_CONFIRM_CHANGE_MAIN_CARD = {
  en_US: `Your new Visa direct payment will automatically be considered as the primary payment method.`,
  pt_BR:
    'Seu novo pagamento direto Visa será automaticamente considerado como o método de pagamento principal.',
  es_ES:
    'Tu tarjeta de débito Visa se actualizará como principal para recibir los depósitos de tus reclamos.',
};

export const BUTTON_ADD_CONFIRM_CHANGE_MAIN_CARD = {
  en_US: 'Continue with new Visa direct payment',
  pt_BR: 'Continuar com novo pagamento direto Visa',
  es_ES: 'Continuar con nueva tarjeta',
};

export const CHECKING_OCT_INFORMATION = {
  en_US: 'Verifying your Visa direct payment information...',
  pt_BR: 'Verificando suas informações do pagamento direto Visa...',
  es_ES: 'Verificando tu información...',
};

export const CHANGE_OCT_INFORMATION = {
  en_US:
    'Your Visa direct payment information has been changed. Do you want to save it?',
  pt_BR:
    'Suas informações do pagamento direto Visa foram alteradas. Deseja salvá-las?',
  es_ES:
    'Se ha modificado tu tarjeta de débito Visa. ¿Deseas guardar los cambios?',
};

export const CHANGE_OCT_INFORMATION_SUBTITLE = {
  en_US: '',
  pt_BR: '',
  es_ES:
    'Tu nueva tarjeta de débito Visa se actualizará como el pago principal para recibir los depósitos de tus reclamos.',
};

export const TEXT_YES = {
  en_US: 'Yes, I do',
  es_ES: 'Sí, quiero',
  pt_BR: 'Sim, eu quero',
};

export const TEXT_NO = {
  en_US: 'No',
  es_ES: 'No',
  pt_BR: 'Não',
};

export const MODAL_CONFIRMATION_UPDATE_INFO = {
  en_US: 'New information updated successfully.',
  pt_BR: 'Novas informações atualizadas com sucesso.',
  es_ES: 'Nueva información actualizada con éxito.',
};

export const SAVING_CARD_INFORMATION = {
  en_US: 'Saving your Visa direct payment information...',
  pt_BR: 'Salvando informações do seu pagamento direto Visa...',
  es_ES: 'Guardando nueva tarjeta...',
};

export const YOU_CANT_CHANGE_DIRECT_PAYMENT_NOW = {
  en_US:
    'There is a claim under review that prevents the addition of a new Visa direct payment at the moment.',
  pt_BR:
    'Há um sinistro em análise impedindo a adição de um novo pagamento direto Visa neste momento.',
  es_ES:
    'Hay un reclamo bajo revisión que impide que se agregue una nueva tarjeta de débito Visa en este momento.',
};
