import { SVGProps } from 'react';
import { useTheme } from '../../themes/ThemeContextParent/ThemeContextParent';

const DocumentIcon = (props: SVGProps<SVGSVGElement>) => {
  const { getCurrentThemeColors } = useTheme();
  const { primary } = getCurrentThemeColors();

  return (
    <svg
      width={65}
      height={64}
      viewBox="0 0 65 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={1.78906}
        y={1}
        width={62}
        height={62}
        rx={31}
        stroke="#021E4C"
        strokeWidth={2}
      />
      <path
        d="M32.9988 17.2867C28.141 17.2867 24.1945 21.1598 24.1945 25.9221C24.1945 30.6844 28.1483 34.5574 32.9988 34.5574C37.8493 34.5574 41.8032 30.6844 41.8032 25.9221C41.8032 21.1598 37.8493 17.2867 32.9988 17.2867ZM32.9988 33.0288C32.5946 33.0288 31.9846 32.2571 31.5143 30.7946H34.476C34.0057 32.2571 33.3957 33.0288 32.9988 33.0288ZM31.1395 29.266C30.9998 28.5164 30.9043 27.6565 30.8676 26.6938H35.1228C35.086 27.6565 34.9905 28.5164 34.8508 29.266H31.1395ZM25.7672 26.6938H29.3389C29.3683 27.5757 29.4565 28.4429 29.5888 29.266H26.583C26.1567 28.4796 25.8701 27.6124 25.7672 26.6938ZM32.9988 18.8227C33.403 18.8227 34.013 19.5944 34.4834 21.0569H31.5216C31.992 19.5944 32.602 18.8227 33.0062 18.8227H32.9988ZM34.8582 22.5855C34.9978 23.3352 35.0934 24.195 35.1301 25.1578H30.8749C30.9117 24.195 31.0072 23.3352 31.1468 22.5855H34.8582ZM29.3389 25.1578H25.7672C25.8701 24.2391 26.1494 23.3646 26.583 22.5855H29.5961C29.4565 23.4086 29.3757 24.2759 29.3463 25.1578H29.3389ZM36.6587 26.6864H40.2305C40.1276 27.6051 39.8483 28.4796 39.4147 29.2586H36.4015C36.5412 28.4355 36.622 27.5683 36.6514 26.6864H36.6587ZM36.6587 25.1578C36.6293 24.2759 36.5412 23.4086 36.4089 22.5855H39.4221C39.8483 23.3646 40.1349 24.2391 40.2378 25.1578H36.6661H36.6587ZM38.2829 21.0495H36.0782C35.8944 20.3734 35.6666 19.7561 35.3947 19.2196C36.5044 19.6017 37.4892 20.2338 38.2756 21.0495H38.2829ZM30.603 19.2196C30.3311 19.7487 30.1106 20.3661 29.9195 21.0495H27.7147C28.5085 20.2338 29.4932 19.6017 30.5956 19.2196H30.603ZM27.7221 30.7873H29.9269C30.1106 31.4634 30.3384 32.0807 30.603 32.6172C29.4933 32.2351 28.5085 31.603 27.7221 30.7873ZM35.402 32.6172C35.674 32.0881 35.8944 31.4707 36.0855 30.7873H38.2903C37.4966 31.603 36.5118 32.2351 35.4094 32.6172H35.402Z"
        fill={primary}
      />
      <path
        d="M21.1298 14.2222V41.0248H22.6584V15.7582H43.3318V42.5608H21.1224V44.0894H40.2672V47.6097C35.8576 46.206 29.6843 45.4858 21.8868 45.4858H21.1224V47.0144H21.8868C30.1326 47.0144 36.4823 47.8228 40.7669 49.3956L41.7958 49.7777V44.0821H44.8604V14.2222H21.1224H21.1298Z"
        fill={primary}
      />
      <path
        d="M36.4456 39.4961H29.552V41.0247H36.4456V39.4961Z"
        fill={primary}
      />
      <path
        d="M38.3563 36.4315H27.6338V37.9602H38.3563V36.4315Z"
        fill={primary}
      />
    </svg>
  );
};
export default DocumentIcon;
