import { useTheme } from '../../../themes/ThemeContextParent/ThemeContextParent';

const ReloadIcon = () => {
  const { getCurrentThemeColors } = useTheme();
  const { primary } = getCurrentThemeColors();

  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="reload" clipPath="url(#clip0_1361_761)">
        <path
          id="primary"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22 5.86441L22 2.5H24L24 9.5H17V7.5H20.6622C18.9331 4.51099 15.7014 2.5 12 2.5C6.47715 2.5 2 6.97715 2 12.5C2 18.0228 6.47715 22.5 12 22.5C17.5228 22.5 22 18.0228 22 12.5H24C24 19.1274 18.6274 24.5 12 24.5C5.37258 24.5 0 19.1274 0 12.5C0 5.87258 5.37258 0.5 12 0.5C16.1739 0.5 19.8502 2.63102 22 5.86441Z"
          fill={primary}
        />
      </g>
      <defs>
        <clipPath id="clip0_1361_761">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ReloadIcon;
