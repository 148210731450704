export const getInitialValues = claim => {
  const { statuses } = claim;

  const rejectedSubStatuses = statuses
    .flatMap(claimStatus => claimStatus.sub_statuses)
    .find(subStatus => subStatus.name === 'Claims - Rejected Claim');

  return {
    reason: rejectedSubStatuses ? rejectedSubStatuses.description : 'Unknown',
    description: rejectedSubStatuses
      ? rejectedSubStatuses.decision_details
      : '-',
    comment: '',
  };
};
