import { BadgeProps } from '../Badge';

export const GENERICS = intl => ({
  REVIEW: {
    label: intl.UNDER_REVIEW,
    type: 'INFO',
  } as BadgeProps,
  REVIEW_BY_UNDERWRITER: {
    label: intl.UNDER_REVIEW,
    type: 'INFO',
  } as BadgeProps,
  CREATED: {
    label: intl.UNDER_REVIEW,
    type: 'INFO',
  } as BadgeProps,
  WAITING_FOR_PAYMENT: {
    label: intl.UNDER_REVIEW,
    type: 'INFO',
  } as BadgeProps,

  COMPLETED: {
    label: intl.CONCLUDED,
    type: 'POSITIVE',
  } as BadgeProps,

  CLOSED: {
    label: intl.CLOSED,
    type: 'DISABLED',
  } as BadgeProps,
  CLOSED_DUPLICATED: {
    label: intl.CLOSED,
    type: 'DISABLED',
  } as BadgeProps,

  REJECTED: {
    label: intl.REJECTED,
    type: 'NEGATIVE',
  } as BadgeProps,

  PENDING_DOCUMENTATION: {
    label: intl.PENDING,
    type: 'WARNING',
  } as BadgeProps,
  PENDING: {
    label: intl.PENDING,
    type: 'WARNING',
  } as BadgeProps,

  SUSPENDED: {
    label: intl.PENDING,
    type: 'INFO',
  } as BadgeProps,
  // PENDING: {
  //   label: intl.PENDING,
  //   type: 'INFO',
  // } as BadgeProps,

  ONGOING: {
    label: intl.ACTIVE,
    type: 'POSITIVE',
  } as BadgeProps,

  CANCELED: {
    label: intl.CANCELED,
    type: 'DISABLED',
  } as BadgeProps,
  CANCELLED: {
    label: intl.CANCELED,
    type: 'DISABLED',
  } as BadgeProps,

  EXPIRED: {
    label: intl.EXPIRED,
    type: 'DISABLED',
  } as BadgeProps,

  PAYMENT_REFUSED: {
    label: intl.NOT_APPROVED,
    type: 'NEGATIVE',
  } as BadgeProps,

  WAITING_FOR_TRAVEL: {
    label: intl.WAITING,
    type: 'WARNING',
  } as BadgeProps,
});

export const UUP = intl => ({
  REVIEW: {
    label: intl.UPLOADED,
    type: 'POSITIVE',
  } as BadgeProps,
  MANUAL_VERIFICATION: {
    label: intl.UPLOADED,
    type: 'POSITIVE',
  } as BadgeProps,
  ERROR: {
    label: intl.UPLOADED,
    type: 'POSITIVE',
  } as BadgeProps,

  ACCEPTED: {
    label: intl.APPROVED,
    type: 'POSITIVE',
  } as BadgeProps,
  ACCEPTED_AUTOMATICALLY: {
    label: intl.APPROVED,
    type: 'POSITIVE',
  } as BadgeProps,
  ACCEPTED_MANUALLY: {
    label: intl.APPROVED,
    type: 'POSITIVE',
  } as BadgeProps,

  REJECTED: {
    label: intl.REJECTED,
    type: 'NEGATIVE',
  } as BadgeProps,
  DISCARDED: {
    label: intl.REJECTED,
    type: 'NEGATIVE',
  } as BadgeProps,

  PENDING: {
    label: intl.PENDING,
    type: 'INFO',
  } as BadgeProps,

  PENDING_DOCUMENTATION: {
    label: intl.PENDING,
    type: 'WARNING',
  } as BadgeProps,
  SUSPENDED: {
    label: intl.PENDING,
    type: 'WARNING',
  } as BadgeProps,
});

export const CLAIMS = intl => ({
  REVIEW: {
    label: intl.UPLOADED,
    type: 'POSITIVE',
  } as BadgeProps,

  COMPLETED: {
    label: intl.CONCLUDED,
    type: 'POSITIVE',
  } as BadgeProps,

  CLOSED: {
    label: intl.CLOSED,
    type: 'DISABLED',
  } as BadgeProps,

  PENDING_DOCUMENTATION: {
    label: intl.PENDING,
    type: 'WARNING',
  } as BadgeProps,
  PENDING: {
    label: intl.PENDING,
    type: 'WARNING',
  } as BadgeProps,

  ACCEPTED: {
    label: intl.APPROVED,
    type: 'POSITIVE',
  } as BadgeProps,
  ACCEPTED_AUTOMATICALLY: {
    label: intl.APPROVED,
    type: 'POSITIVE',
  } as BadgeProps,
  ACCEPTED_MANUALLY: {
    label: intl.APPROVED,
    type: 'POSITIVE',
  } as BadgeProps,

  MANUAL_VERIFICATION: {
    label: intl.PENDING,
    type: 'INFO',
  } as BadgeProps,
  ERROR: {
    label: intl.PENDING,
    type: 'INFO',
  } as BadgeProps,

  DISCARDED: {
    label: intl.REJECTED,
    type: 'NEGATIVE',
  } as BadgeProps,
});

export const NAL = intl => ({
  CANCELED: {
    label: intl.CANCELED,
    type: 'DISABLED',
  } as BadgeProps,
  CANCELLED: {
    label: intl.CANCELED,
    type: 'DISABLED',
  } as BadgeProps,

  DONE: {
    label: intl.DONE,
    type: 'POSITIVE',
  } as BadgeProps,

  NOT_DONE: {
    label: intl.NOT_DONE,
    type: 'DISABLED',
  } as BadgeProps,

  PENDING: {
    label: intl.PENDING,
    type: 'WARNING',
  } as BadgeProps,
});

export const MONETIZATION = intl => ({
  EXPIRED: {
    label: intl.EXPIRED,
    type: 'DISABLED',
  } as BadgeProps,

  ONGOING: {
    label: intl.ACTIVE,
    type: 'POSITIVE',
  } as BadgeProps,

  CLOSED: {
    label: intl.CLOSED,
    type: 'DISABLED',
  } as BadgeProps,
});

export const TRAVEL_KIT = intl => ({
  DELAYED: {
    label: intl.DELAYED,
    type: 'NEGATIVE',
  } as BadgeProps,

  ON_TIME: {
    label: intl.ON_TIME,
    type: 'POSITIVE',
  } as BadgeProps,

  CANCELLED: {
    label: intl.CANCELED,
    type: 'DISABLED',
  } as BadgeProps,
});

export type StatusPossibility =
  | keyof ReturnType<typeof GENERICS>
  | keyof ReturnType<typeof UUP>
  | keyof ReturnType<typeof CLAIMS>
  | keyof ReturnType<typeof NAL>
  | keyof ReturnType<typeof MONETIZATION>
  | keyof ReturnType<typeof TRAVEL_KIT>;
