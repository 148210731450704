import { Dispatch, SetStateAction, useMemo } from 'react';
import { Formik } from 'formik';
import styles from './PersonalInformation.module.scss';
import PhoneForm from '../../../components/PhoneForm';
import { generateInitialValues } from './initialValues';
import { useIntl } from '../../../intl';
import * as translations from './intl';
import { generateValidationSchema } from './validationSchema';
import Input from '../../../components/Input/Input';
import Calendar from '../../../components/Calendar/Calendar';
import { subDays } from 'date-fns';
import Select from '../../../components/Select/Select';
import sanitize from '../../../utils/sanitize';
import { removeRequiredCharLabel } from '../../../utils/stringUtils';
import { pt_BR } from '../../../intl/idioms';
import Button from '../../../components/Button/Button';

interface Props {
  setStep: Dispatch<SetStateAction<number>>;
}

const PersonalInformation = ({ setStep }: Props) => {
  const { translate, idiom } = useIntl();

  const intl = translate(translations);
  const yesterday = subDays(new Date(), 1);
  const isBR = idiom === pt_BR;

  const validationSchema = useMemo(
    () => generateValidationSchema(intl, isBR),
    [intl, isBR]
  );
  const initialValues = useMemo(() => generateInitialValues(isBR), [isBR]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={values => {
        console.log(values);
        setStep(2);
      }}
      enableReinitialize
    >
      {formikProps => {
        const {
          values,
          handleChange,
          handleBlur,
          touched,
          errors,
          setFieldTouched,
          handleSubmit,
        } = formikProps;

        const registrationType =
          values.person_registrations.registration_type === 'ID_CARD'
            ? intl.LABEL_NATIONAL_ID
            : intl.LABEL_PASSPORT;

        return (
          <form className={styles.personalInformation} onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12 col-md-6 col-lg-4">
                <Input
                  value={values.person.first_name}
                  label={intl.LABEL_NAME}
                  name="person.first_name"
                  type="text"
                  onChange={handleChange}
                  touched={touched.person && touched.person.first_name}
                  onBlur={handleBlur}
                  error={errors.person && errors.person.first_name}
                  maxLength="40"
                />
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <Input
                  value={values.person.last_name}
                  label={intl.LABEL_LAST_NAME}
                  name="person.last_name"
                  type="text"
                  onChange={handleChange}
                  touched={touched.person && touched.person.last_name}
                  onBlur={handleBlur}
                  error={errors.person && errors.person.last_name}
                  maxLength="80"
                />
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <Calendar
                  label={intl.LABEL_BIRTH_DATE}
                  name="person.birth_date"
                  maxDate={yesterday}
                  formikProps={formikProps}
                  setFieldTouched={setFieldTouched}
                />
              </div>
            </div>
            <div className="row">
              <div
                className={`col-12 ${
                  isBR ? 'col-md-6 col-lg-4' : 'col-md-12 col-lg-4'
                }`}
              >
                <Select
                  label={intl.COUNTRY_LABEL}
                  value={values.address.country}
                  name="address.country"
                  onChange={handleChange}
                  dangerouslySetInnerHTML={{
                    __html: sanitize(
                      `<option value="">${intl.COUNTRY_LABEL}</option>${intl.COUNTRY_OPTIONS}`
                    ),
                  }}
                  touched={touched.address && touched.address.country}
                  onBlur={handleBlur}
                  error={errors.address && errors.address.country}
                />
              </div>
              {!isBR && (
                <div className="col-12 col-md-6 col-lg-4">
                  <Select
                    label={intl.LABEL_IDENTIFICATION}
                    value={values.person_registrations.registration_type}
                    name={`person_registrations.registration_type`}
                    onChange={handleChange}
                    touched={
                      touched.person_registrations &&
                      touched.person_registrations &&
                      touched.person_registrations.registration_type
                    }
                    onBlur={handleBlur}
                    error={
                      errors.person_registrations &&
                      errors.person_registrations &&
                      errors.person_registrations.registration_type
                    }
                  >
                    <option value="">{intl.LABEL_IDENTIFICATION}</option>
                    <option value="PASSPORT">
                      {removeRequiredCharLabel(intl.LABEL_PASSPORT)}
                    </option>
                    <option value="ID_CARD">
                      {removeRequiredCharLabel(intl.LABEL_NATIONAL_ID)}
                    </option>
                  </Select>
                </div>
              )}
              <div
                className={`col-12 ${
                  isBR ? 'col-md-6 col-lg-8' : 'col-md-6 col-lg-4'
                }`}
              >
                <Input
                  value={values.person_registrations.value}
                  label={isBR ? intl.LABEL_CPF : registrationType}
                  name={`person_registrations.value`}
                  type="text"
                  onBlur={handleBlur}
                  tooltipText={
                    isBR
                      ? intl.TEXT_ALERT_CPF
                      : values.person_registrations.registration_type ===
                        'ID_CARD'
                      ? intl.TEXT_ALERT_NATIONAL_ID
                      : intl.TEXT_ALERT_PASSPORT
                  }
                  mask={isBR ? 'maskedCPF' : 'maskedPassaport'}
                  onChange={handleChange}
                  touched={
                    touched.person_registrations &&
                    touched.person_registrations &&
                    touched.person_registrations.value
                  }
                  error={
                    errors.person_registrations &&
                    errors.person_registrations &&
                    errors.person_registrations.value
                  }
                />
              </div>
            </div>

            <PhoneForm
              formikProps={formikProps}
              inputNames={{
                countryCode: 'phone.international_prefix',
                phoneNumber: 'phone.number',
                phoneType: 'phone.phone_type',
              }}
            />

            <div className="row">
              <div className="col-12 col-md-6 col-lg-6">
                <Input
                  value={values.currentEmail}
                  label={intl.CURRENT_EMAIL}
                  name="currentEmail"
                  type="text"
                  onChange={handleChange}
                  touched={touched.currentEmail}
                  onBlur={handleBlur}
                  error={errors.currentEmail}
                />
              </div>
              <div className="col-12 col-md-6 col-lg-6">
                <Input
                  value={values.lostEmail}
                  label={intl.LOST_EMAIL}
                  name="lostEmail"
                  type="text"
                  onChange={handleChange}
                  touched={touched.lostEmail}
                  onBlur={handleBlur}
                  error={errors.lostEmail}
                />
              </div>
            </div>

            <footer>
              <div className={styles.wrapperButton}>
                <Button typeHtml="submit" type="blue">
                  {intl.LABEL_SUBMIT}
                </Button>
              </div>
            </footer>
          </form>
        );
      }}
    </Formik>
  );
};

export default PersonalInformation;
