import { useEffect, useContext, useState, useRef } from 'react';
import { useTheme } from '../../themes/ThemeContextParent/ThemeContextParent';
import styles from './FeedbackNPS.module.scss';
import { StateContext } from '../StateContextParent/StateContextParent';
import { useIntl } from '../../intl';
import * as translations from './intl';
import { NotificationContext } from '../NotificationContextParent/NotificationContextParent';
import { AppInsightTrackContext } from '../AppInsightTrackContextParent/AppInsightTrackContextParent';
import { REACT_APP_MINUTES_TO_SHOW_NPS } from '../../utils/environments_variables';

const FeedbackNPS = () => {
  const [showFeedbackButton, setShowFeedbackButton] = useState(false);

  const { getGlobalTheme } = useTheme();
  const {
    utils: { isUserLogged },
    actions: { modal },
  } = useContext(StateContext);
  const { translate } = useIntl();
  const {
    state: { showFeedback },
    actions: { feedback },
  } = useContext(NotificationContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);

  const logged = isUserLogged();
  const intl = translate(translations);
  const theme = getGlobalTheme();
  const timeoutRef = useRef(null);
  const TIME_DELAY = 1000 * 60 * Number(REACT_APP_MINUTES_TO_SHOW_NPS);

  const openModal = () => {
    trackEventUserAction('#### NPS - USER OPENS THE FEEDBACK MODAL ####', true);
    modal.showModal(
      false,
      true,
      <iframe
        width="100%"
        height="640"
        src={intl.NPS_LINK}
        style={{ border: 0 }}
        className={styles.content}
      />
    );

    feedback.setShowFeedbackNPS(false);
    sessionStorage.setItem(
      'feedback-VisaBenefits',
      JSON.stringify({ NPS: false })
    );
  };
  useEffect(() => {
    if (logged && showFeedback.NPS) {
      timeoutRef.current = setTimeout(() => {
        setShowFeedbackButton(true);
      }, TIME_DELAY);
    } else {
      clearTimeout(timeoutRef.current);
      setShowFeedbackButton(false);
    }

    return () => clearTimeout(timeoutRef.current);
  }, [logged, showFeedback.NPS]);

  if (!showFeedbackButton) {
    return <></>;
  }

  return (
    <div className={`${styles.alertContainer} ${styles[theme]}`}>
      <button
        className={`${styles.feedbackButton} ${styles[theme]}`}
        onClick={openModal}
        id="btnFeedbackNPS"
      >
        <span className={styles.textButton}>{intl.FEEDBACK_TEXT}</span>
      </button>
    </div>
  );
};

export default FeedbackNPS;
