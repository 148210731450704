import { useContext, useRef, useEffect, useState } from 'react';
import style from '../../../Upload.module.scss';
import { claimsServices, cmxService } from '../../../../../../services';
import { IntlContext } from '../../../../../../intl/index';
import * as translations from '../../intl';
import LoadingDots from '../../../../../../components/LoadingDots/LoadingDots';
import { StateContext } from '../../../../../../components/StateContextParent/StateContextParent';
import Button from '../../../../../../components/Button/Button';
import ModalInformation from '../../../../../../components/ModalInformation/ModalInformation';
import { AppInsightTrackContext } from '../../../../../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';
import {
  ENABLE_UUP,
  REACT_APP_UPLOAD_CONCLUDE_ENABLE,
} from '../../../../../../utils/environments_variables';
import {
  dataLayerClaimDocumentEventTrack,
  CLAIM_SITE_SECTION,
  CLAIM_DOCUMENT_ATTACHED,
  CLAIM_DOCUMENT_ATTACHED_FAILED,
  PROFILE_SITE_SECTION,
} from '../../../../../../utils/GTM_helper';
import { extensions } from '../../../../../../utils/fileExtensionsValidade';
import { isBR } from '../../../../../../utils/country';
import ExpectedDocumentCard from '../../../../../../components/ExpectedDocumentCard';
import {
  ExpectedDocument,
  newDocumentAttached,
} from '../../../../../../@types/APIs/ExpectedDocuments';
import { Attachment } from '../../../../../../@types/APIs/Customer';
import { ClaimInfo } from '../../../../../../@types/APIs/claim';
import StatusBadge from '../../../../../../componentsV2/Badge/StatusBadge/StatusBadge';

const filterByMandatoryinAttachDocuments = (documents, attachments) => {
  let attachDocumentsByMandatory = [];
  for (let index = 0; index < documents.length; index++) {
    const expectDocument = documents[index];
    const filter = attachments.filter(
      attach => attach.document_type_name === expectDocument.document_type_name
    );
    attachDocumentsByMandatory = [...attachDocumentsByMandatory, ...filter];
  }
  return attachDocumentsByMandatory;
};

const getDocumentStatus = document => {
  if (
    (document.document_name && document.document_status == 'PENDING') ||
    (document.document_name && !document.document_status)
  ) {
    return 'REVIEW';
  } else if (document.document_status) {
    return document.document_status;
  }
};

const checkStatusUpload = (
  attachments,
  document_name,
  setStatusUpload,
  initialStatus,
  setAttachName,
  documents
) => {
  if (attachments.length === 0) {
    setStatusUpload(initialStatus);
    return;
  }

  const filteredAttachDocuments = filterByMandatoryinAttachDocuments(
    documents,
    attachments
  );
  for (let index = 0; index < filteredAttachDocuments.length; index++) {
    const attachment = filteredAttachDocuments[index];
    if (
      attachment.document_type_name === document_name ||
      `${attachment.document_type_name}_${index + 1}` === document_name
    ) {
      setAttachName(attachment.document_name);
      setStatusUpload(getDocumentStatus(attachment));
      return;
    }
  }
  setAttachName('');
  setStatusUpload('PENDING');
};

export interface FiteItemProps {
  id: number;
  document: ExpectedDocument;
  documents: ExpectedDocument[];
  claimInfo: ClaimInfo;
  claimId: string;
  addDocumentAttached: (newDocumentAttached: newDocumentAttached) => void;
  removeDocumentAttached: (
    document_type_name: string,
    attachment_id: string
  ) => void;
  isOptional: boolean;
  newInput: () => void;
  isFromProfile: boolean;
  alreadyAttached: Attachment[];
}

const FileItem = (props: FiteItemProps) => {
  const {
    id,
    document,
    documents,
    claimInfo,
    claimId,
    addDocumentAttached,
    removeDocumentAttached,
    isOptional,
    newInput,
    isFromProfile,
    alreadyAttached,
  } = props;

  const { translate, idiomForApi, country } = useContext(IntlContext);
  const intl = translate(translations);
  const [statusUpload, setStatusUpload] = useState<
    'REVIEW' | 'PENDING' | 'ACCEPTED'
  >('PENDING');
  const [loading, setLoading] = useState('');
  const [attachName, setAttachName] = useState('');
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const { document_description, document_type_name, is_mandatory } = document;
  const { claim_type_name } = claimInfo;
  const { actions, utils } = useContext(StateContext);
  const cn = utils.getCn();
  const { modal } = actions;
  const fileReference = useRef<HTMLInputElement>();
  const attached = statusUpload !== 'PENDING';
  const isUUPDocument =
    document_type_name == 'National ID' ||
    (isBR(country) && document_type_name == 'Proof of Residency');
  const idButton = document_type_name.replace(' ', '_');

  const getFileData = async () => {
    trackEventUserAction(
      '#### (UPLOAD) ABRIU A JANELA PARA SELECIONAR DOCUMENTOS PARA O UPLOAD ####',
      true
    );
    setLoading('Loading');
    const file = fileReference.current && fileReference.current.files[0];
    const { name: document_name, type: content_type, size } = file;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    await new Promise<void>(resolve => {
      reader.onload = () => resolve();
    });
    const content_data = String(reader.result).substring(
      String(reader.result).indexOf(',') + 1
    );

    const sizeKbFile = parseFloat(String(size / 1024)).toFixed(2);
    const sizeMBFile = parseFloat(String(Number(sizeKbFile) / 1024)).toFixed(2);
    //check size minor or equall to 1.5MB
    trackEventUserAction(
      '#### (UPLOAD) VERIFICANDO O TAMANHO DO ARQUIVO SELECIONADO PARA UPLOAD ####',
      { sizeKbFile, file }
    );
    if (Number(sizeKbFile) > 1536) {
      trackEventUserAction(
        '#### (UPLOAD) TAMANHO DO ARQUIVO SELECIONADO PARA UPLOAD É MAIOR DO QUE O PERMITIDO ####',
        { sizeKbFile, file }
      );
      dataLayerClaimDocumentEventTrack(
        CLAIM_DOCUMENT_ATTACHED_FAILED,
        claim_type_name,
        null,
        document_name
      );
      setLoading('Loading finished');

      modal.showModal(
        false,
        true,
        <ModalInformation
          type="error"
          message={intl.FILE_SIZE_ERROR}
          subtitle={`${document_name} (${sizeMBFile} MB)`}
        />
      );
      return;
    }

    trackEventUserAction(
      '#### (UPLOAD) VERIFICANDO A EXTENSÃO DO ARQUIVO SELECIONADO PARA UPLOAD ####',
      { content_type, file }
    );
    if (!content_type || extensions.indexOf(content_type) < 0) {
      trackEventUserAction(
        '#### (UPLOAD) EXTENSÃO DO ARQUIVO SELECIONADO PARA UPLOAD NÃO É PERMITIDA ####',
        { content_type, file }
      );
      setLoading('Loading finished');
      modal.showModal(
        false,
        true,
        <ModalInformation
          type="error"
          message={intl.FILE_TYPE_ERROR}
          subtitle={`${document_name} (${sizeMBFile} MB)`}
        />
      );
      return;
    }

    // Check optional to change document_type_name
    let submitName = null;
    const additional = 'Additional Documents';
    if (isOptional) {
      const inputName = document_type_name.split('_');
      if (inputName.length === 2 && inputName[0] === additional) {
        submitName = inputName[0];
      } else {
        submitName = document_type_name;
      }
    }
    const data = {
      content_type,
      document_name,
      document_type_name:
        isOptional && submitName ? submitName : document_type_name,
      content_data,
      complete: !REACT_APP_UPLOAD_CONCLUDE_ENABLE,
    };

    try {
      let resp;
      trackEventUserAction(
        '#### (UPLOAD) FAZENDO O UPLOAD DO ARQUIVO SELECIONADO ####',
        { claimId, data }
      );

      if (
        (document_type_name == 'National ID' ||
          (document_type_name == 'Proof of Residency' && isBR(country))) &&
        ENABLE_UUP
      ) {
        const fileData = {
          content_data,
          content_type,
        };
        trackEventUserAction(
          '#### (UUP FLOW - CLAIM) O ARQUIVO SERÁ ENVIADO PARA O CMX ####',
          { fileData }
        );
        const responseCMX = await cmxService.uploadDocument(fileData);
        trackEventUserAction(
          '#### (UUP FLOW - CLAIM) O ARQUIVO FOI SALVO NO CMX ####',
          {
            response_cmx:
              responseCMX && responseCMX.data ? responseCMX.data : null,
          }
        );
        const data = {
          document_type_name,
          document_name,
          document_description: document_type_name,
          external_attachment_id: responseCMX.data,
          content_type: content_type,
        };
        trackEventUserAction(
          '#### (UUP FLOW - CLAIM) O ARQUIVO FOI SALVO NO CMX E SERÁ ENVIADO PARA O SALESFORCE ####',
          {
            data,
          }
        );
        const {
          data: { attachment_id },
        } = await cmxService.saveIdCMX(cn, data, idiomForApi());
        trackEventUserAction(
          '#### (UUP FLOW - CLAIM) O ARQUIVO FOI SALVO NO SALESFORCE COM SUCESSO ####',
          {
            attachment_id,
          }
        );
        resp = {
          data: {
            attachment_id,
          },
        };
      } else {
        trackEventUserAction(
          '#### (UPLOAD) ARQUIVO NÃO PRECISA DE CMX, VAI DIRETO PARA SALESFORCE ####',
          {
            claimId,
            data,
          }
        );
        resp = await claimsServices.postAttachment(claimId, data);
        trackEventUserAction(
          '#### (UPLOAD) ARQUIVO SALVO NO SALESFORCE COM SUCESSO ####',
          {
            resp,
          }
        );
      }
      dataLayerClaimDocumentEventTrack(
        CLAIM_DOCUMENT_ATTACHED,
        claim_type_name,
        isFromProfile ? PROFILE_SITE_SECTION : CLAIM_SITE_SECTION,
        document_name
      );

      setStatusUpload('REVIEW');
      if (isOptional) newInput();
      setLoading('Loading finished');
      trackEventUserAction(
        '#### (UPLOAD) UPLOAD REALIZADO COM SUCESSO ####',
        resp
      );
      setAttachName(document_name);
      addDocumentAttached({
        attachment_id: resp.data.attachment_id,
        document_type_name:
          isOptional && submitName ? submitName : document_type_name,
        document_name,
      });
    } catch (error) {
      trackEventUserAction('#### (UPLOAD) ERRO AO REALIZAR O UPLOAD ####', {
        error,
        error_response: error.response,
      });
      setStatusUpload('PENDING');
      setLoading('Loading finished');
      dataLayerClaimDocumentEventTrack(
        CLAIM_DOCUMENT_ATTACHED_FAILED,
        claim_type_name,
        null,
        document_name
      );
      trackEventUserAction(
        '#### (UPLOAD) ERRO AO REALIZAR O UPLOAD - VAI CAIR NAS CONDICIONAIS ####',
        {
          error,
          error_response: error.response,
        }
      );
      if (
        error.response &&
        error.response.data &&
        error.response.data.error_description &&
        error.response.data.error_description.startsWith(
          'ContentType_is_invalid_Please_verify'
        )
      ) {
        trackEventUserAction(
          '#### (UPLOAD) A EXTENSÃO DO ARQUIVO INSERIDO PARA UPLOAD NÃO É PERMITIDO ####',
          error
        );
        modal.showModal(
          false,
          true,
          <ModalInformation
            type="error"
            message={intl.FILE_TYPE_ERROR}
            subtitle={`${document_name} (${sizeMBFile} MB)`}
          />
        );
      } else if (error.response && error.response.status === 413) {
        trackEventUserAction(
          '#### (UPLOAD) O TAMANHO DO ARQUIVO INSERIDO PARA UPLOAD É MAIOR DO QUE O PERMITIDO ####',
          error
        );
        modal.showModal(
          false,
          true,
          <ModalInformation
            type="error"
            message={intl.FILE_SIZE_ERROR}
            subtitle={`${document_name} (${sizeMBFile} MB)`}
          />
        );
      } else {
        trackEventUserAction(
          '#### (UPLOAD) OCORREU UM ERRO GÉNERICO NO UPLOAD DO ARQUIVO ####',
          error
        );
        modal.showModal(
          false,
          true,
          <ModalInformation
            type="error"
            message={intl.UNEXPECTED_ERROR_OCCURRED}
            subtitle={`${document_name} (${sizeMBFile} MB)`}
          />
        );
      }
    }
  };

  const modalAlertUUP = userAction => {
    trackEventUserAction(
      '#### (UUP FLOW - CLAIM) MODAL INFORMANDO QUE OS DOCUMENTOS SERÃO MODIFICADOS ####',
      true
    );
    modal.showModal(
      false,
      false,
      <ModalInformation
        type="question"
        message={intl.TEXT_YOUR_PERSONAL_DOCUMENTS_MODIFIED}
        textBtn={intl.BTN_YES_UPDATE}
        textBtnDeny={intl.BTN_NOT_UPDATE}
        clickBtn={() => {
          userAction();
          modal.closeModal();
        }}
        clickBtnDeny={() => {
          modal.closeModal();
        }}
      />,
      true
    );
  };
  const clickButtonUpload = () => {
    fileReference.current.click();
  };

  const uploadFile = () => {
    if (isUUPDocument && ENABLE_UUP) {
      return modalAlertUUP(clickButtonUpload);
    }
    clickButtonUpload();
  };

  const removeFile = async () => {
    try {
      trackEventUserAction(
        '#### (UPLOAD) O USUÁRIO ESTÁ REMOVENDO O ARQUIVO ANEXADO ####',
        document_type_name
      );
      setLoading('Loading');
      const filteredAttachDocuments = filterByMandatoryinAttachDocuments(
        documents,
        alreadyAttached
      );

      const attachmentSelected = filteredAttachDocuments.find(
        (attachment, index) =>
          attachment.document_type_name === document_type_name ||
          `${attachment.document_type_name}_${index + 1}` === document_type_name
      );

      if (
        (document_type_name == 'National ID' ||
          (document_type_name == 'Proof of Residency' && isBR(country))) &&
        ENABLE_UUP
      ) {
        trackEventUserAction(
          '#### (UUP FLOW - CLAIM) O ARQUIVO DO UUP SERÁ DELETADO ####',
          true
        );
        await cmxService.deleteDocument(
          cn,
          attachmentSelected.attachment_id,
          idiomForApi()
        );
      } else {
        await claimsServices.deleteAttachment(
          claimId,
          attachmentSelected.attachment_id
        );
      }

      fileReference.current.value = '';
      setStatusUpload('PENDING');
      removeDocumentAttached(
        document_type_name,
        attachmentSelected.attachment_id
      );
      setAttachName('');
      setLoading('Loading finished');
      trackEventUserAction(
        '#### (UPLOAD) O USUÁRIO REMOVEU O ARQUIVO ANEXADO COM SUCESSO ####',
        document_type_name
      );
    } catch (error) {
      trackEventUserAction(
        '#### (UPLOAD) ERRO AO REMOVER O ARQUIVO ANEXADO ####',
        error
      );
      setLoading('Loading finished');
      const error_description =
        error.response &&
        error.response.data &&
        error.response.data.error_description;
      modal.showModal(
        false,
        true,
        <ModalInformation type="error" message={error_description} />
      );
    }
  };

  const checkFileToRemove = () => {
    if (isUUPDocument && ENABLE_UUP) {
      return modalAlertUUP(removeFile);
    }
    removeFile();
  };

  useEffect(() => {
    checkStatusUpload(
      alreadyAttached,
      document_type_name,
      setStatusUpload,
      'PENDING',
      setAttachName,
      documents
    );
  }, [alreadyAttached, document_type_name]);

  return (
    <ExpectedDocumentCard
      name={attachName}
      description={document_description}
      isMandatory={is_mandatory}
      typeName={document_type_name}
    >
      {statusUpload != 'ACCEPTED' && (
        <ExpectedDocumentCard.Option>
          <div className={style.hideInput}>
            <input
              type="file"
              ref={fileReference}
              style={{ display: 'none' }}
              data-testid={
                isOptional
                  ? `inputUpload-${id}-optional`
                  : `inputUpload-${id}-required`
              }
              onChange={getFileData}
            />
          </div>
          <Button
            id={`uploadButton-${id}-${idButton}`}
            type="borderBlue"
            width="176px"
            height="53px"
            onClick={attached ? checkFileToRemove : uploadFile}
            className={`buttonActive ${style.widthButtonUpload} ${
              attached ? style.backGroundBlue : ''
            }`}
            disabled={loading === 'Loading'}
          >
            {loading === 'Loading' ? (
              <>
                {attached ? intl.REMOVING : intl.LOADING} <LoadingDots />
              </>
            ) : (
              <>{attached ? intl.REMOVE : intl.ADD}</>
            )}
          </Button>
        </ExpectedDocumentCard.Option>
      )}
      <ExpectedDocumentCard.Option>
        <div className={style.statusArea}>
          <StatusBadge status={statusUpload} context="CLAIMS" />
        </div>
      </ExpectedDocumentCard.Option>
    </ExpectedDocumentCard>
  );
};

export default FileItem;
