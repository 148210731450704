import { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const ChevronRightIcon = props => {
  const { axa } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme, axa);

  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          d="m5 1 5.586 5.586a2 2 0 0 1 0 2.828L5 15"
          stroke={props.color ?? config_icon.secondary}
          strokeWidth={2}
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" transform="rotate(-90 8 8)" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ChevronRightIcon;
