export const UPLOADED = {
  en_US: 'Uploaded',
  es_ES: 'Subido',
  pt_BR: 'Enviado',
};

export const APPROVED = {
  en_US: 'Approved',
  es_ES: 'Aprobado',
  pt_BR: 'Aprovado',
};

export const REJECTED = {
  en_US: 'Rejected',
  es_ES: 'Rechazado',
  pt_BR: 'Rejeitado',
};

export const PENDING = {
  en_US: 'Pending',
  es_ES: 'Pendiente',
  pt_BR: 'Pendente',
};

export const CONCLUDED = {
  en_US: 'Concluded',
  es_ES: 'Completado',
  pt_BR: 'Concluído',
};

export const CLOSED = {
  en_US: 'Closed',
  es_ES: 'Cerrado',
  pt_BR: 'Fechado',
};

export const UNDER_REVIEW = {
  en_US: 'Under review',
  es_ES: 'En revisión',
  pt_BR: 'Em revisão',
};

export const ACTIVE = {
  en_US: 'Active',
  es_ES: 'Activo',
  pt_BR: 'Ativo',
};

export const CANCELED = {
  en_US: 'Canceled',
  es_ES: 'Cancelado',
  pt_BR: 'Cancelado',
};

export const EXPIRED = {
  en_US: 'Expired',
  es_ES: 'Expirado',
  pt_BR: 'Expirado',
};

export const NOT_APPROVED = {
  en_US: 'Not approved',
  es_ES: 'No aprobado',
  pt_BR: 'Não aprovado',
};

export const WAITING = {
  en_US: 'Waiting',
  es_ES: 'En espera',
  pt_BR: 'Espera',
};

export const DONE = {
  en_US: 'Done',
  es_ES: 'Hecho',
  pt_BR: 'Realizado',
};

export const NOT_DONE = {
  en_US: 'Not done',
  es_ES: 'No hecho',
  pt_BR: 'Não feito',
};

export const DELAYED = {
  en_US: 'Delayed',
  es_ES: 'Retrasado',
  pt_BR: 'Atrasado',
};

export const ON_TIME = {
  en_US: 'On time',
  es_ES: 'A tiempo',
  pt_BR: 'No horário',
};
