import { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const ChevronLeftIcon = props => {
  const { axa } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme, axa);

  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 15 5.414 9.414a2 2 0 0 1 0-2.828L11 1"
        stroke={props.color ?? config_icon.secondary}
        strokeWidth={2}
      />
    </svg>
  );
};

export default ChevronLeftIcon;
