export const TITLE_1 = {
  en_US: 'Recover account access',
  pt_BR: 'Recuperar acesso à conta',
  es_ES: 'Recuperar acceso a la cuenta',
};

export const DESCRIPTION_1 = {
  en_US:
    'To recover access to your account, fill out the form. You will receive a response within 2 business days.',
  pt_BR:
    'Para recuperar o acesso a sua conta, preencha o formulário. Você receberá uma resposta em até 2 dias úteis.',
  es_ES:
    'Para recuperar el acceso a tu cuenta, completa el formulario. Recibirás una respuesta en un plazo de 2 días hábiles.',
};

export const TITLE_2 = {
  en_US: 'Identity confirmation',
  pt_BR: 'Confirmação de identidade',
  es_ES: 'Confirmación de identidad',
};

export const DESCRIPTION_2 = {
  en_US: `To confirm your identity, we need you to send a photo holding an identification document next to your face.<br/><br/>For better chances of approval, follow the steps below:`,
  pt_BR: `Para confirmar sua identidade, precisamos que você envie uma foto segurando um documento de identificação próximo do rosto. São aceitas fotos com <strong>CNH, carteira de identidade e passaporte</strong>.<br/><br/>Para maiores chances de aprovação, siga os seguintes passos:`,
  es_ES:
    'Para confirmar tu identidad, necesitamos que <strong>envíes una foto</strong> sosteniendo un documento de identidad al lado de tu rostro.<br/><br/>Para que la foto sea aprobada, sigue estos pasos:',
};

export const TITLE_3 = {
  en_US: 'Wait for the return by e-mail ',
  pt_BR: 'Aguarde o retorno por e-mail',
  es_ES: 'Espera la respuesta en tu correo electrónico ',
};

export const DESCRIPTION_3 = {
  en_US:
    'We appreciate the information. You will receive a <strong>return by e-mail within 2 business days</strong>. Pay attention to your inbox and also to spam.',
  pt_BR:
    'Agradecemos pelas informações. Você receberá um <strong>retorno por e-mail em até 2 dias úteis</strong>. Atente-se a sua caixa de entrada e também ao spam.',
  es_ES:
    'Te agradecemos por la información. Recibirás una <strong>respuesta por correo electrónico</strong> en un máximo de <strong>2 días hábiles</strong>. Mantente atento a tu bandeja de entrada y revisa también el spam.',
};
