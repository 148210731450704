import { PHONE_TYPE_MOBILE } from '../../../constants/phone';

export const generateInitialValues = (isBR: boolean) => {
  return {
    person: {
      first_name: '',
      last_name: '',
      birth_date: '',
    },
    address: {
      country: '',
    },
    person_registrations: {
      registration_type: isBR ? 'CPF' : '',
      value: '',
    },
    phone: {
      phone_type: PHONE_TYPE_MOBILE,
      number: '',
      international_prefix: '',
    },
    currentEmail: '',
    lostEmail: '',
  };
};
