import { useTheme } from '../../../themes/ThemeContextParent/ThemeContextParent';

const ErrorIcon = () => {
  const { getColors } = useTheme();
  const { genericColors } = getColors();
  const defaultColor = genericColors.red_70;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <circle cx="8" cy="8" r="7" stroke={defaultColor} strokeWidth="2" />
      <path
        d="M10.8284 5.17157L5.17158 10.8284"
        stroke={defaultColor}
        strokeWidth="2"
      />
      <path
        d="M10.8284 10.8284L5.17158 5.17157"
        stroke={defaultColor}
        strokeWidth="2"
      />
    </svg>
  );
};

export default ErrorIcon;
