import { SVGProps } from 'react';
import { useTheme } from '../../themes/ThemeContextParent/ThemeContextParent';

const FaceIcon = (props: SVGProps<SVGSVGElement>) => {
  const { getCurrentThemeColors } = useTheme();
  const { primary } = getCurrentThemeColors();

  return (
    <svg
      width={65}
      height={64}
      viewBox="0 0 65 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={1.78906}
        y={1}
        width={62}
        height={62}
        rx={31}
        stroke={primary}
        strokeWidth={2}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4141 24.7812V21.1875C18.4141 19.2231 20.0122 17.625 21.9766 17.625H25.5703C26.019 17.625 26.3828 17.2612 26.3828 16.8125C26.3828 16.3638 26.019 16 25.5703 16H21.9766C19.1162 16 16.7891 18.3271 16.7891 21.1875V24.7812C16.7891 25.23 17.1528 25.5938 17.6016 25.5938C18.0503 25.5938 18.4141 25.23 18.4141 24.7812ZM25.5703 46.375C26.019 46.375 26.3828 46.7388 26.3828 47.1875C26.3828 47.6362 26.019 48 25.5703 48H21.9766C19.1162 48 16.7891 45.6729 16.7891 42.8125V39.2188C16.7891 38.77 17.1528 38.4062 17.6016 38.4062C18.0503 38.4062 18.4141 38.77 18.4141 39.2188V42.8125C18.4141 44.7768 20.0122 46.375 21.9766 46.375H25.5703ZM48.7891 39.2188V42.8125C48.7891 45.6729 46.4619 48 43.6016 48H40.0078C39.5591 48 39.1953 47.6362 39.1953 47.1875C39.1953 46.7388 39.5591 46.375 40.0078 46.375H43.6016C45.5659 46.375 47.1641 44.7768 47.1641 42.8125V39.2188C47.1641 38.77 47.5278 38.4062 47.9766 38.4062C48.4253 38.4062 48.7891 38.77 48.7891 39.2188ZM48.7891 21.1875V24.7812C48.7891 25.23 48.4253 25.5938 47.9766 25.5938C47.5278 25.5938 47.1641 25.23 47.1641 24.7812V21.1875C47.1641 19.2231 45.5659 17.625 43.6016 17.625H40.0078C39.5591 17.625 39.1953 17.2612 39.1953 16.8125C39.1953 16.3638 39.5591 16 40.0078 16H43.6016C46.4619 16 48.7891 18.3271 48.7891 21.1875ZM40.2891 30.4219V28.0156C40.2891 27.5755 39.9323 27.2188 39.4922 27.2188C39.0521 27.2188 38.6953 27.5755 38.6953 28.0156V30.4219C38.6953 30.862 39.0521 31.2188 39.4922 31.2188C39.9323 31.2188 40.2891 30.862 40.2891 30.4219ZM25.4766 30.4219C25.4766 30.862 25.8333 31.2188 26.2734 31.2188C26.7135 31.2188 27.0703 30.862 27.0703 30.4219V28.0156C27.0703 27.5755 26.7135 27.2188 26.2734 27.2188C25.8333 27.2188 25.4766 27.5755 25.4766 28.0156V30.4219Z"
        fill={primary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.4766 34.0625V28.0625C34.4766 27.5965 34.0988 27.2188 33.6328 27.2188C33.1668 27.2188 32.7891 27.5965 32.7891 28.0625V34.0625C32.7891 34.321 32.5788 34.5313 32.3203 34.5313H31.6328C31.1668 34.5313 30.7891 34.909 30.7891 35.375C30.7891 35.841 31.1668 36.2188 31.6328 36.2188H32.3203C33.5093 36.2188 34.4766 35.2515 34.4766 34.0625Z"
        fill={primary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.3961 39.6511C38.7384 39.3349 38.7595 38.8011 38.4434 38.4587C38.1271 38.1164 37.5934 38.0953 37.251 38.4114C36.033 39.5366 34.4483 40.1562 32.7889 40.1562C31.1295 40.1562 29.5448 39.5366 28.3268 38.4114C27.9844 38.0953 27.4507 38.1165 27.1345 38.4587C26.8183 38.8011 26.8395 39.3349 27.1818 39.6511C28.7125 41.065 30.7038 41.8437 32.7889 41.8437C34.874 41.8437 36.8653 41.065 38.3961 39.6511Z"
        fill={primary}
      />
    </svg>
  );
};
export default FaceIcon;
