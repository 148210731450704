import { useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import * as translations from './intl';
import styles from './Monetization.module.scss';
import { CLAIMS } from '../../../utils/consts';
import { IntlContext } from '../../../intl';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import { formatDateFromString } from '../../../utils/date';
import ChevronGeneric from '../../../assets/icons/ChevronGeneric';
import TotalReimbursed from '../../../components/TotalReimbursed/TotalReimbursed';
import Pagination from '../Monetization/Pagination/Pagination';
import Loading from '../../../components/Loading/Loading';
import TitleSectionProfile from '../../../components/TitleSectionProfile/TitleSectionProfile';
import StatusBadge from '../../../componentsV2/Badge/StatusBadge/StatusBadge';

const MonetizationClaims = props => {
  const { advancedSearch, userCertificates } = props;
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const [numberTicket, setNumberTicket] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const [lastShowIndex, setLastShowIndex] = useState(0);
  const [total, setTotal] = useState(() => parseFloat(0).toFixed(2));

  const checkWindowWidth = () => {
    if (window.screen.width > 600 && window.innerWidth > 600) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  };

  useEffect(() => {
    checkWindowWidth();
    window.addEventListener('resize', checkWindowWidth);
    return () => {
      window.removeEventListener('resize', checkWindowWidth);
    };
  }, []);

  useState(advancedSearch);
  const [loadingClaims, setLoadingClaims] = useState(true);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  const [allTotalClaim, setAllTotalClaim] = useState([]);
  const [allTotal, setAllTotal] = useState([]);
  const [copyAllTotal, setCopyAllTotal] = useState([]);
  const [checkAll, setCheckAll] = useState(false);

  const [page, setpage] = useState(1);
  const amountItemsByPage = 10;

  const sortFun = (a, b) => {
    if (a.info.date < b.info.date) {
      return 1;
    }
    if (a.info.date > b.info.date) {
      return -1;
    }

    // names must be equal
    return 0;
  };

  const returnAllClaims = claims => {
    setLoadingClaims(true);
    const temp = claims.sort(sortFun);
    setAllTotalClaim(temp);
    setLoadingClaims(false);
  };

  const header = (
    <tr className={`row ${styles.header} ${styles[themes]}`}>
      <th className={`col-2 ${styles.cell} ${styles[themes]}`}>
        {intl.TEXT_PRODUCT}
      </th>
      <th className={`col-2 ${styles.cell} ${styles[themes]}`}>
        {intl.TEXT_TICKET_NUMBER}
      </th>
      <th className={`col-2 ${styles.cell} ${styles[themes]}`}>
        {intl.TEXT_STATUS}
      </th>
      <th className={`col-2 ${styles.cell} ${styles[themes]}`}>
        {intl.TEXT_DATE}
      </th>
      <th className={`col-2 ${styles.cell} ${styles[themes]}`}>
        {intl.PRODUCT_VALUE}
      </th>
    </tr>
  );

  const headerMobile = (
    <tr className={`row ${styles.header} ${styles[themes]}`}>
      <th
        className={`col-10 ${styles.cellMobile} ${styles[themes]} ${styles.cellMobilePaddingLeft}`}
      >
        {intl.TEXT_PRODUCT_LABEL}
      </th>
    </tr>
  );

  useEffect(() => {
    setCopyAllTotal([...JSON.parse(JSON.stringify(allTotalClaim))]);
    setAllTotal([...JSON.parse(JSON.stringify(allTotalClaim))]);

    setNumberTicket('');
    setCheckAll(false);
  }, [allTotalClaim]);

  const applyFiltersToData = () => {
    let filterData = JSON.parse(JSON.stringify(copyAllTotal));
    const filteredData = filterData.filter(i => {
      return Number(i.value) > 0;
    });

    return filteredData;
  };

  useEffect(() => {
    const timeout = setTimeout(function () {
      const data = applyFiltersToData();
      setCheckAll(false);
      setAllTotal(data);
      setpage(1);
    }, 700);

    return () => {
      clearTimeout(timeout);
    };
  }, [numberTicket]);

  useEffect(() => {
    window.scrollTo({
      top: isMobile
        ? window.innerHeight - window.innerHeight * 0.1
        : window.innerHeight - window.innerHeight * 0.6,
      behavior: 'smooth',
    });
  }, [page]);
  useEffect(() => {
    const copy = [...JSON.parse(JSON.stringify(allTotal))];
    for (let i = 0; i < copy.length; i++) {
      copy[i].checked = checkAll;
    }
    setAllTotal(copy);
  }, [checkAll]);

  const clickExpand = index => {
    const base = amountItemsByPage * (page - 1);
    const copy = [...JSON.parse(JSON.stringify(allTotal))];
    copy[index + base].expand = !copy[index + base].expand;
    if (lastShowIndex !== index + base && lastShowIndex < copy.length) {
      copy[lastShowIndex].expand = false;
    }
    setLastShowIndex(index + base);
    setAllTotal(copy);
  };

  const content = allTotal
    .slice(amountItemsByPage * (page - 1), amountItemsByPage * page)
    .map((item, index) => {
      const formatDate = formatDateFromString(item.info.date, idiom);
      return (
        <tr className={`row ${styles.item} ${styles[themes]}`} key={index}>
          <td className={`col-2 ${styles.cell} ${styles[themes]}`}>
            {item.info.referenceName}
          </td>
          <td className={`col-2 ${styles.cell} ${styles[themes]}`}>
            {item.info.ticket_number}
          </td>
          <td
            className={`col-2 ${styles.cell} ${styles.status} ${styles[themes]}`}
          >
            <StatusBadge
              context="MONETIZATION"
              status={item.info.status?.toUpperCase()}
            />
          </td>
          <td className={`col-2 ${styles.cell} ${styles[themes]}`}>
            {formatDate}
          </td>
          <td className={`col-2 ${styles.cell} ${styles[themes]}`}>
            {item.currencyInitialsCode}
            {item.value.toFixed(2).split('.')[0]}
            <span className={`${styles.decimal}`}>
              {`.${item.value.toFixed(2).split('.')[1]}`}
            </span>
          </td>
        </tr>
      );
    });

  const contentMobile = allTotal
    .slice(amountItemsByPage * (page - 1), amountItemsByPage * page)
    .map((item, index) => {
      const formatDate = formatDateFromString(item.info.date, idiom);
      return (
        <>
          <tr
            className={`row ${styles.itemMobile} ${
              item.expand ? styles.NoBottomBorder : ''
            } ${styles[themes]}`}
            key={index}
          >
            <td className={`col-8 ${styles.cellMobile} ${styles[themes]}`}>
              {item.info.referenceName}
            </td>
            <td
              className={`col-2 ${styles.cellMobileEnd} ${styles[themes]} ${
                item.expand ? styles.rotateIcon : ''
              }`}
              onClick={_ => {
                clickExpand(index);
              }}
            >
              <ChevronGeneric aria-label={intl.IMAGE_ARROW} />
            </td>
          </tr>
          {item.expand && (
            <tr className={`row ${styles.itemMobile} ${styles[themes]}`}>
              <div className={`row ${styles.itemExpand}`}>
                <div className={`col-6`}>
                  <div className={`${styles[themes]} ${styles.label}`}>
                    {intl.TEXT_TICKET_NUMBER}
                  </div>
                  <div className={`${styles.contentRow}`}>
                    {item.info.ticket_number}
                  </div>
                </div>
                <div className={`col-6`}>
                  <div className={`${styles[themes]} ${styles.label}`}>
                    {intl.TEXT_STATUS}
                  </div>
                  <div className={`${styles.contentRow}`}>
                    <StatusBadge
                      context="MONETIZATION"
                      status={item.info.status?.toUpperCase()}
                    />
                  </div>
                </div>
              </div>
              <div className={`row ${styles.itemExpand}`}>
                <div className={`col-6`}>
                  <div className={`${styles[themes]} ${styles.label}`}>
                    {intl.TEXT_DATE}
                  </div>
                  <div className={`${styles.contentRow}`}>{formatDate}</div>
                </div>
                <div className={`col-6`}>
                  <div className={`${styles[themes]} ${styles.label}`}>
                    {intl.PRODUCT_VALUE}
                  </div>
                  <div className={`${styles.contentRow}`}>
                    {item.currencyInitialsCode}
                    {item.value.toFixed(2)}
                  </div>
                </div>
              </div>
            </tr>
          )}
        </>
      );
    });

  return (
    <div className={`${styles.container} ${styles[themes]}`}>
      <div className={`row ${styles.smallPaddingBottom} ${styles[themes]}`}>
        <div className={`col-12 ${styles[themes]}`} id="claims">
          <TitleSectionProfile
            title={intl.TITLE_CLAIMS_MONETIZATION}
            subtitle={intl.SUBTITLE_CLAIMS_MONETIZATION}
            loading={false}
          />
          <TotalReimbursed
            bottom
            type={CLAIMS}
            certificateData={userCertificates}
            shouldShowTooltip
            returnAllClaims={returnAllClaims}
            setTotalMonetization={setTotal}
          />
        </div>
      </div>

      {loadingClaims && (
        <div className={`row ${styles.center}`}>
          <div
            className={`col-lg-3 col-md-3 col-sm-3 col-xs-3 ${styles.center} ${styles[themes]}`}
          >
            <Loading />
          </div>
        </div>
      )}

      {!loadingClaims && Number(total) > 0 && (
        <div className={`row ${styles.center} ${styles[themes]}`}>
          <div
            className={`col-lg-12 col-md-12 col-sm-12 col-xs-12 ${styles.center} ${styles[themes]}`}
          >
            <table className={styles.table}>
              <thead>{isMobile ? headerMobile : header}</thead>
              <tbody className={styles.body}>
                {isMobile ? contentMobile : content}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {!loadingClaims && Number(total) > 0 && (
        <div className={`row ${styles.center}`}>
          <div
            className={`col-lg-12 col-md-12 col-sm-12 col-xs-12 ${styles.pagesLabel} ${styles[themes]}`}
          >
            {intl.TEXT_PAGE} {page} {intl.TEXT_PAGEOF}
            {` ${Array(Math.ceil(allTotal.length / amountItemsByPage)).length}`}
          </div>
          <div
            className={`col-lg-12 col-md-12 col-sm-12 col-xs-12 ${styles.center} ${styles[themes]}`}
          >
            <Pagination
              data={allTotal}
              amountItemsByPage={amountItemsByPage}
              setPage={setpage}
              page={page}
              isMobile={isMobile}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(MonetizationClaims);
