import { ChangeEvent, useRef, useState } from 'react';
import styles from './FileUploader.module.scss';
import FileCard from './FileCard/FileCard';
import Button from '../../components/Button/Button';
import { useTheme } from '../../themes/ThemeContextParent/ThemeContextParent';
import * as translations from './intl';
import { useIntl } from '../../intl';

export interface FileUploaderProps {
  title: string;
  description: string;
  callbackAddFile: (file: File) => Promise<void>;
  callbackDeleteFile: (file: File) => Promise<void>;
  initialFilesList?: File[];
  multiple?: boolean;
  required?: boolean;
  hasLabel?: boolean;
}

export type FileStatus = 'SENT' | 'NOT SENT' | 'SENDING';

const FileUploader = ({
  title,
  description,
  callbackAddFile,
  callbackDeleteFile,
  initialFilesList,
  multiple,
  required,
  hasLabel,
}: FileUploaderProps) => {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<File[]>(initialFilesList || []);
  const [filesStatus, setFileStatus] = useState<FileStatus[]>(
    initialFilesList ? new Array(initialFilesList.length).fill('SENT') : []
  );

  const { getGlobalTheme, getCurrentThemeColors, getColors } = useTheme();
  const { translate } = useIntl();
  const { primary } = getCurrentThemeColors();
  const {
    genericColors: { gray_20, white, gray_100 },
  } = getColors();

  const intl = translate(translations);
  const theme = getGlobalTheme();

  const handleUploadFile = () => {
    inputFileRef.current.click();
  };

  const uploadFile = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      const file = inputFileRef.current.files[0];
      setFileStatus(currentStatus => [...currentStatus, 'SENDING']);
      setFiles(currentFiles => [...currentFiles, file]);

      await callbackAddFile(file);

      setFileStatus(currentStatus => {
        const clone = [...currentStatus];
        clone[clone.length - 1] = 'SENT';
        return clone;
      });
    } catch (error) {
      setFileStatus(currentStatus => {
        const clone = [...currentStatus];
        clone[clone.length - 1] = 'NOT SENT';
        return clone;
      });
    }
  };

  const removeFile = async (index: number) => {
    try {
      setFileStatus(currentStatus => {
        const clone = [...currentStatus];
        clone[index] = 'SENDING';
        return clone;
      });
      await callbackDeleteFile(files[index]);

      setFiles(files => {
        const clone = [...files];
        clone.splice(index, 1);
        return clone;
      });
      setFileStatus(status => {
        const clone = [...status];
        clone.splice(index, 1);
        return clone;
      });
    } catch (error) {
      setFileStatus(currentStatus => {
        const clone = [...currentStatus];
        clone[index] = 'SENT';
        return clone;
      });
    }
  };

  const reloadFile = async (index: number) => {
    try {
      setFileStatus(currentStatus => {
        const clone = [...currentStatus];
        clone[index] = 'SENDING';
        return clone;
      });

      await callbackAddFile(files[index]);

      setFileStatus(currentStatus => {
        const clone = [...currentStatus];
        clone[index] = 'SENT';
        return clone;
      });
    } catch (error) {
      setFileStatus(currentStatus => {
        const clone = [...currentStatus];
        clone[index] = 'NOT SENT';
        return clone;
      });
    }
  };

  return (
    <div className={`${styles.fileUploaderContainer} ${styles[theme]}`}>
      {hasLabel && (
        <div
          className={`${styles.label}`}
          style={{
            background: required ? primary : gray_20,
            color: required ? white : gray_100,
          }}
        >
          <span>{required ? intl.LABEL_REQUIRED : intl.LABEL_OPTIONAL}</span>
        </div>
      )}
      <div
        className={`${styles.fileUploaderBody} ${
          hasLabel ? '' : styles.borderNoLabel
        }`}
      >
        <header className={styles.bodyHeader}>
          <h5 className={styles[theme]}>{title}</h5>
          <p>{description}</p>
        </header>

        {files.length > 0 && (
          <div className={styles.content}>
            <ul>
              {files.map((file, index) => (
                <FileCard
                  file={file}
                  key={file.name}
                  removeFile={() => removeFile(index)}
                  fileStatus={filesStatus[index]}
                  reloadFile={() => reloadFile(index)}
                />
              ))}
            </ul>
          </div>
        )}

        {(multiple || (!multiple && files.length === 0)) && (
          <footer className={styles.actions}>
            <input
              type="file"
              ref={inputFileRef}
              onChange={uploadFile}
              style={{ display: 'none' }}
            />
            <div className={styles.wrapperButton}>
              <Button
                type="borderBlue"
                typeHtml="button"
                onClick={handleUploadFile}
              >
                {intl.BUTTON_ADD_FILE}
              </Button>
            </div>
          </footer>
        )}
      </div>
    </div>
  );
};

export default FileUploader;
