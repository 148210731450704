import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import Input from '../../../../../../../../components/Input/Input';
import RadioButton from '../../../../../../../../components/RadioButton/RadioButton';
import Select from '../../../../../../../../components/Select/Select';
import styles from '../BaggageLoss.module.scss';
import * as translations from './intl';
import sanitize from '../../../../../../../../utils/sanitize';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';

const InsuranceArea = props => {
  const { translate } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const intl = translate(translations);
  const themes = getGlobalTheme();

  const { values, handleChange, touched, handleBlur, errors, setFieldValue } =
    props;
  return (
    <>
      {/* Complaint against the transport service */}
      <div className={`row`} style={{ marginTop: '20px' }}>
        <div className="col-12 col-md-6">
          <Select
            label={intl.TEXT_SUBMITTED_COMPLAINT}
            name="formal_complaint.is_submitted"
            value={values.formal_complaint.is_submitted}
            onBlur={handleBlur}
            touched={
              touched.formal_complaint && touched.formal_complaint.is_submitted
            }
            error={
              errors.formal_complaint && errors.formal_complaint.is_submitted
            }
            onChange={e => {
              handleChange(e);
              setFieldValue('formal_complaint.submission_reason', '');
            }}
          >
            <option value="">{intl.TEXT_SUBMITTED_COMPLAINT}</option>
            <option value={true}>{intl.TEXT_OPTION_YES}</option>
            <option value={false}>{intl.TEXT_OPTION_NO}</option>
          </Select>
        </div>
        <div className="col-12 col-md-6">
          <div className="row">
            <div
              className={`col-12 ${
                values.formal_complaint.is_submitted === 'true'
                  ? ''
                  : styles.none
              }`}
            >
              <Input
                label={intl.TEXT_PIR}
                tooltipText={intl.TOOLTIP_PIR}
                name="formal_complaint.submission_reason"
                type="text"
                formikProps={props}
                maxLength="10"
              />
            </div>

            <div
              className={`col-12 ${
                values.formal_complaint.is_submitted === 'false'
                  ? ''
                  : styles.none
              }`}
              data-testid="DivComplaintReasonField"
            >
              <Input
                label={intl.TEXT_PLEASE_EXPLAIN}
                name="formal_complaint.submission_reason"
                type="text"
                formikProps={props}
                maxLength="255"
              />
            </div>
          </div>
        </div>
      </div>

      {/* receive indemnification by the transportation*/}
      <div className="row">
        <div className="col-12">
          <div className={`${styles.elementRadio} row`}>
            <div className={`col-lg-12 col-sm-12`}>
              <label className={`${styles.labelTitle} ${styles[themes]}`}>
                {intl.TEXT_INDEMNIFICATION_TRANSPORTATION}
              </label>
            </div>
            <div className={`${styles.option} col-12`}>
              <RadioButton
                label={intl.TEXT_INDEMNIFICATION_TRANSPORTATION}
                describe={intl.TEXT_DESCRIBE_OPTION_YES}
                defaultChecked={
                  values.other_company_covering.checked === 'true'
                }
                text={intl.TEXT_OPTION_YES}
                name="other_company_covering.checked"
                dataTestid="InputOtherCompanyCoveringYes"
                value={true}
                onChange={e => {
                  handleChange(e);
                }}
              />
            </div>
            <div className={`${styles.option} col-12`}>
              <RadioButton
                label={intl.TEXT_INDEMNIFICATION_TRANSPORTATION}
                describe={intl.TEXT_DESCRIBE_OPTION_NO}
                defaultChecked={
                  values.other_company_covering.checked === 'false'
                }
                text={intl.TEXT_OPTION_NO}
                name="other_company_covering.checked"
                dataTestid="InputOtherCompanyCoveringNo"
                value={false}
                onChange={e => {
                  handleChange(e);
                  setFieldValue('other_company_covering.value', '');
                }}
              />
            </div>
          </div>

          <div className="row">
            <div
              className={`col-12 col-sm-12 col-md-6 ${
                values.other_company_covering.checked !== 'true'
                  ? styles.none
                  : ''
              }`}
              data-testid="DivOtherCompanyValue"
            >
              <Input
                label={intl.TEXT_AMOUNT_TRANSPORTATION}
                type="text"
                maskedMoney
                name="other_company_covering.value"
                dataTestid="InputOtherCompanyCoveringValue"
                tooltipText={intl.TEXT_DECIMAL_SYMBOL}
                value={values.other_company_covering.value}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={
                  touched.other_company_covering &&
                  touched.other_company_covering.value
                }
                error={
                  errors.other_company_covering &&
                  errors.other_company_covering.value
                }
              />
            </div>
            <div
              className={`col-12 col-sm-12 col-md-6 ${
                values.other_company_covering.checked !== 'true'
                  ? styles.none
                  : ''
              }`}
            >
              <Select
                label={intl.LABEL_CURRENCY}
                name="other_company_covering.currency"
                value={values.total_amount_claimed.currency}
                disabled
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  errors.other_company_covering &&
                  errors.other_company_covering.currency
                }
                dangerouslySetInnerHTML={{
                  __html: sanitize(
                    `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
                  ),
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* receive indemnification by the transportation*/}
      <div className="row">
        <div className="col-12">
          <div className={`${styles.elementRadio} row`}>
            <div className={`col-lg-12 col-sm-12`}>
              <label className={`${styles.labelTitle} ${styles[themes]}`}>
                {intl.TEXT_INSURANCE_CLAIMED}
              </label>
            </div>
            <div className={`${styles.option} col-12`}>
              <RadioButton
                label={intl.TEXT_INSURANCE_CLAIMED}
                describe={intl.TEXT_DESCRIBE_OPTION_YES}
                defaultChecked={
                  values.other_insurance_covering.checked === 'true'
                }
                text={intl.TEXT_OPTION_YES}
                name="other_insurance_covering.checked"
                dataTestid="InputOtherInsuranceCoveringYes"
                value={true}
                onChange={e => {
                  handleChange(e);
                }}
              />
            </div>
            <div className={`${styles.option} col-12`}>
              <RadioButton
                label={intl.TEXT_INSURANCE_CLAIMED}
                describe={intl.TEXT_DESCRIBE_OPTION_NO}
                defaultChecked={
                  values.other_insurance_covering.checked === 'false'
                }
                text={intl.TEXT_OPTION_NO}
                name="other_insurance_covering.checked"
                dataTestid="InputOtherInsuranceCoveringNo"
                value={false}
                onChange={e => {
                  handleChange(e);
                  setFieldValue('other_insurance_covering.value', '');
                }}
              />
            </div>
          </div>
          <div className="row">
            <div
              className={`col-12 col-sm-12 col-md-6 ${
                values.other_insurance_covering.checked !== 'true'
                  ? styles.none
                  : ''
              }`}
              data-testid="DivOtherInsuranceCoveringField"
            >
              <Input
                label={intl.TEXT_AMOUNT_PAID_INSURANCE}
                type="text"
                maskedMoney
                name="other_insurance_covering.value"
                dataTestid="InputOtherInsuranceCoveringValue"
                tooltipText={intl.TEXT_DECIMAL_SYMBOL}
                value={values.other_insurance_covering.value}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={
                  touched.other_insurance_covering &&
                  touched.other_insurance_covering.value
                }
                error={
                  errors.other_insurance_covering &&
                  errors.other_insurance_covering.value
                }
              />
            </div>
            <div
              className={`col-12 col-sm-12 col-md-6 ${
                values.other_insurance_covering.checked !== 'true'
                  ? styles.none
                  : ''
              }`}
            >
              <Select
                label={intl.LABEL_CURRENCY}
                name="other_insurance_covering.currency"
                dataTestid="InputOtherInsuranceCoveringCurrency"
                value={values.total_amount_claimed.currency}
                disabled
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  errors.other_insurance_covering &&
                  errors.other_insurance_covering.currency
                }
                dangerouslySetInnerHTML={{
                  __html: sanitize(
                    `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
                  ),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InsuranceArea;
