import { SVGProps } from 'react';
import { useTheme } from '../../../themes/ThemeContextParent/ThemeContextParent';

const InfoIcon = ({ color, ...rest }: SVGProps<SVGSVGElement>) => {
  const { getColors } = useTheme();
  const { genericColors } = getColors();
  const defaultColor = genericColors.blue_70;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...rest}
    >
      <path
        d="M8 15C4.13388 15 1 11.8661 1 8C1 4.13388 4.13388 1 8 1C11.8661 1 15 4.13388 15 8C15 11.8661 11.8661 15 8 15Z"
        stroke={color || defaultColor}
        strokeWidth="2"
      />
      <path d="M8 6L8 4" stroke={color || defaultColor} strokeWidth="2" />
      <path d="M8 12L8 7" stroke={color || defaultColor} strokeWidth="2" />
    </svg>
  );
};

export default InfoIcon;
