/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState, lazy } from 'react';

import MainLayout from '../layouts/MainLayout/MainLayout';
import {
  HEADER_TRANSPARENT_COLOR_DARK_BLUE,
  HEADER_TRANSPARENT_COLOR_WHITE,
} from '../components/Header/headerConfig';
import { StateContext } from '../components/StateContextParent/StateContextParent';
import { Redirect, useLocation } from 'react-router-dom';
import { userServices } from '../services';
import { dataLayerCrossSiteTrack } from '../utils/GTM_helper';

import LayoutRoute from '../components/LayoutRoute/LayoutRoute';
import Home from '../pages/Home/Home';
import PublicBenefits from '../pages/PublicBenefits/PublicBenefits';
import BenefitsDetail from '../pages/BenefitsDetail/BenefitsDetail';
import Help from '../pages/Help/Help';
import Logout from '../pages/Logout/Logout';
import SessionExpired from '../pages/SessionExpired/SessionExpired';
import EmptyLayoutHalfBlue from '../layouts/EmptyLayoutHalfBlue/EmptyLayoutHalfBlue';
import SpecialBenefits from '../pages/SpecialBenefits/SpecialBenefits';
import ConfirmationLinkExpired from '../pages/ConfirmationLinkExpired/ConfirmationLinkExpired';
import Login from '../components/Login/Login';
import PageNotFound from '../pages/PageNotFound/PageNotFound';
import NotificationDetail from '../pages/NotificationDetail/NotificationDetail';
import SpecialBenefitsDetail from '../pages/SpecialBenefitsDetail/SpecialBenefitsDetail';
import RedirectToPDF from '../pages/RedirectToPDF/RedirectToPDF';
import DownloadApp from '../pages/DownloadApp/DownloadApp';
import { ENABLE_DOWNLOAD_APP_PAGE } from '../utils/environments_variables';
import DownloadFromStore from '../pages/DownloadApp/DownloadFromStore';
import TravelKitMarketing from '../pages/DownloadApp/TravelKitMarketing/TravelKitMarketing';
import Chat from '../pages/Chat/Chat';
import EmptyLayout from '../layouts/EmptyLayout/EmptyLayout';
import OpenApp from '../pages/OpenApp/OpenApp';
import QuoteDetail from '../pages/B2B2C/QuoteDetail/QuoteDetail';
import Maintenance from '../pages/Maintenance/Maintenance';
import Auth0Layout from '../layouts/Auth0Layout/Auth0Layout';
import RecoverAccount from '../pages/RecoverAccount/RecoverAccount';

const IsLoggedPublic = props => {
  const { state } = useContext(StateContext);
  const location = useLocation();
  const { redirect } = props;
  const [user, setUser] = useState('');
  const getUserInfoLogged = async () => {
    try {
      await userServices.getUserInfo();
    } catch (error) {
      setUser('NOT_LOGGED');
    }
  };

  useEffect(() => {
    if (!state.user.logged) {
      trackInformation(location, state.user.logged);
      getUserInfoLogged();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.user.logged]);

  if (state.user.logged) {
    return <Redirect to={redirect} />;
  }

  return user === 'NOT_LOGGED' ? props.children : <></>;
};

const trackInformation = (history, isLogged) => {
  if (history) {
    dataLayerCrossSiteTrack(
      history.pathname === '/' ? '/home' : history.pathname || '',
      isLogged
    );
  }
};

const TrackPublicRoutes = props => {
  const { state } = useContext(StateContext);
  const location = useLocation();

  useEffect(() => {
    if (!state.user.logged) {
      trackInformation(location, state.user.logged);
    }
  }, [state.user.logged, location]);

  return props.children;
};

export const PublicRoutesBinNotActived = [
  <LayoutRoute
    exact={true}
    path="/"
    key="/"
    component={Home}
    layout={props => (
      <IsLoggedPublic {...props} redirect="/dashboard">
        <MainLayout {...props} headerType={HEADER_TRANSPARENT_COLOR_WHITE} />
      </IsLoggedPublic>
    )}
  />,
  <LayoutRoute
    path="/benefits/:product_id/:benefit_id"
    key="/benefits/:product_id/:benefit_id"
  >
    <Redirect to="/PageNotFound-insertPan" />
  </LayoutRoute>,
  <LayoutRoute path="/benefits/:product_id/" key="/benefits/:product_id/">
    <Redirect to="/PageNotFound-insertPan" />
  </LayoutRoute>,
  <LayoutRoute path="/benefits" key="/benefits">
    <Redirect to="/PageNotFound-insertPan" />
  </LayoutRoute>,
  <LayoutRoute
    path="/subscription-benefits/:benefit_id"
    key="/subscription-benefits/:benefit_id"
  >
    <Redirect to="/PageNotFound-insertPan" />
  </LayoutRoute>,
  <LayoutRoute path="/subscription-benefits" key="/subscription-benefits">
    <Redirect to="/PageNotFound-insertPan" />
  </LayoutRoute>,
  <LayoutRoute
    exact={true}
    path="/signout"
    key="/signout"
    component={Logout}
    layout={props => (
      <TrackPublicRoutes {...props}>
        <MainLayout {...props} />
      </TrackPublicRoutes>
    )}
  />,

  <LayoutRoute
    path="/session-expired"
    key="/session-expired"
    component={SessionExpired}
    layout={EmptyLayoutHalfBlue}
  />,

  <LayoutRoute
    path="/help"
    key="/help"
    component={Help}
    layout={props => (
      <TrackPublicRoutes {...props}>
        <MainLayout {...props} />
      </TrackPublicRoutes>
    )}
  />,
  <LayoutRoute
    path="/verify_email_result"
    key="/verify_email_result"
    component={ConfirmationLinkExpired}
    layout={props => (
      <TrackPublicRoutes {...props}>
        <MainLayout
          {...props}
          headerType={HEADER_TRANSPARENT_COLOR_DARK_BLUE}
        />
      </TrackPublicRoutes>
    )}
  />,
  <LayoutRoute
    key="/login"
    path="/login"
    component={Login}
    layout={props => (
      <TrackPublicRoutes {...props}>
        <MainLayout
          {...props}
          headerType={HEADER_TRANSPARENT_COLOR_DARK_BLUE}
        />
      </TrackPublicRoutes>
    )}
  />,
  <LayoutRoute
    path="/notification-detail/privacy-note"
    key="/notification-detail/privacy-note"
    component={props => <NotificationDetail {...props} type="privacyNote" />}
    layout={props => (
      <TrackPublicRoutes {...props}>
        <MainLayout
          {...props}
          headerType={HEADER_TRANSPARENT_COLOR_DARK_BLUE}
        />
      </TrackPublicRoutes>
    )}
  />,
  <LayoutRoute
    key="/PageNotFound-insertPan"
    path="/PageNotFound-insertPan"
    component={() => <PageNotFound insertNewCard />}
    layout={EmptyLayoutHalfBlue}
  />,
  <LayoutRoute
    path="/download-app/store"
    key="/download-app/store"
    component={ENABLE_DOWNLOAD_APP_PAGE ? DownloadFromStore : PageNotFound}
    layout={ENABLE_DOWNLOAD_APP_PAGE ? MainLayout : EmptyLayoutHalfBlue}
  />,
  <LayoutRoute
    path="/download-app"
    key="/download-app"
    component={ENABLE_DOWNLOAD_APP_PAGE ? DownloadApp : PageNotFound}
    layout={ENABLE_DOWNLOAD_APP_PAGE ? MainLayout : EmptyLayoutHalfBlue}
  />,

  <LayoutRoute
    path="/maintenance"
    key="/maintenance"
    component={Maintenance}
    layout={EmptyLayoutHalfBlue}
  />,

  <LayoutRoute
    key="/PageNotFound"
    path="*"
    component={PageNotFound}
    layout={EmptyLayoutHalfBlue}
  />,
];

export const PublicRoutes = [
  <LayoutRoute
    exact={true}
    path="/"
    key="/"
    component={Home}
    layout={props => (
      <IsLoggedPublic {...props} redirect="/dashboard">
        <MainLayout {...props} headerType={HEADER_TRANSPARENT_COLOR_WHITE} />
      </IsLoggedPublic>
    )}
  />,
  <LayoutRoute
    path="/benefits/:product_id/:benefit_id"
    key="/benefits/:product_id/:benefit_id"
    component={BenefitsDetail}
    layout={props => (
      <TrackPublicRoutes {...props}>
        <IsLoggedPublic
          {...props}
          redirect={`/your-benefits/${props.benefit_id}`}
        >
          <MainLayout {...props} />
        </IsLoggedPublic>
      </TrackPublicRoutes>
    )}
  />,
  <LayoutRoute
    path="/benefits/:product_id/"
    key="/benefits/:product_id/"
    component={PublicBenefits}
    layout={props => (
      <TrackPublicRoutes {...props}>
        <IsLoggedPublic
          {...props}
          redirect={`/your-benefits/${props.product_id}`}
        >
          <MainLayout
            {...props}
            headerType={HEADER_TRANSPARENT_COLOR_DARK_BLUE}
          />
        </IsLoggedPublic>
      </TrackPublicRoutes>
    )}
  />,
  <LayoutRoute
    path="/benefits"
    key="/benefits"
    component={PublicBenefits}
    layout={props => (
      <IsLoggedPublic {...props} redirect="/your-benefits">
        <MainLayout
          {...props}
          headerType={HEADER_TRANSPARENT_COLOR_DARK_BLUE}
        />
      </IsLoggedPublic>
    )}
  />,
  <LayoutRoute
    path="/subscription-benefits/:benefit_id"
    key="/subscription-benefits/:benefit_id"
    component={SpecialBenefitsDetail}
    layout={props => (
      <IsLoggedPublic
        redirect={`/your-subscription-benefits/${props.benefit_id}`}
      >
        <MainLayout {...props} headerType={HEADER_TRANSPARENT_COLOR_WHITE} />
      </IsLoggedPublic>
    )}
  />,
  <LayoutRoute
    path="/subscription-benefits"
    key="/subscription-benefits"
    component={SpecialBenefits}
    layout={props => (
      <IsLoggedPublic {...props} redirect="/your-subscription-benefits">
        <MainLayout {...props} headerType={HEADER_TRANSPARENT_COLOR_WHITE} />
      </IsLoggedPublic>
    )}
  />,
  <LayoutRoute
    exact={true}
    path="/signout"
    key="/signout"
    component={Logout}
    layout={props => (
      <TrackPublicRoutes {...props}>
        <MainLayout {...props} />
      </TrackPublicRoutes>
    )}
  />,

  <LayoutRoute
    path="/session-expired"
    key="/session-expired"
    component={SessionExpired}
    layout={EmptyLayoutHalfBlue}
  />,

  <LayoutRoute
    path="/help"
    key="/help"
    component={Help}
    layout={props => (
      <TrackPublicRoutes {...props}>
        <MainLayout {...props} />
      </TrackPublicRoutes>
    )}
  />,
  <LayoutRoute
    path="/verify_email_result"
    key="/verify_email_result"
    component={ConfirmationLinkExpired}
    layout={props => (
      <TrackPublicRoutes {...props}>
        <MainLayout
          {...props}
          headerType={HEADER_TRANSPARENT_COLOR_DARK_BLUE}
        />
      </TrackPublicRoutes>
    )}
  />,
  <LayoutRoute
    key="/login"
    path="/login"
    component={Login}
    layout={props => (
      <TrackPublicRoutes {...props}>
        <MainLayout
          {...props}
          headerType={HEADER_TRANSPARENT_COLOR_DARK_BLUE}
        />
      </TrackPublicRoutes>
    )}
  />,
  <LayoutRoute
    path="/notification-detail/privacy-note"
    key="/notification-detail/privacy-note"
    component={props => <NotificationDetail {...props} type="privacyNote" />}
    layout={props => (
      <TrackPublicRoutes {...props}>
        <MainLayout
          {...props}
          headerType={HEADER_TRANSPARENT_COLOR_DARK_BLUE}
        />
      </TrackPublicRoutes>
    )}
  />,
  <LayoutRoute
    path="/terms/:type/:benefit/:country"
    key="/terms/:type/:benefit/:country"
    component={RedirectToPDF}
    layout={props => <TrackPublicRoutes {...props}></TrackPublicRoutes>}
  />,
  <LayoutRoute
    path="/download-app/travel-kit"
    key="/download-app/travel-kit"
    component={ENABLE_DOWNLOAD_APP_PAGE ? TravelKitMarketing : PageNotFound}
    layout={props => (
      <IsLoggedPublic {...props} redirect={`/download-your-app/travel-kit`}>
        {ENABLE_DOWNLOAD_APP_PAGE ? (
          <MainLayout {...props} />
        ) : (
          EmptyLayoutHalfBlue
        )}
      </IsLoggedPublic>
    )}
  />,
  <LayoutRoute
    path="/download-app/store"
    key="/download-app/store"
    component={ENABLE_DOWNLOAD_APP_PAGE ? DownloadFromStore : PageNotFound}
    layout={ENABLE_DOWNLOAD_APP_PAGE ? MainLayout : EmptyLayoutHalfBlue}
  />,
  <LayoutRoute
    path="/download-app"
    key="/download-app"
    component={ENABLE_DOWNLOAD_APP_PAGE ? DownloadApp : PageNotFound}
    layout={props => (
      <IsLoggedPublic {...props} redirect={`/download-your-app`}>
        {ENABLE_DOWNLOAD_APP_PAGE ? (
          <MainLayout {...props} />
        ) : (
          EmptyLayoutHalfBlue
        )}
      </IsLoggedPublic>
    )}
  />,
  <LayoutRoute
    path="/chat"
    key="/chat"
    component={Chat}
    layout={props => <EmptyLayout logoVisa={false} {...props} />}
  />,

  <LayoutRoute
    path="/open-app"
    key="/open-app"
    component={OpenApp}
    layout={props => <EmptyLayout logoVisa={false} {...props} />}
  />,

  <LayoutRoute
    path="/upsell-preview/:benefit_id"
    key="/upsell-preview/:benefit_id"
    component={QuoteDetail}
    layout={props => (
      <IsLoggedPublic
        {...props}
        redirect={`/upsell-detail/${props.benefit_id}`}
      >
        <MainLayout
          {...props}
          headerType={HEADER_TRANSPARENT_COLOR_DARK_BLUE}
        />
      </IsLoggedPublic>
    )}
  />,
  <LayoutRoute
    path="/cross-sell-preview/:benefit_id"
    key="/cross-sell-preview/:benefit_id"
    component={QuoteDetail}
    layout={props => (
      <IsLoggedPublic
        {...props}
        redirect={`/cross-sell-detail/${props.benefit_id}`}
      >
        <MainLayout
          {...props}
          headerType={HEADER_TRANSPARENT_COLOR_DARK_BLUE}
        />
      </IsLoggedPublic>
    )}
  />,

  <LayoutRoute
    path="/maintenance"
    key="/maintenance"
    component={Maintenance}
    layout={EmptyLayoutHalfBlue}
  />,

  <LayoutRoute
    path="/recover-account"
    key="/recover-account"
    component={RecoverAccount}
    layout={props => <Auth0Layout thirdBlue {...props}></Auth0Layout>}
  />,

  <LayoutRoute
    key="/PageNotFound"
    path="*"
    component={PageNotFound}
    layout={EmptyLayoutHalfBlue}
  />,

  <LayoutRoute
    key="/document-not-found"
    path="/document-not-found"
    component={PageNotFound}
    layout={EmptyLayoutHalfBlue}
  />,
];
