import React, { useContext } from 'react';
import InfoLabelValue from '../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../intl';
import * as translations from '../../../../../intl/infoProfile';

const CardHolderEmailInfo = ({ userInfo }) => {
  const { translate, idiom, country } = useContext(IntlContext);
  const intl = translate(translations);

  return (
    <>
      <div className="row">
        <div className="col-12 col-sm-5 col-lg-4">
          <InfoLabelValue label={intl.LABEL_EMAIL} value={userInfo.email} />
        </div>
      </div>
    </>
  );
};

export default CardHolderEmailInfo;
