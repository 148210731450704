import { useContext } from 'react';
import styles from './ExtensionItem.module.scss';
import * as translations from './intl';
import StatusBadge from '../../../../../componentsV2/Badge/StatusBadge/StatusBadge';
import iconBenefitExtension from '../../../../../assets/images/icon/icon-benefitExtension.svg';
import { IntlContext } from '../../../../../intl';
import { formatDateFromString } from '../../../../../utils/date';
import { BenefitExtension } from '../../../../../@types/APIs/BenefitsExtensions';

interface ExtensionItemProps {
  item: BenefitExtension;
}

const ExtensionItem = ({ item }: ExtensionItemProps) => {
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);

  const coverageFromFormated = formatDateFromString(
    String(item.product_criteria.effective_period.from).substring(0, 10),
    idiom
  );
  const coverageToFormated = formatDateFromString(
    String(item.product_criteria.effective_period.to).substring(0, 10),
    idiom
  );

  return (
    <li className={styles.container}>
      <img src={iconBenefitExtension} />
      <div className={styles.textContent}>
        <div className={`${styles.contentArea} ${styles.productName}`}>
          {item.product_criteria && item.product_criteria.external_product_name}
        </div>
        <div className={`${styles.contentArea} ${styles.validity}`}>
          <span className={styles.label}>{intl.VALIDITY}</span>
          <span className={styles.label}>
            {coverageFromFormated} - {coverageToFormated}
          </span>
        </div>
        <div className={styles.contentArea}>
          <StatusBadge status={item.status} />
        </div>
      </div>
    </li>
  );
};

export default ExtensionItem;
