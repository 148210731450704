import LoaderIcon from '../../assets/iconsV2/LoaderIcon';
import styles from './Loader.module.scss';

const Loader = () => {
  return (
    <div className={styles.loaderContainer}>
      <LoaderIcon />
    </div>
  );
};

export default Loader;
