import * as Yup from 'yup';
import { validateCPF } from '../../../utils/stringUtils';

export const generateValidationSchema = (intl: any, isBR: boolean) => {
  return Yup.lazy(formValues => {
    return Yup.object().shape({
      person: Yup.object().shape({
        first_name: Yup.string().required(intl.TEXT_REQUIRED),
        last_name: Yup.string().required(intl.TEXT_REQUIRED),
        birth_date: Yup.string().required(intl.TEXT_REQUIRED),
      }),
      address: Yup.object().shape({
        country: Yup.string().required(intl.TEXT_REQUIRED),
      }),
      person_registrations: Yup.object().shape({
        registration_type: Yup.string().required(intl.TEXT_REQUIRED),
        value: Yup.string()
          .test('Validate CPF', intl.VALIDATE_CPF, function (value) {
            if (isBR) {
              return validateCPF(value);
            }
            return true;
          })
          .required(intl.TEXT_REQUIRED),
      }),
      phone: Yup.object().shape({
        phone_type: Yup.string().required(intl.TEXT_REQUIRED),
        number: Yup.string().required(intl.TEXT_REQUIRED),
        international_prefix: Yup.string().required(intl.TEXT_REQUIRED),
      }),
      currentEmail: Yup.string()
        .email(intl.VALIDATE_EMAIL_VALID)
        .required(intl.TEXT_REQUIRED),
      lostEmail: Yup.string()
        .email(intl.VALIDATE_EMAIL_VALID)
        .required(intl.TEXT_REQUIRED)
        .test('Check the same email', intl.TEXT_SAME_EMAIL, function (value) {
          return value !== formValues.currentEmail;
        }),
    });
  });
};
