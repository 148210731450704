import ErrorIcon from '../../assets/iconsV2/Badge/ErrorIcon';
import InfoIcon from '../../assets/iconsV2/Badge/InfoIcon';
import SuccessIcon from '../../assets/iconsV2/Badge/SuccessIcon';
import WarningIcon from '../../assets/iconsV2/Badge/WarningIcon';
import { useTheme } from '../../themes/ThemeContextParent/ThemeContextParent';
import { ThemeColorsGeneric } from '../../themes/ThemeContextParent/themes';
import styles from './Badge.module.scss';

export const configIcon = (themeGenericColors: ThemeColorsGeneric) => ({
  POSITIVE: SuccessIcon,
  NEGATIVE: ErrorIcon,
  WARNING: WarningIcon,
  INFO: InfoIcon,
  DISABLED: () => <InfoIcon color={themeGenericColors.gray_60} />,
});

export type BadgeType = keyof ReturnType<typeof configIcon>;

export interface BadgeProps {
  icon?: boolean;
  label: string;
  type: BadgeType;
}

const Badge = ({ icon, label, type }: BadgeProps) => {
  const { getColors } = useTheme();
  const colors = getColors();
  const Icon = configIcon(colors.genericColors)[type];

  return (
    <div className={`${styles.badgeContainer} ${styles[type]}`}>
      {icon && <Icon />}
      <span>{label}</span>
    </div>
  );
};

export default Badge;
