import { useTheme } from '../../../themes/ThemeContextParent/ThemeContextParent';

const DownloadIcon = () => {
  const { getCurrentThemeColors } = useTheme();
  const { primary } = getCurrentThemeColors();

  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Download">
        <path
          id="Primary"
          d="M12.5 11.3882L12.5 0M19.5 7.97171L13.9142 14.3329C13.1332 15.2224 11.8668 15.2224 11.0858 14.3329L5.5 7.97171"
          stroke={primary}
          strokeWidth="2"
        />
        <path
          id="Secondary"
          d="M2.68237 14V20C2.68237 21.1046 3.5778 22 4.68237 22H20.6824C21.7869 22 22.6824 21.1046 22.6824 20V14"
          stroke={primary}
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

export default DownloadIcon;
