export const TITLE_OCT_DETAILS = {
  en_US: 'Your Visa direct payment information',
  es_ES: 'Tu información de Visa Direct',
  pt_BR: 'Sua informação de pagamento direto Visa',
};

export const ADD_OCT_PAYMENT_METHOD = {
  en_US: 'Add Visa direct Payment',
  es_ES: 'Agregar una tarjeta de débito Visa nueva',
  pt_BR: 'Adicionar pagamento direto Visa',
};

export const ADD_SHORT = {
  en_US: 'Add',
  es_ES: 'Agregar',
  pt_BR: 'Adicionar',
};

export const TITLE_ADD_CONFIRM_CHANGE_MAIN_CARD = {
  en_US: 'Continue with new Visa direct paymend?',
  pt_BR: 'Continuar com novo pagamento direto Visa?',
  es_ES: '¿Continuar con una nueva tarjeta de débito Visa?',
};

export const SUBTITLE_ADD_CONFIRM_CHANGE_MAIN_CARD = {
  en_US: `Your new Visa direct payment will automatically be considered as the primary payment method.`,
  pt_BR:
    'Seu novo pagamento direto Visa será automaticamente considerado como o método de pagamento principal.',
  es_ES:
    'Tu tarjeta de débito Visa se actualizará como principal para recibir los depósitos de tus reclamos.',
};

export const BUTTON_ADD_CONFIRM_CHANGE_MAIN_CARD = {
  en_US: 'Continue with new Visa direct payment',
  pt_BR: 'Continuar com novo pagamento direto Visa',
  es_ES: 'Continuar con nueva tarjeta',
};

export const BUTTON_DENY_CONFIRM_CHANGE_MAIN_CARD = {
  en_US: 'Cancel',
  pt_BR: 'Cancelar',
  es_ES: 'Cancelar',
};
