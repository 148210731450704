import { useTheme } from '../../../themes/ThemeContextParent/ThemeContextParent';

const DeleteIcon = () => {
  const { getCurrentThemeColors } = useTheme();
  const { primary } = getCurrentThemeColors();

  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Delete">
        <path
          id="primary"
          d="M17.5 6H22.5V8H20.5V21C20.5 21.2652 20.3946 21.5196 20.2071 21.7071C20.0196 21.8946 19.7652 22 19.5 22H5.5C5.23478 22 4.98043 21.8946 4.79289 21.7071C4.60536 21.5196 4.5 21.2652 4.5 21V8H2.5V6H7.5V3C7.5 2.73478 7.60536 2.48043 7.79289 2.29289C7.98043 2.10536 8.23478 2 8.5 2H16.5C16.7652 2 17.0196 2.10536 17.2071 2.29289C17.3946 2.48043 17.5 2.73478 17.5 3V6ZM18.5 8H6.5V20H18.5V8ZM9.5 4V6H15.5V4H9.5Z"
          fill={primary}
        />
        <g id="secondary">
          <path d="M9.5 10H11.5V18H9.5V10Z" fill={primary} />
          <path d="M13.5 10H15.5V18H13.5V10Z" fill={primary} />
        </g>
      </g>
    </svg>
  );
};

export default DeleteIcon;
