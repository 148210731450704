import { useContext, useState, useEffect } from 'react';
import { IntlContext } from '../../../../intl';
import styles from './BenefitsExtensions.module.scss';
import ExtensionItem from './ExtensionItem/ExtensionItem';
import * as translations from './intl';
import Loading from '../../../../components/Loading/Loading';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import { enrollService } from '../../../../services';
import { StateContext } from '../../../../components/StateContextParent/StateContextParent';
import { B2B2CType, UPSELL } from '../../CONFIG_B2B2C';
import { BenefitExtension } from '../../../../@types/APIs/BenefitsExtensions';

interface BenefitsExtensionsProps {
  productType: B2B2CType;
}

const BenefitsExtensions = ({ productType }: BenefitsExtensionsProps) => {
  const [benefitsExtension, setBenefitsExtension] = useState<
    BenefitExtension[] | null
  >(null);
  const { translate } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const { utils } = useContext(StateContext);

  const intl = translate(translations);
  const theme = getGlobalTheme();
  const card = utils.getSelectedCard();
  const isUpsell = productType === UPSELL;

  const getBenefitsExtensions = async () => {
    try {
      const response = await enrollService.getBenefitExtensions(card.policy_id);
      const reverseArray = response.data.reverse();
      setBenefitsExtension(reverseArray);
    } catch (error) {
      setBenefitsExtension([]);
    }
  };

  useEffect(() => {
    getBenefitsExtensions();
  }, []);

  if (benefitsExtension?.length === 0) {
    return <></>;
  }

  return (
    <div className={styles.container}>
      <h2 className={`${styles.title} ${styles[theme]}`}>
        {isUpsell
          ? intl.TITLE_INCREASE_YOUR_TRAVEL_COVERAGE
          : intl.TITLE_MY_PURCHASES}
      </h2>

      {!benefitsExtension && (
        <div className={styles.wrapLoading}>
          <Loading />
        </div>
      )}

      <ul className={styles.listExtensions}>
        {benefitsExtension?.length > 0 &&
          benefitsExtension.map((extension, index) => (
            <ExtensionItem key={index} item={extension} />
          ))}
      </ul>
    </div>
  );
};

export default BenefitsExtensions;
