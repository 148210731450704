import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from '../intl';
import Input from '../../../../../../../../components/Input/Input';

import styles from './EmailArea.module.scss';

const EmailArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { values, handleChange, touched, handleBlur, errors } = props;

  return (
    <div className={`row ${styles.section}`}>
      <div className="col-12 col-sm-10 col-md-4 col-lg-4">
        <Input
          value={values.email}
          label={intl.LABEL_EMAIL}
          name="email"
          type="text"
          dataTestid="InputClaimCHFirstName"
          onChange={handleChange}
          touched={touched.email}
          onBlur={handleBlur}
          error={errors.email}
        />
      </div>
    </div>
  );
};

export default EmailArea;
