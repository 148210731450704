import React, { useContext, useMemo, useState } from 'react';
import { StateContext } from '../../../../../../../components/StateContextParent/StateContextParent';
import { IntlContext } from '../../../../../../../intl';
import * as translations from './intl';
import { Formik } from 'formik';
import { emailDataSchema } from '../validationSchema';
import styles from './EmailInformation.module.scss';

import EmailArea from './EmailArea/EmailArea';

const EmailInformation = props => {
  const { translate, idiom } = useContext(IntlContext);
  const [isCardHolder, setIsCardHolder] = useState(true);
  const intl = translate(translations);
  const { utils } = useContext(StateContext);
  const { initialValues, updateParent, policySubmitRef } = props;

  const card = utils.getSelectedCard();
  const cardHolderEmail = utils.getEmail();

  const validationSchema = useMemo(
    () => emailDataSchema(intl, cardHolderEmail),
    [card, intl]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={() => {}}
      enableReinitialize
    >
      {props => {
        const { values, handleSubmit, resetForm, errors } = props;
        updateParent(values, validationSchema);
        return (
          <form
            onSubmit={handleSubmit}
            data-testid="FullFormCliamEmailInformation"
          >
            <button
              ref={policySubmitRef}
              type="submit"
              style={{ display: 'none' }}
            ></button>

            <div className={styles.generalInformation}>
              <EmailArea {...props} />
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default EmailInformation;
