export const LABEL_EMAIL = {
  en_US: 'E-mail * ',
  pt_BR: 'E-mail * ',
  es_ES: 'Correo eletrónico * ',
};

export const EMAIL_INVALID_SAME_ACCOUNT = {
  en_US: 'This is the current email associate for this account.',
  pt_BR: 'Este é o e-mail atual associado a esta conta.',
  es_ES: 'Este es el correo electrónico actual asociado a esta cuenta.',
};
