import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

import MonetizationClaims from '../MonetizationClaims';
import MonetizationCertificates from '../MonetizationCertificates';
import DividingLine from '../../../components/DividingLine/DividingLine';

const Monetization = props => {
  const { advancedSearch, userCertificates } = props;

  useState(advancedSearch);

  return (
    <>
      <MonetizationCertificates
        {...props}
        advancedSearch={false}
        userCertificates={userCertificates}
      />

      <DividingLine />

      <MonetizationClaims
        {...props}
        advancedSearch={false}
        userCertificates={userCertificates}
      />
    </>
  );
};

export default withRouter(Monetization);
