import { useTheme } from '../../../themes/ThemeContextParent/ThemeContextParent';

const GenericDocumentIcon = () => {
  const { getCurrentThemeColors } = useTheme();
  const { primary } = getCurrentThemeColors();

  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Document">
        <path id="Secondary" d="M16 5.5H8V7.5H16V5.5Z" fill={primary} />
        <path
          id="Primary"
          d="M4 1.5V19.5H6V3.5H18V15.5H12V21.5H4V23.5H12C16.4111 23.5 20 19.9111 20 15.5V1.5H4ZM14 21.15V17.5H17.65C17.0462 19.1999 15.7 20.5462 14 21.15Z"
          fill={primary}
        />
      </g>
    </svg>
  );
};

export default GenericDocumentIcon;
