import { productsAndBenefitsServices } from '../../../../../services';
import { isTripAssistance } from '../../../../../utils/benefits';

export const loadBenefits = async (
  trackEventUserAction,
  externalReference,
  idiomForApi
) => {
  try {
    trackEventUserAction(`#### Certificate ### Requesting benefits`, true);
    const response = await productsAndBenefitsServices.getBenefitsByProduct(
      externalReference,
      idiomForApi(),
      false,
      false
    );

    trackEventUserAction(`#### Certificate ### Benefits requested`, {
      data: response.data,
    });
    return response.data;
  } catch (error) {
    trackEventUserAction(`#### Certificate ### Error requesting benefits`, {
      error,
    });
    return [];
  }
};

const getTripAssistanceBenefit = benefits =>
  Array.isArray(benefits) && benefits && benefits.find(isTripAssistance);

export const getTripAssistanceBenefitId = benefits => {
  const tripAssistance = getTripAssistanceBenefit(benefits);
  return tripAssistance && tripAssistance.benefit_id;
};
