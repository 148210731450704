export const TEXT_WE_ARE_WORKING = {
  en_US:
    'We are working to offer you a more personalized and complete experience, for that we need more information of your Visa Card ',
  pt_BR:
    'Estamos trabalhando para oferecer uma experiência mais personalizada e completa, para isso precisamos de mais informações do seu cartão Visa ',
  es_ES:
    'Estamos trabajando para ofrecerte una experiencia más personalizada y completa, para eso necesitamos más información de tu tarjeta Visa ',
};

export const TEXT_PLEASE_ENTER_16_DIGITS = {
  en_US:
    'Please enter the 16 digits and the expiration date of your Visa card:',
  pt_BR:
    'Por favor ingresse os 16 dígitos e a data de expiração do seu cartão Visa:',
  es_ES:
    'Por favor ingresa los 16 digitos y la fecha de expiración de tu tarjeta Visa:',
};

export const TEXT_FORMAT_INVALID = {
  en_US: 'Invalid format, please check and try again.',
  pt_BR: 'Formato inválido, por favor verifique.',
  es_ES: 'El formato no es válido, por favor verifica.',
};

export const TEXT_NOT_VISA_PRODUCT = {
  en_US:
    'The informed card does not correspond to a Visa product. Please check and try again.',
  pt_BR:
    'Os dígitos informados não correspondem a um cartão Visa. Por favor verifique e tente novamente.',
  es_ES:
    'Los 16 digitos ingresados no corresponden a una tarjeta Visa. Por favor verifica e intenta nuevamente.',
};

export const TEXT_NOT_CORRESPOND_REGISTERED_CARD = {
  en_US:
    'The 16 digits entered do not correspond to the previously registered card. Please verify the digits.',
  pt_BR:
    'Os 16 dígitos informados não correspondem ao cartão que deseja atualizar. Por favor verifique novamente.',
  es_ES:
    'Os 16 digitos ingresados no corresponden a la tarjeta que tú quieres actualizar. Por favor verifica e intenta nuevamente.',
};

export const TEXT_UPDATE_BUTTON = {
  en_US: 'Conclude',
  pt_BR: 'Concluir',
  es_ES: 'Concluir',
};

export const TEXT_BIN_UPDATE_SUCCESS = {
  en_US: 'Your card has been successfully updated!',
  pt_BR: 'Seu cartão foi atualizado com sucesso!',
  es_ES: 'Tu tarjeta ha sido actualizada con éxito!',
};

export const LOADING = {
  en_US: 'Loading',
  pt_BR: 'Carregando',
  es_ES: 'Cargando',
};

export const UNEXPECTED_ERROR_OCCURRED = {
  en_US:
    'An error occurred while performing this operation. Please try again later.',
  pt_BR:
    'Ocorreu um erro ao efetuar esta operação. Por favor tente novamente mais tarde.',
  es_ES:
    'Se ha producido un error al realizar esta operación. Por favor, inténtalo de nuevo más tarde.',
};

export const ALREADY_USED_ERROR_OCCURRED = {
  en_US:
    'Please make sure you informed the 16-digit number correctly (this number is unique).',
  pt_BR:
    'Por favor, certifique-se de que informou o número de 16 dígitos corretamente (essa numeração é única).',
  es_ES:
    'Por favor, asegúrate de haber informado correctamente el número de 16 dígitos (este número es único).',
};

export const LENGTH_16_DIGITS = {
  en_US: 'The card numbers must be have 16 digits.',
  pt_BR: 'Os números do cartão devem ter 16 dígitos.',
  es_ES: 'Los números de la tarjeta deben tener 16 dígitos.',
};

export const TEXT_ENJOY_BENEFITS = {
  en_US: 'Your card is up to date, enjoy the benefits.',
  pt_BR:
    'O cartão informado já está atualizado. Disfrute dos seus benefícios! ',
  es_ES: 'Tu tarjeta ha sido actualizada con éxito!',
};

export const TEXT_BACK = {
  en_US: 'Back',
  pt_BR: 'Voltar ',
  es_ES: 'Volver',
};

export const TEXT_WARNING_VISA = {
  en_US:
    'Visa will not make any additional charges to your card, the information provided will be used for validation purposes.',
  pt_BR:
    'A Visa não efeturá nenhum cargo adicional ao seu cartão, a informação fornecida é apenas para validação. ',
  es_ES:
    'Visa no hará ningún cargo adicional a tu tarjeta, la información brindada será usada con fines de validación. ',
};

export const TEXT_INVALID_DATE = {
  en_US: 'Invalid date',
  pt_BR: 'Data inválida',
  es_ES: 'Fecha invalida',
};

export const TEXT_FIELD_REQUIRED = {
  en_US: 'Required field.',
  pt_BR: 'Campo obrigatório.',
  es_ES: 'Campo obligatorio.',
};

export const TEXT_ADD_YOUR_CARD_PAN = {
  en_US: 'More information of your Visa Card',
  es_ES: 'Más información de tu Tarjeta Visa',
  pt_BR: 'Mais informações do seu cartão Visa',
};

export const TEXT_BIN_NUMBER = {
  en_US: 'We are working to offer you a better and complete experience.',
  es_ES:
    'Estamos trabajando para ofrecerte una experiencia mejor y más completa.',
  pt_BR:
    'Estamos trabalhando para oferecer uma melhor e mais completa experiência.',
};

export const TEXT_YOUR_CARD = {
  en_US: 'Additional information',
  es_ES: 'Información adicional',
  pt_BR: 'Informação adicional',
};

export const TEXT_SUBTITLE = {
  en_US:
    'Visa will not make any additional charges to your card, the information provided will be used for validation purposes.',
  pt_BR:
    'A Visa não efeturá nenhum cargo adicional ao seu cartão, a informação fornecida é apenas para validação.',
  es_ES:
    'Visa no hará ningún cargo adicional a tu tarjeta, la información brindada será usada con fines de validación. ',
};

export const LABEL_10_DIGITS = {
  en_US: 'Credit card number (first 10 digits)',
  es_ES: 'Número de tarjeta de crédito (primeros 10 dígitos)',
  pt_BR: 'Número do cartão de crédito (primeiros 10 dígitos)',
};

export const LABEL_16_DIGITS = {
  en_US: 'Card number *',
  es_ES: 'Número de tarjeta *',
  pt_BR: 'Número do cartão  *',
};

export const LABEL_EXPIRATION_DATE = {
  en_US: 'Expiration date (MM/YY) *',
  es_ES: 'Fecha de caducidad (MM/AA) *',
  pt_BR: 'Data de validade (MM/AA) *',
};

export const TEXT_SUCCESS = {
  en_US:
    'Congratulations! Your card has been successfully saved. Get ready for a better and complete experience.',
  pt_BR:
    'Parabéns! Os dados do seu cartão foram salvos com sucesso. Prepare-se para uma experiência melhor e mais completa.',
  es_ES:
    '¡Felicidades! Los datos de tu tarjeta se han guardado correctamente. Prepárate para una experiencia mejor y más completa.',
};

export const OK = {
  en_US: 'OK',
  pt_BR: 'OK',
  es_ES: 'OK',
};

export const SAVING_YOUR_CARD = {
  en_US: 'Saving your card...',
  pt_BR: 'Salvando os dados do seu cartão...',
  es_ES: 'Guardando los datos de tu tarjeta ...',
};

export const FULL_NAME = {
  en_US: 'Full name (as displayed on Card) *',
  pt_BR: 'Nome completo (conforme exibido no cartão) *',
  es_ES: 'Nombre completo (como se muestra en la tarjeta) *',
};

export const EXPIRATION_DATE = {
  en_us: 'Expiration date (MM/YY) *',
  pt_BR: 'Data de validade (MM/AA) *',
  es_ES: 'Fecha de caducidad (MM/AA) *',
};
