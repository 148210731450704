import { IntlContext } from '../../../../intl';
import { useContext, useEffect, useState } from 'react';
import * as translations from './intl';
import styles from './OCTDetails.module.scss';

import OCTChooseCard from '../../../Claims/Generate/ClaimFillForm/Form/PaymentInformation/OCTFlow/OCTForm/OCTChooseCard/OCTChooseCard';
import TitleSectionProfile from '../../../../components/TitleSectionProfile/TitleSectionProfile';
import ButtonWithIcon from '../../../../components/ButtonWithIcon/ButtonWithIcon';
import AddIcon from '../../../../assets/icons/AddIcon';
import { StateContext } from '../../../../components/StateContextParent/StateContextParent';
import AddPaymentMethod from './AddPaymentMethod/AddPaymentMethod';
import ModalInformation from '../../../../components/ModalInformation/ModalInformation';
import { checkClaimsToReplacePaymentMethod } from '../../../Claims/Generate/ClaimFillForm/Form/PaymentInformation/OCTFlow/oct-const';
import { AppInsightTrackContext } from '../../../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';

const newPaymentMethodForm = (actions, intl, setPaymentID) => {
  actions.modal.showModal(
    intl.ADD_OCT_PAYMENT_METHOD,
    true,
    <AddPaymentMethod setPaymentID={setPaymentID} />,
    false,
    false,
    null,
    null,
    null,
    true,
    false,
    false
  );
};

export const changeMainCardAddConfirmation = (
  actions,
  intl,
  setPaymentID,
  canShowModalConfirmation = true
) => {
  if (!canShowModalConfirmation) {
    newPaymentMethodForm(actions, intl, setPaymentID);
    return;
  }

  actions.modal.showModal(
    '',
    true,
    <ModalInformation
      type="question"
      message={intl.TITLE_ADD_CONFIRM_CHANGE_MAIN_CARD}
      subtitle={intl.SUBTITLE_ADD_CONFIRM_CHANGE_MAIN_CARD}
      clickBtn={() => newPaymentMethodForm(actions, intl, setPaymentID)}
      textBtn={intl.BUTTON_ADD_CONFIRM_CHANGE_MAIN_CARD}
      textBtnDeny={intl.BUTTON_DENY_CONFIRM_CHANGE_MAIN_CARD}
      clickBtnDeny={() => {
        actions.modal.closeModal();
      }}
    />
  );
};

const OCTDetails = () => {
  const { translate, idiomForApi } = useContext(IntlContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const { actions, utils } = useContext(StateContext);
  const [canChangePaymentMethod, setCanChangePaymentMethod] = useState(false);
  const intl = translate(translations);
  const [addText, setAddText] = useState('');
  const [canShowModalConfirmation, setCanShowModalConfirmation] =
    useState(true);
  const [paymentID, setPaymentID] = useState<string>();
  const binOrPan = utils.getBin(true);
  const idiomAPI = idiomForApi();

  const titleByWidthScreen = () => {
    if (window.screen.width > 768) {
      setAddText(intl.ADD_OCT_PAYMENT_METHOD);
    } else {
      setAddText(intl.ADD_SHORT);
    }
  };

  useEffect(() => {
    titleByWidthScreen();
    window.addEventListener('resize', titleByWidthScreen);
    return () => {
      window.removeEventListener('resize', titleByWidthScreen);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    checkClaimsToReplacePaymentMethod(
      utils.getCn(),
      binOrPan,
      idiomAPI,
      setCanChangePaymentMethod,
      trackEventUserAction
    );
  }, []);

  return (
    <div id="oct">
      <div className={styles.titleArea}>
        <TitleSectionProfile title={intl.TITLE_OCT_DETAILS} />
        {canChangePaymentMethod && (
          <div>
            <ButtonWithIcon
              id="addPaymentMethod"
              type="blueWithoutBorder"
              onClick={() =>
                changeMainCardAddConfirmation(
                  actions,
                  intl,
                  setPaymentID,
                  canShowModalConfirmation
                )
              }
              value={addText}
              isSVGComponent={true}
              img={<AddIcon />}
            />
          </div>
        )}
      </div>
      <OCTChooseCard
        setPaymentID={setPaymentID}
        paymentID={paymentID}
        isProfile={true}
        canChangePaymentMethod={canChangePaymentMethod}
        setCanShowModalConfirmation={setCanShowModalConfirmation}
      />
    </div>
  );
};

export default OCTDetails;
