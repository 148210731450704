import * as Yup from 'yup';

export const emailDataSchema = (intl, cardHolderEmail) => {
  return Yup.lazy(values => {
    const schema = {
      email: Yup.string()
        .email(intl.VALIDATE_EMAIL_VALID)
        .required(intl.TEXT_FIELD_REQUIRED)
        .test(
          'is-same-email',
          intl.EMAIL_INVALID_SAME_ACCOUNT,
          email => !(email === cardHolderEmail)
        ),
    };
    return Yup.object().shape(schema);
  });
};
