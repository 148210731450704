import { useTheme } from '../../themes/ThemeContextParent/ThemeContextParent';

const LoaderIcon = () => {
  const { getCurrentThemeColors } = useTheme();
  const { primary } = getCurrentThemeColors();

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Adjust Fill"
        d="M12 2C13.8442 2 15.6525 2.50999 17.225 3.4736C18.7974 4.4372 20.0728 5.81688 20.9101 7.46009C21.7473 9.10331 22.1139 10.9461 21.9692 12.7846C21.8245 14.6231 21.1742 16.3858 20.0902 17.8779C19.0062 19.3699 17.5307 20.533 15.8268 21.2388C14.123 21.9445 12.2572 22.1654 10.4357 21.8769C8.61414 21.5884 6.90788 20.8018 5.50552 19.6041C4.10316 18.4063 3.05933 16.8441 2.48944 15.0902"
        stroke={primary}
        strokeWidth="4"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default LoaderIcon;
