import { ALWAYS_ADD_PAN } from '../../utils/environments_variables';

export const TEXT_ADD_YOUR_CARD_BIN = {
  en_US: 'Add a CTA card',
  es_ES: 'Agregar una tarjeta CTA',
  pt_BR: 'Adicionar um cartão CTA',
};

export const TEXT_10_DIGITS = {
  en_US: 'Type in the first 10 digits on the front of your card.',
  es_ES: 'Escribe los 10 primeros dígitos de tu tarjeta.',
  pt_BR:
    'Digite os primeiros 10 dígitos que se encontram na frente do seu cartão.',
};

export const TEXT_ADD_YOUR_CARD_PAN = {
  en_US: 'Add your card',
  es_ES: 'Agrega tu tarjeta',
  pt_BR: 'Adicione seu cartão',
};

export const TEXT_16_DIGITS = {
  en_US: 'Type in the 16 digits on the front of your card.',
  es_ES: 'Escribe los 16 digitos de tu tarjeta Visa.',
  pt_BR: 'Digite os 16 dígitos que se encontram na frente do seu cartão.',
};

export const TEXT_DIGITS = TEXT_16_DIGITS;

export const TEXT_ADD_YOUR_CARD = TEXT_ADD_YOUR_CARD_PAN;

export const TEXT_REQUIRED_FORM = {
  en_US: 'This field is required.',
  pt_BR: 'Este campo é obrigatório.',
  es_ES: 'Este campo es requerido.',
};

export const TEXT_LABEL_ADD_CARD = {
  en_US: 'Card number (16 digits)',
  pt_BR: 'Número do cartão (16 dígitos)',
  es_ES: 'Número de la tarjeta (16 dígitos)',
};

export const LABEL_EXPIRATION_DATE = {
  en_US: 'Expiration date (MM/YY) *',
  es_ES: 'Fecha de caducidad (MM/AA) *',
  pt_BR: 'Data de validade (MM/AA) *',
};

export const TEXT_INVALID_DATE = {
  en_US: 'Invalid date',
  pt_BR: 'Data inválida',
  es_ES: 'Fecha invalida',
};

export const NOT_VISA_PRODUCT = {
  en_US:
    'The informed card does not correspond to a Visa product. Please check and try again.',
  pt_BR:
    'Os dígitos informados não correspondem a um cartão Visa. Por favor verifique e tente novamente.',
  es_ES:
    'Los 16 digitos ingresados no corresponden a una tarjeta Visa. Por favor verifica e intenta nuevamente.',
};

export const TEXT_FORMAT_INVALID = {
  en_US: 'Invalid format, please check and try again.',
  pt_BR: 'Formato inválido, por favor verifique.',
  es_ES: 'El formato no es válido, por favor verifica.',
};

export const TEXT_NOT_VISA_PRODUCT = {
  en_US:
    'The informed card does not correspond to a Visa product. Please check and try again.',
  pt_BR:
    'Os dígitos informados não correspondem a um cartão Visa. Por favor verifique e tente novamente.',
  es_ES:
    'Los 16 digitos ingresados no corresponden a una tarjeta Visa. Por favor verifica e intenta nuevamente.',
};

export const ALREADY_USED_ERROR_OCCURRED = {
  en_US:
    'Please make sure you informed the 16-digit number correctly (this number is unique).',
  pt_BR:
    'Por favor, certifique-se de que informou o número de 16 dígitos corretamente (essa numeração é única).',
  es_ES:
    'Por favor, asegúrate de haber informado correctamente el número de 16 dígitos (este número es único).',
};

export const PAN_ERROR_OCCURRED = {
  en_US:
    'An error occurred while validating your card number. Please try again.',
  pt_BR:
    'Ocorreu um erro ao validar seu número de cartão. Por favor tente novamente.',
  es_ES:
    'Se ha producido un error al validar tu número de tarjeta. Por favor, inténtalo de nuevo.',
};

export const TEXT_ERROR_MESSAGE = {
  en_US: 'Oops. Unfortunately your card could not be added at this time.',
  pt_BR:
    'Opa. Infelizmente, não foi possível adicionar seu cartão neste momento.',
  es_ES:
    '¡Ups! Desafortunadamente, no pudimos agregar tu tarjeta en este momento.',
};

export const TEXT_ERROR_TRY_AGAIN = {
  en_US: 'Try again',
  pt_BR: 'Tente novamente',
  es_ES: 'Inténtalo de nuevo',
};

export const SUBTITLE_ERROR_MESSAGE = {
  en_US: 'The number entered is not recognized by the Benefits Portal.',
  pt_BR: 'A numeração informada não é reconhecida pelo Portal de Benefícios.',
  es_ES: 'El portal de beneficios no reconoce el número ingresado.',
};

export const TEXT_ERROR_NEED_HELP = {
  en_US: 'Need help?',
  pt_BR: 'Precisa de ajuda?',
  es_ES: '¿Necesita ayuda?',
};

export const TEXT_SUCCESS = {
  en_US: 'Congratulations! Your new card was added successfully.',
  pt_BR: 'Parabéns! Seu novo cartão foi adicionado com sucesso.',
  es_ES: '¡Felicidades! Tu nueva tarjeta fue agregada con éxito.',
};

export const OK = {
  en_US: 'OK',
  pt_BR: 'OK',
  es_ES: 'OK',
};

export const TEXT_MAIN_CARD = {
  en_US: 'I want this card to be the main one',
  es_ES: 'Quiero que esta sea la tarjeta principal',
  pt_BR: 'Quero esse cartão como sendo o principal',
};

export const TEXT_CARD_ALREADY_REGISTER = {
  en_US: 'This card number is already registered in your account.',
  pt_BR: 'Este número de cartão já está registrado em sua conta.',
  es_ES: 'Este número de tarjeta ya está registrado en tu cuenta.',
};

export const LOADING_NEW_CARD = {
  en_US: 'Adding your card...',
  pt_BR: 'Adicionando seu cartão...',
  es_ES: 'Agregando tu tarjeta...',
};

export const BTN_ADD_CARD = {
  en_US: 'Add card',
  pt_BR: 'Adicionar cartão',
  es_ES: 'Agregar tarjeta',
};

export const TEXT_WARNING_VISA = {
  en_US:
    'Visa will not make any additional charges to your card, the information provided will be used for validation purposes. Only Visa cards issued in Latin America and the Caribbean are eligible.',
  pt_BR:
    'A Visa não efetuará nenhuma cobrança adicional ao seu cartão, a informação fornecida é apenas para validação. Apenas os cartões Visa emitidos na América Latina e no Caribe são elegíveis.',
  es_ES:
    'Visa no hará ningún cargo adicional a tu tarjeta, la información brindada será usada con fines de validación. Solo son elegibles las tarjetas Visa emitidas en América Latina y el Caribe.',
};
