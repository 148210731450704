import React, { useState, useEffect, useContext } from 'react';

import Item from './Item/Item';
import * as translations from './intl';
import { IntlContext } from '../../intl';
import { certificatesServices, claimsServices } from '../../services';
import styles from './PrivateBenefitsBanner.module.scss';
import Carousel from '../../components/Carousel/Carousel';
import { StateContext } from '../../components/StateContextParent/StateContextParent';
import Loading from '../Loading/Loading';
import {
  travel_insurance,
  certificate_extended_warranty,
} from '../../utils/certificates_name';
import travelImage from '../../assets/images/topBenefits/Visa_Airport.jpg';
import warrantyImage from '../../assets/images/topBenefits/Visa_Shutterstock.jpg';
import { productsAndBenefitsServices } from '../../services/index';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';

import { enableUpselling } from '../../utils/verifyAccess';
import { UPSELLING, CROSSELLING } from '../../utils/B2C/constants';
import { enableCrosselling } from '../../utils/verifyAccessCrosselling';
import B2B2CImage from '../../assets/images/B2B2C/Benefit_B2B2C.png';
import { emergency_medical_assistance } from '../../utils/claims_name';
import { pt_BR } from '../../intl/idioms';
import { ENABLE_TRIP_ASSISTANCE } from '../../utils/environments_variables';
import { isTripAssistance, trip_assistance } from '../../utils/benefits';
import { Link } from 'react-router-dom';
import ArrowRightIcon from '../../assets/icons/ArrowRight';

const settings = {
  className: styles.sliderBenefits,
  dots: false,
  infinite: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  centerMode: false,
  arrows: false,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2.1,
        slidesToScroll: 1,
        dots: false,
        centerMode: false,
      },
    },
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1,
        dots: false,
        centerMode: false,
      },
    },
    {
      breakpoint: 630,
      settings: {
        slidesToShow: 1.2,
        slidesToScroll: 1,
        dots: false,
        centerMode: false,
      },
    },
    {
      breakpoint: 375,
      settings: {
        slidesToShow: 1.1,
        slidesToScroll: 1,
        dots: false,
        centerMode: false,
      },
    },
  ],
};

const benefitsOrder = [
  CROSSELLING,
  UPSELLING,
  'Disney',
  'Rappi',
  'Amazon_Prime',
  'Nurse_Advice_Line',
  trip_assistance,
  'travel_insurance',
  'certificate_extended_warranty',
  'EMC_Emergency_Medical_Assistance',
  'Auto_Rental_Insurance',
  'Price_Protection',
  'Purchase_Protection',
  'Baggage_Delay',
  'Baggage_Loss',
  'Protected_Vacations',
  'Trip_Cancellation',
  'Trip_Delay',
  'Extended_Warranty',
  'Missed_Connection',
  'Travel_Accident_Insurance',
];

const getCertificateImage = item => {
  switch (item.code) {
    case 'travel_insurance':
      return travelImage;
    case 'certificate_extended_warranty':
      return warrantyImage;
    default:
      return undefined;
  }
};

const getClaimName = (benefit, benefitsData) => {
  const find = benefitsData.find(
    benefitItem => benefitItem.external_reference === benefit.code
  );

  return find ? find.name : undefined;
};

const getClaimImage = (item, listBenefits) => {
  const benefit = listBenefits.find(
    benefit => benefit.external_reference === item.code
  );

  if (benefit && benefit.images) {
    const getBenefitWithPicture = benefit.images.find(
      image => image.type === 'PICTURE'
    );

    const getBenefitWithPictureMobile = benefit.images.find(
      image => image.type === 'PICTURE_MOBILE'
    );

    if (getBenefitWithPictureMobile) {
      return (
        getBenefitWithPictureMobile && getBenefitWithPictureMobile.content_url
      );
    } else {
      return getBenefitWithPicture && getBenefitWithPicture.content_url;
    }
  }
  return undefined;
};

const fomartObjectCotizar = (title, type) => {
  return {
    code: '',
    name: title,
    type: type,
  };
};

const formatCotizar = (productName, country, intl, email) => {
  if (enableCrosselling(productName, country, email)) {
    return fomartObjectCotizar(intl.TITLE_BENEFIT_COTIZAR, CROSSELLING);
  }
  if (enableUpselling(productName, country, email)) {
    return fomartObjectCotizar(intl.TITLE_BENEFIT_COTIZAR, UPSELLING);
  }

  return false;
};

const getSpecialBenefits = (benefits, handleFormatData) => {
  const filterBenefits = benefits.filter(
    benefit => benefit.categories === 'special_benefits'
  );
  return filterBenefits.map(benfit => handleFormatData(benfit));
};

const getTripAssistance = (benefits, handleFormatData) => {
  const benefitFind = benefits.find(isTripAssistance);
  if (!benefitFind) {
    return null;
  }
  return handleFormatData(benefitFind);
};

const getNurseAdviceLine = (benefits, handleFormatData) => {
  const benefitFind = benefits.find(
    benefit => benefit.external_reference === 'Nurse_Advice_Line'
  );
  if (!benefitFind) {
    return null;
  }
  return handleFormatData(benefitFind);
};

const PrivateBenefitsBanner = ({ isSimpleBanner }) => {
  const { state, utils } = useContext(StateContext);
  const { cards } = state;
  const [benefits, setBenefits] = useState([]);
  const [loading, setLoading] = useState(true);
  const selectedCard = utils.getSelectedCard();
  const binOrPan = utils.getBin(true);
  const cardSelected = utils.getSelectedCard();
  const { translate, idiomForApi, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const isBrazil = idiom === pt_BR;

  function mapOrder(benefitsList, benefitOrder, benefitsData) {
    const benefitsFiltered = [];

    benefitOrder.forEach(element => {
      let benefitFind = benefitsList.find(
        benefit =>
          benefit.code?.toLowerCase() === element.toLowerCase() ||
          benefit.type?.toLowerCase() === element.toLowerCase()
      );

      if (benefitFind !== undefined) {
        if (benefitFind.type === 'certificates') {
          benefitFind.image = getCertificateImage(benefitFind);
        } else if (
          benefitFind.type === UPSELLING ||
          benefitFind.type === CROSSELLING
        ) {
          benefitFind.image = B2B2CImage;
        } else {
          benefitFind.image = getClaimImage(benefitFind, benefitsData);
        }

        if (benefitFind.type === 'claims') {
          benefitFind.name = getClaimName(benefitFind, benefitsData);

          if (!benefitFind.image || !benefitFind.name) {
            return;
          }
        }

        benefitFind = { ...benefitFind };

        if (benefitFind.code === 'travel_insurance' && !isBrazil) {
          const iemsExists = benefitsList.find(
            benefit => benefit.code === emergency_medical_assistance
          );
          if (iemsExists) {
            benefitsFiltered.push(benefitFind);
          }
        } else {
          benefitsFiltered.push(benefitFind);
        }
      }
    });
    return benefitsFiltered;
  }

  const handleFormatData = (data, isClaim) => {
    return {
      code: isClaim ? data.external_reference : data.code,
      name: data.name,
      type: isClaim ? 'claims' : 'certificates',
    };
  };

  const handleFormatBenefits = data => {
    const isSpecial = data.categories === 'special_benefits';

    return {
      code: data.external_reference,
      benefit_id: data.benefit_id,
      name: data.name,
      type: isSpecial ? 'special_benefit' : 'benefit',
    };
  };

  const handleMakeBenefits = (certificatesData, claimsData, benefitsData) => {
    const checkIsCotizar = formatCotizar(
      cardSelected.name,
      cardSelected.country,
      intl,
      utils.getEmail()
    );
    const benefitsList = [];
    const specialBenefits = getSpecialBenefits(
      benefitsData,
      handleFormatBenefits
    );

    if (specialBenefits.length > 0) {
      benefitsList.push(...specialBenefits);
    }

    if (checkIsCotizar) {
      benefitsList.push(checkIsCotizar);
    }

    if (ENABLE_TRIP_ASSISTANCE) {
      const tripAssistanceBenefit = getTripAssistance(
        benefitsData,
        handleFormatBenefits
      );
      if (tripAssistanceBenefit) {
        benefitsList.push(tripAssistanceBenefit);
      }
    }

    const benefitNAL = getNurseAdviceLine(benefitsData, handleFormatBenefits);
    if (benefitNAL) {
      benefitsList.push(benefitNAL);
    }

    const certificatesFiltered = certificatesData.filter(
      item =>
        item.code.toLowerCase() === travel_insurance ||
        item.code.toLowerCase() === certificate_extended_warranty
    );

    certificatesFiltered.map(item => {
      benefitsList.push(handleFormatData(item));
    });

    claimsData.map(item => {
      benefitsList.push(handleFormatData(item, true));
    });

    const dataOrdered = mapOrder(benefitsList, benefitsOrder, benefitsData);
    if (dataOrdered.length > 3) {
      setBenefits(dataOrdered.slice(0, 3));
    } else if (dataOrdered.length > 0) {
      setBenefits(dataOrdered.slice(0, dataOrdered.length));
    }
  };

  const loadData = async binPan => {
    setLoading(true);
    try {
      const responseCertificates =
        await certificatesServices.listCertificateTypesByBinNumber(
          binPan,
          idiomForApi()
        );
      const responseClaims = await claimsServices.getClaimTypes(
        binPan,
        idiomForApi()
      );
      const benefits = await productsAndBenefitsServices.getBenefitsByProduct(
        binOrPan,
        idiomForApi(),
        true,
        false
      );

      handleMakeBenefits(
        responseCertificates.data,
        responseClaims.data,
        benefits.data
      );
    } catch (error) {}
    setLoading(false);
  };

  useEffect(() => {
    if (cards.data.length > 0) {
      loadData(binOrPan);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCard]);

  return (
    <div className={styles.container}>
      {!isSimpleBanner && (
        <h1 className={`${styles.title} ${styles[theme]}`}>
          {intl.TITLE(selectedCard.name)}
        </h1>
      )}
      <div className={isSimpleBanner ? styles.bannerSubContainner : null}>
        <span
          className={`${styles[theme]} ${
            isSimpleBanner ? styles.subtitleSimpleBanner : styles.subtitle
          }`}
        >
          {isSimpleBanner ? intl.TITLE_ENJOY_BENEFITS : intl.SUBTITLE}
        </span>
        {isSimpleBanner ? (
          <div className={`${styles.titleContainer}`}>
            <Link to="/benefits" className={styles[theme]}>
              {intl.TEXT_VIEW_BENEFITS}
              <span className={styles.icon}>
                <ArrowRightIcon width={20} height={20} alt="" />
              </span>
            </Link>
          </div>
        ) : null}
      </div>

      <div
        className={`${styles[theme]} ${
          isSimpleBanner
            ? styles.carouselContentSimpleBanner
            : styles.carouselContent
        }`}
      >
        {loading ? (
          <div className={styles.loadingContainer}>
            <Loading />
          </div>
        ) : (
          <Carousel settings={settings} className={styles.carousel}>
            {benefits.map(item => (
              <Item
                key={item.code}
                data={item}
                isSimpleBanner={isSimpleBanner}
              />
            ))}
          </Carousel>
        )}
      </div>
    </div>
  );
};

export default PrivateBenefitsBanner;
