import { SVGProps } from 'react';
import { useTheme } from '../../themes/ThemeContextParent/ThemeContextParent';

const ProfileSuccessIcon = (props: SVGProps<SVGSVGElement>) => {
  const { getCurrentThemeColors } = useTheme();
  const { primary } = getCurrentThemeColors();

  return (
    <svg
      width={65}
      height={64}
      viewBox="0 0 65 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={1.78906}
        y={1}
        width={62}
        height={62}
        rx={31}
        stroke={primary}
        strokeWidth={2}
      />
      <path
        d="M49.0879 32.1766L46.9557 28.4453V26.5833C46.9557 19.9207 41.535 14.5 34.8724 14.5C28.2098 14.5 22.7891 19.9207 22.7891 26.5833C22.7891 29.2872 23.8425 31.0491 24.7719 32.6038C24.8409 32.7191 24.9077 32.8318 24.9744 32.9444C25.7446 33.0591 26.4874 33.2589 27.183 33.5533C26.8987 32.9218 26.55 32.3295 26.2026 31.7485C25.3436 30.3122 24.4557 28.827 24.4557 26.5833C24.4557 20.8395 29.1286 16.1667 34.8724 16.1667C40.6162 16.1667 45.2891 20.8395 45.2891 26.5833V28.888L47.641 33.0034C47.7712 33.2321 47.7866 33.5015 47.6833 33.744C47.5799 33.9865 47.3748 34.1615 47.1193 34.2257L45.4355 34.6457L43.9634 40.5351C43.8698 40.9071 43.5378 41.1667 43.1545 41.1667H39.9709C39.3345 41.1667 38.7632 41.5199 38.48 42.0879L37.1618 44.7243L38.6527 45.4697L39.9709 42.8333H43.1545C44.3035 42.8333 45.3013 42.0545 45.5796 40.9396L46.8092 36.021L47.523 35.842C48.2896 35.6507 48.9064 35.125 49.2165 34.3983C49.5265 33.6724 49.4793 32.8626 49.0879 32.1766Z"
        fill={primary}
      />
      <path
        d="M31.9355 45.8384L39.083 49.4121L39.8285 47.9212L32.4968 44.2554C32.357 44.8046 32.1696 45.3336 31.9355 45.8384Z"
        fill={primary}
      />
      <path
        d="M29.3695 41.0381C29.4219 41.3518 29.4558 41.6717 29.4558 42C29.4558 45.2161 26.839 47.8333 23.6224 47.8333C20.4059 47.8333 17.7891 45.2161 17.7891 42C17.7891 38.7838 20.4059 36.1667 23.6224 36.1667C24.6444 36.1667 25.6048 36.4326 26.441 36.8963L27.6523 35.685C26.4868 34.9386 25.1062 34.5 23.6224 34.5C19.4871 34.5 16.1224 37.8643 16.1224 42C16.1224 46.1357 19.4871 49.5 23.6224 49.5C27.7578 49.5 31.1224 46.1357 31.1224 42C31.1224 41.1836 30.9869 40.3993 30.7446 39.663L29.3695 41.0381Z"
        fill={primary}
      />
      <path
        d="M23.2058 42.4883L21.295 40.5775L20.1166 41.7559L23.2058 44.8451L31.295 36.7559L30.1166 35.5775L23.2058 42.4883Z"
        fill={primary}
      />
    </svg>
  );
};
export default ProfileSuccessIcon;
