export { CREATE_TRIP_ASSISTANCE as BUTTON_ACTIVATE_TRIP_ASSISTANCE } from '../../../intl/travels';

export const TEXT_CH_CHANGE = {
  en_US: 'Your personal details has been changed. Do you want to save it?',
  pt_BR: 'Suas informações pessoais foram modificadas. Deseja salvá-las?',
  es_ES:
    'Tu información personal ha sido modificada. ¿Deseas guardar los cambios?',
};

export const TEXT_DEPENDENT_CHANGE = {
  en_US: 'Beneficiary information has been changed. Do you want to save them?',
  pt_BR: 'A informação do beneficiário foi alterada. Você quer salvá-los?',
  es_ES: 'La información beneficiario ha sido modificada. ¿Quieres salvarlos?',
};

export const TEXT_ERROR = {
  en_US: 'An error occurred while performing this operation.',
  pt_BR: 'Ocorreu um erro ao efetuar esta operação.',
  es_ES: 'Se ha producido un error al realizar esta operación.',
};

export const TEXT_ERROR_CERTIFICATE = {
  en_US: 'Unfortunately it was not possible to generate your certificate.',
  pt_BR: 'Infelizmente não foi possível gerar seu bilhete.',
  es_ES: 'Desafortunadamente, no fue posible generar tu certificado.',
};

export const ERROR_AIG_NOT_AVAILABLE = {
  en_US: 'Our certificate generation system is under maintenance.',
  pt_BR: 'Nosso sistema de geração de bilhetes está em manutenção.',
  es_ES: 'Nuestro sistema de generación de certificados está en mantenimiento.',
};

export const ERROR_SORRY = {
  en_US:
    'We apologize for the inconvenience and recommend you to try again in a few minutes.',
  pt_BR:
    'Pedimos desculpas pela inconveniência e recomendamos que tente novamente em alguns minutos.',
  es_ES:
    'Lamentamos las molestias y te recomendamos que vuelva a intentarlo en unos minutos.',
};

export const TEXT_ERROR_DUPLICATED_CARDHOLDER = {
  pt_BR:
    'O titular do cartão já possui um bilhete de seguro viagem válido emitido anteriormente.',
};

export const SUBTITLE_ERROR_DUPLICATED_CARDHOLDER = {
  pt_BR: 'Um bilhete emitido por você fica válido durante um ano.',
};

export const TEXT_CREATE_CERTIFICATE = {
  pt_BR: 'Criar bilhete',
};

export const TEXT_SEE_MY_CERTIFICATES = {
  pt_BR: 'Ver meus bilhetes',
};

export const TEXT_ERROR_DUPLICATED_DEPENDENTS = {
  pt_BR:
    'Um dos viajantes adicionais já possui um bilhete de seguro viagem válido emitido anteriormente.',
};

export const SUBTITLE_ERROR_DUPLICATED_DEPENDENTS = {
  pt_BR:
    'Verifique nos Bilhetes Gerados quais os documentos (CPF, Passaporte ou Identidade Nacional) que já possuem bilhetes associados.',
};

export const TEXT_ERROR_CEP = {
  pt_BR: 'CEP não encontrado.',
};

export const TEXT_ERROR_CEP_DEPENDENT = {
  pt_BR: 'O CEP informado para o dependente não foi encontrado.',
};

export const TEXT_SUCCESS = {
  en_US: 'Congratulations! Your certificate was successfully generated.',
  pt_BR: 'Parabéns! Seu certificado foi gerado com sucesso.',
  es_ES: '¡Felicidades! Tu certificado fue generado con éxito.',
};

export const TEXT_SUCCESS_SUBTITLE = {
  en_US:
    '<strong>If you want the e-card of the certificate in your mobile e-wallet, YOU MUST SAVE IT.</strong> Please check out your email for more details. Thank you!',
  pt_BR:
    '<strong>Se você quiser um bilhete (e-card) do seu Bilhete Seguro Viagem em sua carteira digital do celular,  VOCÊ DEVE SALVÁ-LO.</strong> Por favor, verifique seu e-mail para mais detalhes. Obrigado!',
  es_ES:
    '<strong>Si deseas el pase (e-card) del certificado en tu billetera eletrónica móvil, DEBES GUARDARLO.</strong> Por favor revisa tu correo electrónico para más detalles. ¡Gracias!',
};
export const TEXT_SUCCESS_SUBTITLE_TRIP_ASSISTANCE = {
  en_US:
    'For a better experience, now you can activate your Travel Kit to track your flight in real time. Based on your flight details, we can support you to open claims enjoying entirely your trip benefits.',
  pt_BR:
    'Para uma melhor experiência, agora você pode ativar seu Travel Kit para acompanhar seu vôo em tempo real. Com base nos detalhes do vôo, podemos ajudá-lo a abrir sinistros aproveitando integralmente seus benefícios de viagem.',
  es_ES:
    'Para una mejor experiencia, ahora puedes activar tu Visa Travel Kit para rastrear tu vuelo en tiempo real. Según los detalles de tu vuelo, podemos ayudarlo a abrir reclamos disfrutando plenamente de tus beneficios de viaje.',
};

export const BUTTON_DO_IT_LATER = {
  en_US: 'Do it later',
  pt_BR: 'Fazer isso depois',
  es_ES: 'Hazlo después',
};

export const PRODUCT_INFORMATION = {
  en_US: 'Product information',
  pt_BR: 'Informações do produto',
  es_ES: 'Informaciones del producto',
};

export const CUSTOMER_INFORMATION = {
  en_US: 'Customer information',
  pt_BR: 'Informação do cliente',
  es_ES: 'Información del cliente',
};

export const BTN_BACK = {
  en_US: 'Back',
  pt_BR: 'Voltar',
  es_ES: 'Volver',
};

export const BTN_CONTINUE = {
  en_US: 'Continue',
  pt_BR: 'Continuar',
  es_ES: 'Continue',
};

export const TITLE_TRIP_INFORMATION = {
  en_US: 'Trip information',
  pt_BR: 'Informações de viagem',
  es_ES: 'Información de viaje',
};

export const TITLE_TRAVELLER_INFORMATION = {
  en_US: 'Traveller information',
  pt_BR: 'Portador do cartão',
  es_ES: 'Informaciones del viajero',
};

export const TITLE_ADITIONAL_TRAVELERS = {
  en_US: 'Additional travellers',
  pt_BR: 'Viajantes adicionais',
  es_ES: 'Viajeros adicionales',
};

export const NUMBER_DEPENDENTS_EXCEEDED = {
  en_US:
    'The number of beneficiaries associated with the cardholder has exceeded the limit.',
  pt_BR: 'O número de beneficiários associados ao titular excedeu o limite.',
  es_ES:
    'El número de beneficiarios asociados con el titular ha excedido el límite.',
};

export const ERROR_CUSTOMER_DUPLICATED = {
  pt_BR: 'O CPF informado já é utilizado por outro titular.',
};

export const ERROR_TIME_OUT = {
  en_US: 'Performing this operation, a timeout happened. Please try again.',
  pt_BR:
    'Ao realizar essa operação, o tempo de processamento esgotou. Por favor, tente novamente.',
  es_ES:
    'Al realizar esta operación, ha transcurrido el tiempo de procesamiento. Por favor, intenta nuevamente.',
};

export const ERROR_CUSTOMER_UPDATE = {
  en_US:
    'Unfortunately it was not possible to update your personal information.',
  pt_BR: 'Infelizmente não foi possível atualizar suas informações pessoais.',
  es_ES: 'Lamentablemente, no fue posible actualizar tu información personal.',
};

export const UNEXPECTED_ERROR = {
  en_US: 'An unexpected error has occurred.',
  pt_BR: 'Ocorreu um erro inesperado.',
  es_ES: 'Ha ocurrido un error inesperado.',
};

export const TRY_AGAIN = {
  en_US: 'Try again',
  es_ES: 'Intentar nuevamente',
  pt_BR: 'Tentar novamente',
};

export const TRY_LATER = {
  en_US: 'Try later',
  pt_BR: 'Tentar depois',
  es_ES: 'Intenta más tarde',
};

export const MESSAGE_CONFIRM_CERTIFICATE = {
  pt_BR:
    'Por gentileza, certifique-se de que suas informações pessoais e as dos seus beneficiários estão corretas.',
};

export const SUBTITLE_CONFIRM_CERTIFICATE = {
  pt_BR:
    'Esteja ciente de que você só poderá gerar um novo Bilhete de Seguro após 24 horas. Deseja gerar o Bilhete agora?',
};

export const TEXT_CH_CHANGE_SUCCESS = {
  en_US: 'Your personal details has been updated successfully.',
  pt_BR: 'Suas informações pessoais foram atualizadas com sucesso.',
  es_ES: 'Tu información personal se ha actualizado con éxito.',
};

export const TEXT_YES_GENERATE_CERTIFICATE = {
  en_US: 'Yes, generate certificate',
  pt_BR: 'Sim, gerar bilhete',
  es_ES: 'Sí, generar certificado ',
};

export const TEXT_YES_I_DO = {
  en_US: 'Yes, I do',
  pt_BR: 'Sim, eu quero',
  es_ES: 'Sí, quiero',
};

export const TEXT_NO = {
  en_US: 'No',
  pt_BR: 'Não',
  es_ES: 'No',
};

export const TEXT_NO_EDITING_CERTIFICATE = {
  en_US: 'No',
  pt_BR: 'Não, editar bilhete',
  es_ES: 'No',
};

export const SAVING_PERSONAL_DETAILS = {
  en_US: 'Saving personal details...',
  pt_BR: 'Salvando informações pessoais...',
  es_ES: 'Guardando información personal...',
};

export const GENERATING_YOUR_CERTIFICATE = {
  en_US: 'Generating your certificate...',
  pt_BR: 'Gerando seu bilhete...',
  es_ES: 'Generando tu certificado...',
};

export const TEXT_SUBMIT_CERTIFICATE = {
  en_US: 'Generate certificate',
  pt_BR: 'Criar bilhete',
  es_ES: 'Generar certificado',
};

export const TEXT_ERROR_CPF_DEPENDENT_CERTIFICATE = {
  pt_BR:
    'Existe pelo menos um viajante adicional cujo CPF não está preenchido corretamente.',
};

export const TEXT_SUBTITLE_ERROR_CPF_DEPENDENT_CERTIFICATE = {
  pt_BR:
    'Por favor, edite cada um dos viajantes adicionais certificando-se de que todas as informações obrigatórias estão preenchidas.',
};

export const NOT_POSSIBLE_TO_CREATE_CERTIFICATE = {
  en_US: 'It was not possible to create your certificate.',
  pt_BR: 'Não foi possível criar seu certificado.',
  es_ES: 'No fue posible crear tu certificado.',
};

export const NOT_POSSIBLE_TO_CREATE_CERTIFICATE_COVERED_CERTIFICATE_TYPE = {
  en_US:
    'You are trying to access a certificate type that does not have coverage for your currently selected card.',
  pt_BR:
    'Você está tentando acessar um tipo de certificado que não possui cobertura para seu cartão selecionado no momento.',
  es_ES:
    'Estás intentando acceder a un tipo de certificado que no tiene cobertura para tu tarjeta actualmente seleccionada.',
};

export const NOT_POSSIBLE_TO_CREATE_CERTIFICATE_MUST_CPF_FOR_BRAZIL = {
  en_US: '',
  pt_BR:
    'É obrigatório informar um CPF válido do titular do cartão para gerar um bilhete de seguro viagem.',
  es_ES: '',
};

export const NOT_POSSIBLE_TO_CREATE_CERTIFICATE_MUST_CPF_FOR_BRAZIL_DEPENDENT =
  {
    en_US: '',
    pt_BR:
      'É obrigatório informar um CPF válido para todos os viajantes adicionais para gerar um bilhete de seguro viagem.',
    es_ES: '',
  };

export const CUSTOMER_IS_NOT_COVERED_CERTIFICATE_TYPE = {
  en_US: 'The customer is not covered for this certificate type.',
  pt_BR: 'O cliente não está coberto por este tipo de certificado.',
  es_ES: 'El cliente no está cubierto por este tipo de certificado.',
};

export const BIN_FROM_BRASIL_NEED_CPF = {
  en_US:
    'For cards issued in Brazil, the cardholder is required to provide a CPF.',
  pt_BR:
    'Para cartões emitidos no Brasil é requerido que o portador do cartão informe um CPF.',
  es_ES:
    'Para tarjetas emitidas en Brasil, el titular de la tarjeta debe proporcionar un CPF.',
};
export const ERROR_CPF_CAN_BE_USED_ONLY_BY_BRAZILIAN_BIN = {
  en_US: 'Only for cards issued in Brazil it is allowed to use a CPF.',
  pt_BR: 'Somente para cartões emitidos no Brasil é permitido usar um CPF.',
  es_ES: 'Solo para tarjetas emitidas en Brasil se permite el uso de un CPF.',
};

export const ERROR_BRAZILIAN_DEPENDENT_MUST_USE_CPF = {
  en_US:
    'Check your dependents and make sure that all Brazilians have provided a CPF.',
  pt_BR:
    'Verifique seus dependentes e certifique que todos os brasileiros tenham informado um CPF.',
  es_ES:
    'Verifica tus dependientes y asegúrate de que todos los brasileños hayan proporcionado un CPF.',
};

export const TITLE_BENEFIT_TERMS = {
  en_US: 'Terms and conditions',
  pt_BR: 'Termos e condições',
  es_ES: 'Términos y condiciones',
};

export const PRODUCT_NOT_COVERAGE = {
  en_US:
    'An error occurred while performing this operation. Please verify whether your card provides coverage for this product.',
  pt_BR:
    'Ocorreu um erro ao efetuar esta operação. Por favor, verifique se seu cartão oferece cobertura para este produto.',
  es_ES:
    'Se ha producido un error al realizar esta operación. Por favor, verifica si tu tarjeta ofrece cobertura para este producto.',
};

export const TITLE_YOUR_CERTIFICATES = {
  en_US: 'Your certificates',
  es_ES: 'Tus certificados',
  pt_BR: 'Seus bilhetes',
};
