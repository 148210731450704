import { useContext, useState, useRef } from 'react';
import { StateContext } from '../../../../../../components/StateContextParent/StateContextParent';
import styles from './EditEmailCardHolderForm.module.scss';
import { IntlContext } from '../../../../../../intl';
import { useMemo } from 'react';
import Button from '../../../../../../components/Button/Button';
import { getRefs } from './submit/getRefs';
import { checkErrorsOnForm } from './submit/checkErrorsOnForm';
import { formSubmit } from './submit/index';
import ModalInformation from '../../../../../../components/ModalInformation/ModalInformation';
import * as translations from '../../intl';
import { AppInsightTrackContext } from '../../../../../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';
import { initialValuesScheme } from './initialValuesScheme';
import EmailInformation from './EmailInformation/EmailInformation';

const EditEmailCardHolderForm = ({ loadedProfile }) => {
  const { utils, state, actions } = useContext(StateContext);
  const { idiom, translate, idiomForApi } = useContext(IntlContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);

  const intl = translate(translations);

  const [schemas] = useState({});
  const [values] = useState({});
  const cn = utils.getCn();
  const submitRefs = getRefs(useRef);
  const [prefix, setPrefix] = useState('');
  const initialValues = useMemo(
    () => initialValuesScheme(loadedProfile),
    [idiom, loadedProfile]
  );

  const updateForm = (index, childValues, validationSchema) => {
    if (childValues !== values[index]) {
      values[index] = childValues;
    }
    if (validationSchema !== schemas[index]) {
      schemas[index] = validationSchema;
    }
  };

  const showModalSuccess = (msg, subtitle) => {
    return actions.modal.showModal(
      false,
      false,
      <ModalInformation
        type="success"
        message={msg}
        subtitle={subtitle}
        clickBtn={() => {
          window.location.href = '/logout';
          actions.modal.closeModal();
        }}
      />,
      true,
      false
    );
  };

  const submit = async () => {
    const areFormsOk = await checkErrorsOnForm(submitRefs, schemas, values);

    if (areFormsOk) {
      try {
        actions.modal.showLoading(intl.LOADING_SAVING_CONTACT_DETAILS);
        await formSubmit(values, cn, idiomForApi());
        actions.modal.hideLoading();
        showModalSuccess(
          intl.TEXT_EMAIL_CHANGE_SUCCESS,
          intl.SUBTITLE_EMAIL_CHANGE_SUCCESS
        );
      } catch (e) {
        actions.modal.hideLoading();

        actions.modal.showModal(
          false,
          false,
          <ModalInformation type="error" message={intl.ERROR} />,
          true,
          false
        );
      }
    }
  };

  return (
    <div className={styles.editCardHolder}>
      <EmailInformation
        updateParent={updateForm.bind(null, 'cardholder')}
        initialValues={initialValues}
        policySubmitRef={submitRefs.cardholder}
        prefixOptions={{ prefix, setPrefix }}
      />
      <div className={`row ${styles.section}`}>
        <div className={`col-12 ${styles.footerBtns}`}>
          <Button
            id="editPersonalDetailsButton"
            type="borderBlue"
            onClick={submit}
            width="180px"
          >
            {intl.BTN_SAVE}
          </Button>
          <Button
            id="cancelEditPersonalDetailsButton"
            type="blueWithoutBorder"
            onClick={actions.modal.closeModal}
            width="180px"
          >
            {intl.BTN_CANCEL}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EditEmailCardHolderForm;
