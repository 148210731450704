import styles from './Feedback.module.scss';
import Button from '../../../components/Button/Button';
import * as translations from './intl';
import { useIntl } from '../../../intl';
import ArrowLeftIcon from '../../../assets/icons/ArrowLeftIcon';
import { useHistory } from 'react-router-dom';

const Feedback = () => {
  const { translate } = useIntl();
  const { push } = useHistory();
  const intl = translate(translations);

  const handleRedirect = () => {
    push('/');
  };

  return (
    <div className={styles.container}>
      <Button type="blue" typeHtml="button" onClick={handleRedirect}>
        <ArrowLeftIcon color="#fff" width={16} />
        {intl.LABEL_BUTTON_BACK_TO_HOME}
      </Button>
    </div>
  );
};

export default Feedback;
