import { validationFormQuote } from './Quote/FormQuote/validationSchema';
import { verifyAccess as verifyAcessCrosseling } from '../../utils/verifyAccessCrosselling';
import { verifyAccess } from '../../utils/verifyAccess';
import { formQuoteInitialValues } from './Quote/FormQuote/initialValues';

export const UPSELL = 'upsell';
export const CROSSELL = 'crossell';

export type B2B2CType = typeof UPSELL | typeof CROSSELL;

export const getType = (path: string): B2B2CType => {
  const type = ['cross-sell', 'upsell'].find(type => {
    return path.includes(type);
  });

  if (type === UPSELL) return UPSELL;

  if (type === 'cross-sell') return CROSSELL;

  return undefined;
};

export const CREATE_CONFIG_B2B2C = match => {
  const GET_TYPE = getType(match.path);
  const GET_CONFIG = CONFIG_B2B2C(GET_TYPE);
  return GET_CONFIG[GET_TYPE];
};

const CONFIG_B2B2C = type => {
  return {
    crossell: {
      quote: {
        text: {
          text_quote: intl => {
            return {
              title_button_back: intl.QUOTE_CROSSELL_TITLE,
            };
          },
          text_formQuote: intl => {
            return {
              title: intl.TITLE_QUOTE_CROSSELL,
              description: intl.SUBTITLE_QUOTE_CROSSELL(),
            };
          },
          text_coverage_date: (intl, isAnnual) => {
            if (isAnnual === undefined)
              return {
                disclaimer: intl.TEXT_COVERAGE_UNTIL_CROSSELL_NON_SELECTED,
              };

            return isAnnual
              ? {
                  disclaimer: intl.TEXT_COVERAGE_UNTIL_CROSSELL_ANNUAL,
                }
              : {
                  disclaimer: intl.TEXT_COVERAGE_UNTIL_CROSSELL_SINGLE_TRIP,
                };
          },
        },
        form: {
          initialValues: (quote, card) => {
            return formQuoteInitialValues(quote, card, type);
          },
          validationForm: intl => validationFormQuote(intl, type),
          country_origin: false,
          country_destination: false,
          amount_currency: false,
          trip_start: true,
          trip_end: true,
          passanger: true,
          styleFirstItem: false,
          redirect: '/crossell-purchase-plan',
        },
      },
      purchase: {
        back_link: '/cross-sell-benefit',
        quote_detail: '/cross-sell-detail',
        text: {
          general: intl => {
            return {
              title_button_back: intl.QUOTE_PURCHASE_TITLE_CROSSELL,
            };
          },
          terms_and_conditions: intl => {
            return {
              subtitle: intl.PURCHASE_SUBTITLE_TERMS_CROSSELL,
              checkbox_1: intl.TERMS_CONDITIONS_1_CROSSELL,
              checkbox_2: intl.TERMS_CONDITIONS_2_CROSSELL,
              checkbox_3: intl.TERMS_CONDITIONS_3_CROSSELL,
            };
          },
        },
        form: {
          payment: {
            issuance_date: {
              show: true,
              edit: false,
            },
            product_selected: {
              show: false,
              edit: false,
            },
            total_pay: {
              show: false,
              edit: false,
            },
            origin_country: {
              show: true,
              edit: false,
            },
            destination_country: {
              show: true,
              edit: false,
            },
            initial_date: {
              show: true,
              edit: false,
            },
            end_date: {
              show: true,
              edit: false,
            },
            passanger: {
              show: false,
              edit: false,
            },
          },
        },
      },
      verifyAcess: (benefit, card_selected, email) => {
        return verifyAcessCrosseling(benefit, card_selected, email);
      },
      quoteDetail: {
        getBanner: (configBackground, intl) => ({
          background: configBackground.crosselBackground,
          title: intl.CROSSELL_TITLE,
          textButton: intl.CROSSELL_BUTTON,
          linkButton: benefit => `/cross-sell-benefit/${benefit}`,
        }),
        getContent: (configImage, intl) => ({
          image: configImage.crosselImage,
          subtitle: intl.COVERAGE_SUBTITLE_CROSSELL,
          description: intl.DESCRIPTION_COVERAGE_CROSSELL,
        }),
        fakeQuoteShow: false,
        myExtensionPlansShow: true,
        benefitsShow: true,
        quoteBannerShow: true,
        subscriptionBenefitsShow: false,
      },
      homeMarket: intl => ({
        title: intl.TITLE_CROSSELL,
        button: intl.TEXT_BUTTON_CROSSELL,
        link: '/cross-sell-preview/EMC_Emergency_Medical_Assistance',
      }),
    },
    upsell: {
      quote: {
        text: {
          text_quote: intl => {
            return {
              title_button_back: intl.QUOTE_UPSELL_TITLE,
            };
          },
          text_formQuote: intl => {
            return {
              title: intl.TITLE_QUOTE_UPSELL,
              description: intl.SUBTITLE_QUOTE_UPSELL(),
            };
          },
          text_coverage_date: intl => {
            return {
              disclaimer: intl.TEXT_COVERAGE_UNTIL_UPSELL,
            };
          },
        },
        form: {
          initialValues: (quote, card) => {
            return formQuoteInitialValues(quote, card, type);
          },
          validationForm: intl => validationFormQuote(intl, type),
          country_origin: false,
          country_destination: false,
          amount_currency: false,
          trip_start: false,
          trip_end: false,
          passanger: true,
          styleFirstItem: true,
          redirect: '/upsell-purchase-plan',
        },
      },
      purchase: {
        back_link: '/upsell-benefit',
        quote_detail: '/upsell-detail',
        text: {
          general: intl => {
            return {
              title_button_back: intl.QUOTE_PURCHASE_TITLE_UPSELL,
            };
          },
          terms_and_conditions: intl => {
            return {
              subtitle: intl.PURCHASE_SUBTITLE_TERMS_UPSELL,
              checkbox_1: intl.TERMS_CONDITIONS_1_UPSELL,
              checkbox_2: intl.TERMS_CONDITIONS_2_UPSELL,
              checkbox_3: intl.TERMS_CONDITIONS_3_UPSELL,
              checkbox_4: intl.TERMS_CONDITIONS_4_ANNUAL,
            };
          },
        },
        form: {
          payment: {
            issuance_date: {
              show: true,
              edit: false,
            },
            product_selected: {
              show: false,
              edit: false,
            },
            total_pay: {
              show: false,
              edit: false,
            },
            origin_country: {
              show: true,
              edit: false,
            },
            destination_country: {
              show: true,
              edit: false,
            },
            initial_date: {
              show: true,
              edit: true,
            },
            end_date: {
              show: true,
              edit: true,
            },
            passanger: {
              show: false,
              edit: false,
            },
          },
        },
      },
      verifyAcess: (benefit, card_selected, email) => {
        return verifyAccess(benefit, card_selected, email);
      },
      quoteDetail: {
        getBanner: (configBackground, intl) => ({
          background: configBackground.upsellBackground,
          title: intl.UPSELL_TITLE,
          textButton: intl.UPSELL_BUTTON,
          linkButton: benefit => `/upsell-benefit/${benefit}`,
        }),
        getContent: (configImage, intl) => ({
          image: configImage.upsellImage,
          subtitle: intl.COVERAGE_SUBTITLE_UPSELL,
          description: intl.DESCRIPTION_COVERAGE_UPSELL,
        }),
        fakeQuoteShow: true,
        myExtensionPlansShow: true,
        benefitsShow: false,
        quoteBannerShow: false,
        subscriptionBenefitsShow: true,
      },
      homeMarket: intl => ({
        title: intl.TITLE_UPSELL,
        button: intl.TEXT_BUTTON_UPSELL,
        link: '/upsell-preview/EMC_Emergency_Medical_Assistance',
      }),
    },
  };
};
