export default (env, axios, errorHandler) => ({
  forgotPassword: async data => {
    const url = '/forgot_password';
    return errorHandler(axios.post(url, data), 'forgotPassword', { url, data });
  },

  changePassword: async (idiom = 'en-US') => {
    const url = '/auth/change_password';
    return errorHandler(axios.post(url, { idiom }), 'changePassword', {
      url,
      data: [],
      idiom,
    });
  },

  changeEmail: async data => {
    const url = '/auth/change_email';
    return errorHandler(axios.post(url, data), 'changeEmail', {
      url,
      data,
    });
  },

  newPassword: async data => {
    const url = '/forgot_password_confirm';
    return errorHandler(axios.post(url, data), 'newPassword', {
      url,
      data: [],
    });
  },

  register: async data => {
    const url = '/register_user';
    return errorHandler(axios.post(url, data), 'register', { url, data });
  },

  getLogginAttempts: async () => {
    const url = env.REACT_APP_GET_LOGIN_ATTEMPT_URL;
    const headers = {
      Accept: 'application/json, text/plain, */*',
      Origin: 'http://localhost:8080',
    };
    const options = { withCredentials: true, headers };
    try {
      const { data } = await axios.get(url, options);
      return data.attempt;
    } catch (error) {
      return 0;
    }
  },
  getCHInfo: async cn => {
    const url =
      env.REACT_APP_JWE_CUSTOMER_API.toString() === 'true'
        ? `/internal/customer/specific/visagateway/v3/customers/${cn}`
        : `/api/specific/visagateway/v1/customers/${cn}`;
    return errorHandler(axios.get(url), 'getCHInfo', { url, cn });
  },
  createCHInfo: async (cn, data, idiom = 'en-US') => {
    const headers = {
      'Accept-Language': idiom,
    };

    const url =
      env.REACT_APP_JWE_CUSTOMER_API.toString() === 'true'
        ? `/internal/customer/specific/visagateway/v3/customers`
        : `/api/specific/visagateway/v1/customers`;
    return errorHandler(axios.post(url, data, { headers }), 'createCHInfo', {
      url,
      cn,
      data,
    });
  },
  updateCHInfo: async (cn, data, idiom = 'en-US') => {
    const headers = {
      'Accept-Language': idiom,
    };

    const url =
      env.REACT_APP_JWE_CUSTOMER_API.toString() === 'true'
        ? `/internal/customer/specific/visagateway/v3/customers/${cn}`
        : `/api/specific/visagateway/v1/customers/${cn}`;
    return errorHandler(axios.patch(url, data, { headers }), 'updateCHInfo', {
      url,
      cn,
      data,
    });
  },
  getCHDependents: async cn => {
    const url =
      env.REACT_APP_JWE_DEPENDENT_API.toString() === 'true'
        ? `/internal/customer/specific/visagateway/v3/customers/${cn}/dependents`
        : `/api/specific/visagateway/v1/customers/${cn}/dependents`;

    return errorHandler(axios.get(url), 'getCHDependents', { url, cn });
  },
  createCHDependents: async (cn, data) => {
    const url =
      env.REACT_APP_JWE_DEPENDENT_API.toString() === 'true'
        ? `/internal/customer/specific/visagateway/v3/customers/${cn}/dependents`
        : `/api/specific/visagateway/v1/customers/${cn}/dependents`;

    return errorHandler(axios.post(url, data), 'createCHDependents', {
      url,
      cn,
      data,
    });
  },
  updateCHDependents: async (cn, data, dependent_id) => {
    const url =
      env.REACT_APP_JWE_DEPENDENT_API.toString() === 'true'
        ? `/internal/customer/specific/visagateway/v3/customers/${cn}/dependents/${dependent_id}`
        : `/api/specific/visagateway/v1/customers/${cn}/dependents/${dependent_id}`;

    return errorHandler(axios.patch(url, data), 'updateCHDependents', {
      url,
      cn,
      data,
      dependent_id,
    });
  },
  getCHDependentsDetail: async (cn, dependent_id) => {
    const url =
      env.REACT_APP_JWE_DEPENDENT_API.toString() === 'true'
        ? `/internal/customer/specific/visagateway/v3/customers/${cn}/dependents/${dependent_id}`
        : `/api/specific/visagateway/v1/customers/${cn}/dependents/${dependent_id}`;

    return errorHandler(axios.get(url), 'getCHDependentsDetail', {
      url,
      cn,
      dependent_id,
    });
  },
  deleteDependent: async (cn, dependent_id) => {
    const url = `/api/specific/visagateway/v3/customers/${cn}/dependents/${dependent_id}`;
    return errorHandler(axios.delete(url), 'deleteDependent', {
      url,
      cn,
      dependent_id,
    });
  },
  getUserInfo: async () => {
    const url = `/userinfo`;
    return await axios.get(url);
  },
  getPreferences: async cn => {
    const url = `/api/specific/visagateway/v1/customers/${cn}/communication_subscriptions`;
    return errorHandler(axios.get(url), 'getPreferences', {
      url,
      cn,
    });
  },
  optInCommunication: async (cn, related, channel, device_ids = undefined) => {
    const data = {
      related_to: related,
      channel: channel,
      communication_device_ids: device_ids,
    };
    const url = `/api/specific/visagateway/v1/customers/${cn}/communication_subscriptions`;
    return errorHandler(axios.post(url, data), 'getPreferences', {
      url,
      cn,
      data,
    });
  },
  optOutCommunication: async (cn, subscriptionId) => {
    const url = `/api/specific/visagateway/v1/customers/${cn}/communication_subscriptions/${subscriptionId}`;
    return errorHandler(axios.delete(url), 'getPreferences', {
      url,
      cn,
    });
  },
  getCommunicationsDevice: async customer_id => {
    const url = `/api/specific/visagateway/v1/customers/${customer_id}/communication_devices`;
    return errorHandler(axios.get(url), 'getCommunicationsDevice', {
      url,
      customer_id,
    });
  },
  deleteCommunicationDevice: async (customer_id, communication_device_id) => {
    const url = `/api/specific/visagateway/v1/customers/${customer_id}/communication_devices/${communication_device_id}`;
    return errorHandler(axios.delete(url), 'deleteCommunicationDevice', {
      url,
      customer_id,
    });
  },
  convertLeadToCH: async () => {
    const url = `/api/specific/visagateway/vexp/prospects/convert`;
    return errorHandler(axios.post(url), 'convertLeadToCH', { url });
  },
});
