import { Actions } from '../../../../../../../../../@types/Actions';
import { octService } from '../../../../../../../../../services';
import { createPanTokenexEncrypted } from '../../../../../../../../../utils/createPanTokenexEncrypted';
import { formatDataPallaAccount } from '../OCTFunctions/OCTFunctions';
import { ObjectOCTInitialValues } from '../OCTInitialValues/OCTInitialValues';
import ModalInformation from '../../../../../../../../../components/ModalInformation/ModalInformation';
import { stringCapitalize } from '../../../../../../../../../utils/stringUtils';
import { isEqual } from 'lodash';

const showModalError = (actions, msg: string, subtitle?: string) => {
  return actions.modal.showModal(
    false,
    false,
    <ModalInformation type="error" message={msg} subtitle={subtitle} />,
    true,
    false
  );
};

export const OCTSubmit = async (
  values: ObjectOCTInitialValues,
  actions: Actions,
  intl,
  trackEventUserAction,
  tokenexPublicKey: string,
  setPaymentID,
  profileFlow?: boolean,
  profileSetPaymentID?: (payment_id: string) => void
) => {
  const showLoading = actions.modal.showLoading;
  const hideLoading = actions.modal.hideLoading;

  try {
    showLoading(intl.LOADING_NUEVA_TARJETA);
    const panEncrypted = await createPanTokenexEncrypted(
      values.card.number,
      values.card.cvv,
      trackEventUserAction,
      tokenexPublicKey
    );
    trackEventUserAction(
      '#### CREATE ACCOUNT AND PAYMENT METHOD - ENCRYPTED PAN  ###',
      { panEncrypted, values }
    );
    const objectPallaAccount = formatDataPallaAccount(values, panEncrypted);

    trackEventUserAction(
      '#### CREATE ACCOUNT AND PAYMENT METHOD - FORMAT DATA PALLA ACCOUNT  ###',
      { objectPallaAccount, values }
    );
    const payment_id = await octService.createOctFlow(objectPallaAccount);
    if (setPaymentID) {
      setPaymentID(payment_id.data);
    }

    trackEventUserAction(
      '#### CREATE ACCOUNT AND PAYMENT METHOD SUCCESS - PAYMENT ID  ###',
      {
        payment_id,
      }
    );

    if (profileFlow) {
      hideLoading();
      profileSetPaymentID(payment_id.data);
      actions.modal.showModal(
        '',
        true,
        <ModalInformation
          type="success"
          message={intl.ADD_PAYMENT_METHOD_SUCCESS}
        />
      );
    } else {
      actions.modal.closeModal();
    }
    return payment_id.data;
  } catch (error) {
    trackEventUserAction(
      '#### CREATE ACCOUNT AND PAYMENT METHOD - ERROR  ###',
      {
        data: error && error.response && error.response.data,
        response: error && error.response,
        error,
      }
    );
    hideLoading();
    if (
      error.response?.data?.error_description ==
      'PAYMENT_NOT_CREATED_409_CARD_EXISTS'
    ) {
      showModalError(actions, intl.ERROR_409, intl.ERROR_409_SUBTITLE);
      return;
    } else if (
      error.response?.data?.error_description ==
      'PAYMENT_NOT_CREATED_409_IDENTITY_NAME'
    ) {
      showModalError(
        actions,
        intl.UNEXPECTED_ERROR_OCCURRED_CARD,
        intl.UNEXPECTED_ERROR_OCCURRED_CARD_SUBTITLE
      );
      return;
    } else if (
      error.response?.data?.error_description == 'PAYMENT_NOT_CREATED_451_OFAC'
    ) {
      showModalError(actions, intl.ERROR_OFAC, intl.ERROR_OFAC_SUBTITLE);
      return;
    } else if (
      error.response?.data?.error_description ==
      'PAYMENT_NOT_CREATED_412_CARD_BIN'
    ) {
      showModalError(
        actions,
        intl.ERROR_INVALID_CARD,
        intl.ERROR_INVALID_CARD_SUBTITLE
      );
      return;
    } else if (
      error.response?.data?.error_description == 'PAYMENT_NOT_CREATED'
    ) {
      showModalError(
        actions,
        intl.UNEXPECTED_ERROR_OCCURRED_CARD,
        intl.UNEXPECTED_ERROR_OCCURRED_CARD_SUBTITLE
      );
      return;
    } else if (
      error.response?.data?.error_description ==
      'ACCOUNT_NOT_CREATED_400_INVALID_NAME'
    ) {
      showModalError(
        actions,
        intl.ERROR_INVALID_NAME_ACCOUNT,
        intl.ERROR_INVALID_NAME_ACCOUNT_SUBTITLE
      );
      return;
    } else if (
      error.response?.data?.error_description == 'ACCOUNT_NOT_CREATED'
    ) {
      showModalError(
        actions,
        intl.UNEXPECTED_ERROR_OCCURRED_ACCOUNT,
        intl.UNEXPECTED_ERROR_OCCURRED_ACCOUNT_SUBTITLE
      );
      return;
    }
    showModalError(actions, intl.UNEXPECTED_ERROR_OCCURRED);
  }
};

export const OCTEditAccount = async (
  values: ObjectOCTInitialValues,
  actions: Actions,
  intl,
  trackEventUserAction
) => {
  try {
    trackEventUserAction('#### EDIT ACCOUNT PALLA  ###', true);

    const savePromisse = new Promise<void>((resolve, reject) => {
      actions.modal.showModal(
        '',
        true,
        <ModalInformation
          type="question"
          message={intl.CHANGE_OCT_INFORMATION}
          subtitle={intl.CHANGE_OCT_INFORMATION_SUBTITLE}
          clickBtn={async () => {
            try {
              actions.modal.showLoading(intl.SAVING_CARD_INFORMATION);
              const objectPallaAccount = formatDataPallaAccount(values, null);
              trackEventUserAction(
                '#### EDIT ACCOUNT PALLA - FORMAT DATA PALLA ACCOUNT  ###',
                { objectPallaAccount, values }
              );
              await octService.editAccountOCT(objectPallaAccount);
              trackEventUserAction(
                '#### EDIT ACCOUNT PALLA SUCCESS -   ###',
                true
              );

              actions.modal.showModal(
                '',
                true,
                <ModalInformation
                  type="success"
                  message={intl.MODAL_CONFIRMATION_UPDATE_INFO}
                  clickBtn={() => {
                    actions.modal.closeModal();
                    resolve();
                  }}
                />,
                true,
                false
              );
            } catch (error) {
              trackEventUserAction('#### EDIT ACCOUNT PALLA ERROR -   ###', {
                data: error && error.response && error.response.data,
                response: error && error.response,
                error,
              });
              showModalError(actions, intl.UNEXPECTED_ERROR_OCCURRED);
              reject();
            }
          }}
          textBtn={intl.TEXT_YES}
          textBtnDeny={intl.TEXT_NO}
          clickBtnDeny={() => {
            resolve();
            actions.modal.closeModal();
          }}
        />
      );
    });

    return savePromisse;
  } catch (error) {
    trackEventUserAction('#### EDIT ACCOUNT PALLA - ERROR  ###', {
      data: error && error.response && error.response.data,
      response: error && error.response,
      error,
    });
    showModalError(actions, intl.UNEXPECTED_ERROR_OCCURRED);
  }
};

export const checkOCTInfoChange = async (
  values: ObjectOCTInitialValues,
  actions: Actions,
  intl,
  trackEventUserAction,
  paymentId,
  setPaymentId
) => {
  try {
    if (!values.paymentIdOCT && !paymentId) {
      return await OCTSubmit(
        values,
        actions,
        intl,
        trackEventUserAction,
        process.env.REACT_APP_TOKENEX_PUBLIC_KEY,
        setPaymentId,
        false,
        null
      );
    } else {
      trackEventUserAction('#### checkOCTInfoChange - PALLA PAYMENT ID  ###', {
        paymentIdOCT: values.paymentIdOCT,
        paymentId,
      });
      actions.modal.showLoading(intl.CHECKING_OCT_INFORMATION);
      const {
        data: { user_detail, payments },
      } = await octService.getAccountDetailPalla();

      const pallaCurrentValues = {
        birth_date: `${user_detail.birth_date.year}-${
          user_detail.birth_date.month <= 9
            ? `0${user_detail.birth_date.month}`
            : user_detail.birth_date.month
        }-${
          user_detail.birth_date.day <= 9
            ? `0${user_detail.birth_date.day}`
            : user_detail.birth_date.day
        }`,
        name: user_detail.name,
      };

      const formValues = {
        birth_date: values.birth_date,
        name: {
          first: stringCapitalize(values.name.first),
          last: stringCapitalize(values.name.last),
        },
      };

      const changedPersonalInformation = !isEqual(
        formValues,
        pallaCurrentValues
      );
      trackEventUserAction('#### checkOCTInfoChange - COMPARE TO UPDATE  ###', {
        pallaCurrentValues,
        formValues,
        changedPersonalInformation,
      });

      if (changedPersonalInformation) {
        await OCTEditAccount(values, actions, intl, trackEventUserAction);
      } else {
        actions.modal.closeModal();
      }
      return payments.find(payment => payment.primary);
    }
  } catch (error) {
    trackEventUserAction('#### CHECK OCT INFO CHANGE - ERROR  ###', {
      data: error && error.response && error.response.data,
      response: error && error.response,
      error,
    });
    showModalError(actions, intl.UNEXPECTED_ERROR_OCCURRED);
  }
};
