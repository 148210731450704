export const formatToApi = props => {
  const holder = formatHolderToApi(props);
  return { holder };
};

const formatHolderToApi = props => {
  const { cardholder } = props;

  const holder = {
    email: cardholder.email,
  };

  return holder;
};
