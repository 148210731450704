import { Dispatch, SetStateAction, useState } from 'react';
import Instruction from './Instruction/Instruction';
import DocumentIcon from '../../../assets/iconsV2/DocumentIcon';
import FaceIcon from '../../../assets/iconsV2/FaceIcon';
import ProfileSuccessIcon from '../../../assets/iconsV2/ProfileSuccessIcon';
import { useIntl } from '../../../intl';
import * as translations from './intl';
import styles from './IdentityConfirmation.module.scss';
import Button from '../../../components/Button/Button';
import ArrowRightIcon from '../../../assets/icons/ArrowRight';
import FileUploader from '../../../componentsV2/FileUploader/FileUploader';

interface Props {
  setStep: Dispatch<SetStateAction<number>>;
}

const IdentityConfirmation = ({ setStep }: Props) => {
  const [file, setFile] = useState<File>(null);

  const { translate } = useIntl();
  const intl = translate(translations);

  const handleNextStep = () => {
    setStep(3);
  };

  return (
    <div className={styles.container}>
      <ul className={styles.instructionsList}>
        <Instruction
          Icon={DocumentIcon}
          title={intl.INSTRUCTION_1_TITLE}
          description={intl.INSTRUCTION_1_DESCRIPTION}
        />
        <Instruction
          Icon={FaceIcon}
          title={intl.INSTRUCTION_2_TITLE}
          description={intl.INSTRUCTION_2_DESCRIPTION}
        />
        <Instruction
          Icon={ProfileSuccessIcon}
          title={intl.INSTRUCTION_3_TITLE}
          description={intl.INSTRUCTION_3_DESCRIPTION}
        />
      </ul>

      <div className={styles.uploadArea}>
        <FileUploader
          title={intl.UPLOAD_TITLE}
          description={intl.UPLOAD_DESCRIPTION}
          callbackAddFile={async (file: File) => {
            setFile(file);
          }}
          callbackDeleteFile={async (file: File) => {
            setFile(null);
          }}
        />
      </div>

      {file && (
        <footer className={styles.actionNextStep}>
          <div className={styles.wrapperButton}>
            <Button typeHtml="button" type="blue" onClick={handleNextStep}>
              {intl.LABEL_BUTTON}
              <ArrowRightIcon color="#fff" width={16} />
            </Button>
          </div>
        </footer>
      )}
    </div>
  );
};

export default IdentityConfirmation;
