import { userServices } from '../../../../../../../services';
import { formatToApi } from './formatData';

export const formSubmit = async (values, cn, idiom = 'en-US') => {
  const { holder } = formatToApi(values);

  console.log({ cn, holder, idiom });
  await userServices.changeEmail(holder);

  return true;
};
