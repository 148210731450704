import { ReactElement, SVGProps } from 'react';
import styles from './Instruction.module.scss';
import { useTheme } from '../../../../themes/ThemeContextParent/ThemeContextParent';

interface Props {
  title: string;
  description: string;
  Icon: (props: SVGProps<SVGElement>) => ReactElement;
}

const Instruction = ({ title, description, Icon }: Props) => {
  const { getGlobalTheme } = useTheme();
  const themes = getGlobalTheme();

  return (
    <li className={styles.instructionItem}>
      <Icon />
      <div className={styles.textArea}>
        <h3 className={styles[themes]}>{title}</h3>
        <p>{description}</p>
      </div>
    </li>
  );
};

export default Instruction;
