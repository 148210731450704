import { useIntl } from '../../../intl';
import Badge, { BadgeProps } from '../Badge';
import * as translations from './intl';
import * as config from './status';

type ContextType = keyof typeof config;

export interface StatusBadgeProps {
  context?: ContextType;
  status: config.StatusPossibility;
}

const StatusBadge = ({ context = 'GENERICS', status }: StatusBadgeProps) => {
  const { translate } = useIntl();
  const intl = translate(translations);

  const matchConfig = config[context];
  const configObject = matchConfig(intl);

  const badgeProps: BadgeProps =
    configObject[status as keyof typeof configObject];

  return <Badge label={badgeProps?.label} type={badgeProps?.type} />;
};

export default StatusBadge;
