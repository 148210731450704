import { LABEL_STATUS } from '../StatusLabel/constatsStatus';
import styles from './StatusClaim.module.scss';
import {
  review,
  payment,
  rejected,
  closed,
  under_review_by_underwriter,
  case_closed_duplicated,
  pendingSend,
} from '../../pages/Profile/ProfileClaims/StatusClaimBySubStatus';
import StatusBadge from '../../componentsV2/Badge/StatusBadge/StatusBadge';

const StatusClaim = ({ claim }) => {
  const claimStatusPending = 'pending send';
  const getClaimStatus = () => {
    const { status, statuses } = claim;
    const sub_status = statuses ? statuses.sub_status : false;

    if (!status) return;

    if (
      status.toLowerCase() === claimStatusPending.toLocaleLowerCase() &&
      !sub_status.name
    ) {
      return (
        <div className={styles.labelStatus}>
          <StatusBadge status={LABEL_STATUS.PENDING_DOCUMENTATION} />
        </div>
      );
    }

    if (sub_status && review.includes(sub_status.name)) {
      if (under_review_by_underwriter.includes(sub_status.name)) {
        return (
          <div className={styles.labelStatus}>
            <StatusBadge status={LABEL_STATUS.REVIEW_BY_UNDERWRITER} />
          </div>
        );
      } else {
        return (
          <div className={styles.labelStatus}>
            <StatusBadge status={LABEL_STATUS.REVIEW} />
          </div>
        );
      }
    }

    if (sub_status && pendingSend.includes(sub_status.name)) {
      return (
        <div className={styles.labelStatus}>
          <StatusBadge status={LABEL_STATUS.PENDING_DOCUMENTATION} />
        </div>
      );
    }

    if (sub_status && payment.includes(sub_status.name)) {
      return (
        <div className={styles.labelStatus}>
          <StatusBadge status={LABEL_STATUS.COMPLETED} />
        </div>
      );
    }

    if (sub_status && rejected.includes(sub_status.name)) {
      return (
        <div className={styles.labelStatus}>
          <StatusBadge status={LABEL_STATUS.REJECTED} />
        </div>
      );
    }

    if (sub_status && closed.includes(sub_status.name)) {
      if (case_closed_duplicated.includes(sub_status.name)) {
        return (
          <div className={styles.labelStatus}>
            <StatusBadge status={LABEL_STATUS.CLOSED_DUPLICATED} />
          </div>
        );
      } else {
        return (
          <div className={styles.labelStatus}>
            <StatusBadge status={LABEL_STATUS.CLOSED} />
          </div>
        );
      }
    }

    return (
      <div className={styles.labelStatus}>
        <StatusBadge status={LABEL_STATUS.REVIEW} />
      </div>
    );
  };

  return <>{getClaimStatus()}</>;
};

export default StatusClaim;
