export const MENU_ACCOUNT = {
  en_US: 'My account',
  es_ES: 'Mi cuenta',
  pt_BR: 'Minha conta',
};
export const MENU_YOUR_CERTIFICATES = {
  en_US: 'My certificates',
  es_ES: 'Mis certificados',
  pt_BR: 'Meus bilhetes',
};
export const MENU_YOUR_CLAIMS = {
  en_US: 'My claims',
  es_ES: 'Mis reclamos',
  pt_BR: 'Meus sinistros',
};
export const MENU_SETTINGS = {
  en_US: 'Settings',
  es_ES: 'Configuración',
  pt_BR: 'Configuração',
};
export const MENU_HELP = {
  en_US: 'Help',
  es_ES: 'Ayuda',
  pt_BR: 'Ajuda',
};

export const MENU_NOTIFICATIONS = {
  en_US: 'Notifications',
  es_ES: 'Notificaciones',
  pt_BR: 'Notificações',
};

export const SUBMENU_SAVING = {
  en_US: `My Savings`,
  pt_BR: `Minhas Poupanças`,
  es_ES: `Mis Ahorros`,
};

export const ACCOUNT = {
  en_US: 'Account',
  es_ES: 'Cuenta',
  pt_BR: 'Conta',
};

export const CERTIFICATES = {
  en_US: 'Certificates',
  es_ES: 'Certificados',
  pt_BR: 'Certificados',
};

export const CLAIMS = {
  en_US: 'Claims',
  es_ES: 'Reclamaciones',
  pt_BR: 'Sinistros',
};

export const MONETIZATION = {
  en_US: 'Monetization',
  es_ES: 'Monetización',
  pt_BR: 'Monetização',
};

export const NOTIFICATIONS = {
  en_US: 'Notifications',
  es_ES: 'Notificaciones',
  pt_BR: 'Notificações',
};

export const SETTINGS = {
  en_US: 'Settings',
  es_ES: 'Configuración',
  pt_BR: 'Configuração',
};

export const SUBMENU_VOUCHERS = {
  en_US: `Your Vouchers`,
  pt_BR: `Seus Vouchers`,
  es_ES: `Tus Vouchers`,
};
