export const OPTION_LANGUAGE_EN = {
  es_ES: 'Inglés',
  en_US: 'English',
  pt_BR: 'Inglês',
};

export const OPTION_LANGUAGE_PT = {
  es_ES: 'Portugués',
  en_US: 'Portuguese',
  pt_BR: 'Português',
};

export const OPTION_LANGUAGE_ES = {
  es_ES: 'Español',
  en_US: 'Spanish',
  pt_BR: 'Espanhol',
};

export const TERMS_OF_SERVICE = {
  es_ES: 'Términos de Servicio',
  en_US: 'Terms of Service',
  pt_BR: 'Termos de Serviço',
};

export const PRIVACY_POLICY = {
  es_ES: 'Política de Privacidad',
  en_US: 'Privacy Policy',
  pt_BR: 'Política de Privacidade',
};
export const LINK_TERMS_OF_SERVICE = {
  en_US: 'https://usa.visa.com/legal.html',
  pt_BR: 'https://www.visa.com.br/termos-de-uso.html',
  es_ES: 'https://www.visa.com.mx/legal.html',
};
