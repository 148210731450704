import { en_countries } from '../../../intl/en_countries';
import { pt_countries } from '../../../intl/pt_countries';
import { es_countries } from '../../../intl/es_countries';

export const TEXT_REQUIRED = {
  en_US: 'This field is required.',
  pt_BR: 'Este campo é obrigatório.',
  es_ES: 'Este campo es requerido.',
};

export const TEXT_SAME_EMAIL = {
  en_US: 'The emails need to be different.',
  pt_BR: 'Os emails precisam ser diferentes.',
  es_ES: 'Los correos electrónicos deben ser diferentes.',
};

export const LABEL_NAME = {
  en_US: 'First name *',
  pt_BR: 'Nome *',
  es_ES: 'Nombre *',
};
export const LABEL_LAST_NAME = {
  en_US: 'Last name *',
  pt_BR: 'Sobrenome *',
  es_ES: 'Apellido *',
};
export const LABEL_BIRTH_DATE = {
  en_US: 'Date of birth *',
  pt_BR: 'Data de nascimento *',
  es_ES: 'Fecha de nacimiento *',
};

export const COUNTRY_LABEL = {
  en_US: 'Country *',
  pt_BR: 'País *',
  es_ES: 'País  *',
};
export const COUNTRY_OPTIONS = {
  en_US: en_countries,
  pt_BR: pt_countries,
  es_ES: es_countries,
};
export const LABEL_IDENTIFICATION = {
  en_US: 'Document type *',
  pt_BR: '',
  es_ES: 'Tipo de documento *',
};
export const LABEL_CPF = {
  en_US: '',
  pt_BR: 'CPF *',
  es_ES: '',
};
export const TEXT_ALERT_CPF = {
  en_US: '',
  pt_BR: 'Para menores de idade, por favor completar com o CPF de um dos pais.',
  es_ES: '',
};
export const VALIDATE_CPF = {
  en_US: 'Please enter a valid CPF.',
  pt_BR: 'Por favor, insira um CPF válido.',
  es_ES: 'Por favor, introduzca un CPF válido',
};
export const LABEL_PASSPORT = {
  en_US: 'Passport number *',
  pt_BR: 'Passaporte *',
  es_ES: 'Número de pasaporte *',
};
export const LABEL_NATIONAL_ID = {
  en_US: 'National ID *',
  pt_BR: 'Identidade Nacional *',
  es_ES: 'Documento Nacional de Identidad *',
};
export const TEXT_ALERT_NATIONAL_ID = {
  en_US:
    'For minors, please complete with the national ID of one of the parents.',
  pt_BR:
    'Para menores de idade, por favor completar com a identidade nacional de um dos pais.',
  es_ES:
    'Para menores de edad, por favor completar con el Documento Nacional de Identidad de uno de los padres/tutores.',
};
export const TEXT_ALERT_PASSPORT = {
  en_US: 'For minors, please complete with the passport of one of the parents.',
  pt_BR: 'Para menores de idade, por favor completar com o CPF de um dos pais.',
  es_ES:
    'Para menores de edad, por favor completar con el pasaporte de uno de los padres/tutores.',
};

export const CURRENT_EMAIL = {
  en_US: 'Current e-mail *',
  pt_BR: 'E-mail atual *',
  es_ES: 'Correo electrónico actual *',
};
export const LOST_EMAIL = {
  en_US: `E-mail you don't have access *`,
  pt_BR: 'E-mail que não tem acesso *',
  es_ES: 'Correo electrónico al que no tienes acceso *',
};

export const LABEL_SUBMIT = {
  en_US: 'Cotinue',
  pt_BR: 'Continuar',
  es_ES: 'Continuar',
};

export const VALIDATE_EMAIL_VALID = {
  en_US: 'Please enter a valid e-mail.',
  pt_BR: 'Por favor, insira um e-mail válido.',
  es_ES: 'Por favor, introduzca un e-mail válido.',
};
