export const CHANGE_EMAIL = {
  en_US: 'Change Email',
  es_ES: 'Cambiar correo electrónico',
  pt_BR: 'Alterar e-mail',
};

export const BODY_TEXT = {
  en_US: 'Do you want to change your email? Click below to change it.',
  es_ES:
    '¿Quieres cambiar tu correo electrónico? Haga clic a continuación para cambiarlo.',
  pt_BR: 'Você quer alterar seu e-mail? Clique abaixo para alterar.',
};

export const TEXT_EDIT_EMAIL = {
  en_US: 'Edit personal details',
  pt_BR: 'Editar informações pessoais',
  es_ES: 'Editar información personal',
};

export const BTN_EDIT = {
  en_US: 'Edit',
  es_ES: 'Editar',
  pt_BR: 'Editar',
};

export const BTN_SAVE = {
  en_US: 'Save',
  pt_BR: 'Salvar',
  es_ES: 'Guardar',
};

export const BTN_CANCEL = {
  en_US: 'Cancel',
  pt_BR: 'Cancelar',
  es_ES: 'Cancelar',
};

export const TEXT_EMAIL_CHANGE_SUCCESS = {
  en_US: 'Your email has been changed successfully!',
  pt_BR: 'Seu email foi alterado com sucesso!',
  es_ES: '¡Tu correo electrónico ha sido cambiado con éxito!',
};

export const SUBTITLE_EMAIL_CHANGE_SUCCESS = {
  en_US:
    'You will be disconnected shortly, check your inbox for the new email.',
  pt_BR:
    'Você será desconectado em breve, verifique sua caixa de entrada do novo email.',
  es_ES:
    'Cerrarás tu sesión pronto, revisa tu bandeja de entrada para ver el nuevo correo electrónico.',
};

export const ERROR = {
  en_US: 'Error updating your email. Please try again later.',
  pt_BR: 'Erro ao atualizar seu e-mail. Por favor, tente novamente mais tarde.',
  es_ES:
    'Error al actualizar tu correo electrónico. Por favor, inténtalo de nuevo más tarde.',
};
