import { useTheme } from '../../../themes/ThemeContextParent/ThemeContextParent';

const WarningIcon = () => {
  const { getColors } = useTheme();
  const { genericColors } = getColors();
  const defaultColor = genericColors.orange_70;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8 15C4.13388 15 1 11.8661 1 8C1 4.13388 4.13388 1 8 1C11.8661 1 15 4.13388 15 8C15 11.8661 11.8661 15 8 15Z"
        stroke={defaultColor}
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 12V10H9V12H7Z"
        fill={defaultColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 9V4H9V9H7Z"
        fill={defaultColor}
      />
    </svg>
  );
};

export default WarningIcon;
