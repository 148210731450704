import { useTheme } from '../../../themes/ThemeContextParent/ThemeContextParent';

const SuccessIcon = () => {
  const { getColors } = useTheme();
  const { genericColors } = getColors();
  const defaultColor = genericColors.green_70;

  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Success">
        <path
          id="primary"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.5 2C5.18629 2 2.5 4.68629 2.5 8C2.5 11.3137 5.18629 14 8.5 14C11.8137 14 14.5 11.3137 14.5 8C14.5 7.75479 14.4853 7.51344 14.4569 7.27669L16.4427 7.03851C16.4806 7.35412 16.5 7.67501 16.5 8C16.5 12.4183 12.9183 16 8.5 16C4.08172 16 0.5 12.4183 0.5 8C0.5 3.58172 4.08172 0 8.5 0C9.65434 0 10.7541 0.245103 11.7479 0.687059L10.9352 2.5145C10.1923 2.18411 9.36898 2 8.5 2Z"
          fill={defaultColor}
        />
        <path
          id="secondary"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.7327 3.68042L8.52663 11.4409L4.29285 7.20708L5.70706 5.79286L8.47328 8.55908L14.2672 2.31952L15.7327 3.68042Z"
          fill={defaultColor}
        />
      </g>
    </svg>
  );
};

export default SuccessIcon;
