import ChevronDownIcon from './ChevronDownIcon';
import ChevronUpIcon from './ChevronUpIcon';
import ChevronRightIcon from './ChevronRightIcon';
import ChevronLeftIcon from './ChevronLeftIcon';
import { SVGProps } from 'react';

interface ChevronGenericProps extends SVGProps<SVGSVGElement> {
  axa?: boolean;
}

const ChevronGeneric = (props: ChevronGenericProps) => {
  const { rotate = 0 } = props;
  const renderChevron = {
    'rotate-180': () => <ChevronUpIcon {...props} />,
    'rotate-0': () => <ChevronDownIcon {...props} />,
    'rotate-90': () => <ChevronLeftIcon {...props} />,
    'rotate-270': () => <ChevronRightIcon {...props} />,
  };
  return <>{renderChevron[`rotate-${rotate}`]()}</>;
};

export default ChevronGeneric;
