export const pt_nationalities = `
<option value=""> Nacionalidade *</option>
<option value="AF"> Afegão </option>
<option value="AL"> Albanês </option>
<option value="DE"> Alemão </option>
<option value="US"> Americano </option>
<option value="AD"> Andorrano </option>
<option value="AO"> Angolano </option>
<option value="AI"> Anguilano </option>
<option value="AG"> Antiguano </option>
<option value="AN"> Antilhano </option>
<option value="TF"> Antilhas Francesas </option>
<option value="AQ"> Antártica </option>
<option value="DZ"> Argelino </option>
<option value="AR"> Argentino </option>
<option value="AM"> Armeno </option>
<option value="AW"> Arubano </option>
<option value="AC"> Ascension </option>
<option value="AU"> Australiano </option>
<option value="AT"> Austríaco </option>
<option value="AZ"> Azeri </option>
<option value="BS"> Bahamense </option>
<option value="BD"> Bangladechiano </option>
<option value="BB"> Barbadense </option>
<option value="BH"> Barenita </option>
<option value="BW"> Bechuano </option>
<option value="BE"> Belga </option>
<option value="BZ"> Belizenho </option>
<option value="BJ"> Beninense </option>
<option value="BM"> Bermudense </option>
<option value="BY"> Bielorrusso </option>
<option value="MM"> Birmanês </option>
<option value="BO"> Boliviano </option>
<option value="BR"> Brasileiro </option>
<option value="GB"> Britânico </option>
<option value="BN"> Bruneano </option>
<option value="BF"> Burquinense </option>
<option value="BI"> Burundês </option>
<option value="BT"> Butanês </option>
<option value="BA"> Bósnio </option>
<option value="BG"> Búlgaro </option>
<option value="CV"> Cabo-verdiano </option>
<option value="KY"> Caimanês </option>
<option value="CM"> Camaronense </option>
<option value="KH"> Cambojano </option>
<option value="CA"> Canadense </option>
<option value="KZ"> Cazaque </option>
<option value="CF"> Centroafricano </option>
<option value="TD"> Chadiano </option>
<option value="CL"> Chileno </option>
<option value="CN"> Chinês </option>
<option value="LK"> Cingalês </option>
<option value="CY"> Cipriota </option>
<option value="CO"> Colombiano </option>
<option value="KM"> Comorense </option>
<option value="CG"> Congolense </option>
<option value="CK"> Cookense </option>
<option value="CR"> Costarriquenho </option>
<option value="HR"> Croata </option>
<option value="CU"> Cubano </option>
<option value="CW"> Curaçauense </option>
<option value="DK"> Dinamarquês </option>
<option value="DJ"> Djibutiano </option>
<option value="DO"> Dominicana </option>
<option value="DM"> Dominicano </option>
<option value="EG"> Egípcio </option>
<option value="AE"> Emiradense </option>
<option value="EC"> Equatoriano </option>
<option value="ER"> Eritreu </option>
<option value="SK"> Eslovaco </option>
<option value="SI"> Esloveno </option>
<option value="ES"> Espanhol </option>
<option value="EE"> Estoniano </option>
<option value="ET"> Etíope </option>
<option value="FO"> Faroense </option>
<option value="FJ"> Fijiano </option>
<option value="PH"> Filipino </option>
<option value="FI"> Finlandês </option>
<option value="FR"> Francês </option>
<option value="GF"> Franco-guianense </option>
<option value="GA"> Gabonense </option>
<option value="GM"> Gambiano </option>
<option value="GH"> Ganés </option>
<option value="GE"> Geórgico </option>
<option value="GI"> Gibraltino </option>
<option value="GD"> Granadino </option>
<option value="GR"> Grego </option>
<option value="GL"> Groenlandês </option>
<option value="GP"> Guadalupense </option>
<option value="GU"> Guamês </option>
<option value="GT"> Guatemalteco </option>
<option value="GY"> Guianense </option>
<option value="GN"> Guineano </option>
<option value="GW"> Guineense </option>
<option value="GQ"> Guinéu-equatoriano </option>
<option value="HT"> Haitiano </option>
<option value="NL"> Holandês </option>
<option value="HK"> Honconguês </option>
<option value="HN"> Hondurenho </option>
<option value="HU"> Húngaro </option>
<option value="YE"> Iemenita </option>
<option value="UM"> Ilha Midway </option>
<option value="NF"> Ilha Norfolk </option>
<option value="RE"> Ilha Reunião </option>
<option value="WK"> Ilha Wake </option>
<option value="IM"> Ilha de Man </option>
<option value="CX"> Ilha de Natal </option>
<option value="CC"> Ilhas Cocos </option>
<option value="FK"> Ilhas Falkland </option>
<option value="MP"> Ilhas Mariana </option>
<option value="YT"> Ilhas Mayotte </option>
<option value="TC"> Ilhas Turks e Caicos </option>
<option value="IN"> Indiano </option>
<option value="ID"> Indonésio </option>
<option value="IQ"> Iraquiano </option>
<option value="IE"> Irlandês </option>
<option value="IS"> Islandês </option>
<option value="IL"> Israelita </option>
<option value="IT"> Italiano </option>
<option value="YU"> Iugoslavo </option>
<option value="JM"> Jamaicano </option>
<option value="JP"> Japonês </option>
<option value="JO"> Jordão </option>
<option value="XK"> Kosovar </option>
<option value="KW"> Kuwaitiano </option>
<option value="LA"> Laociano </option>
<option value="LS"> Lesotiano </option>
<option value="LV"> Letoniano </option>
<option value="LB"> Libanês </option>
<option value="LR"> Liberiano </option>
<option value="LI"> Liechtensteinense </option>
<option value="LT"> Lituano </option>
<option value="LU"> Luxemburguês </option>
<option value="LY"> Líbio </option>
<option value="MO"> Macaense </option>
<option value="MK"> Macedônio </option>
<option value="MG"> Madagascarense </option>
<option value="MY"> Malaio </option>
<option value="MW"> Malauiano </option>
<option value="MV"> Maldivo </option>
<option value="ML"> Maliano </option>
<option value="MT"> Maltês </option>
<option value="CI"> Marfinense </option>
<option value="MA"> Marroquino </option>
<option value="MH"> Marshallino </option>
<option value="MQ"> Martinicano </option>
<option value="MU"> Mauriciano </option>
<option value="MR"> Mauritano </option>
<option value="MX"> Mexicano </option>
<option value="FM"> Micronésio </option>
<option value="MD"> Moldavo </option>
<option value="MC"> Monegasco </option>
<option value="MN"> Mongol </option>
<option value="MS"> Monserratense </option>
<option value="MZ"> Moçambicano </option>
<option value="NA"> Namibiano </option>
<option value="NR"> Nauruano </option>
<option value="NC"> Neocaledónio </option>
<option value="NZ"> Neozelandês </option>
<option value="NP"> Nepalês </option>
<option value="NI"> Nicaraguense </option>
<option value="NG"> Nigeriano </option>
<option value="NE"> Nigerino </option>
<option value="NU"> Niuano </option>
<option value="NO"> Noruego </option>
<option value="OM"> Omani </option>
<option value="PW"> Palauense </option>
<option value="PA"> Panamenho </option>
<option value="PG"> Papuásio </option>
<option value="PK"> Paquistanês </option>
<option value="PY"> Paraguaio </option>
<option value="PE"> Peruano </option>
<option value="PF"> Polinésio </option>
<option value="PL"> Polonês </option>
<option value="PR"> Portorriquenho </option>
<option value="PT"> Português </option>
<option value="QA"> Qatarense </option>
<option value="KE"> Queniano </option>
<option value="KG"> Quirguiz </option>
<option value="KI"> Quiribatiano </option>
<option value="RO"> Romeno </option>
<option value="RW"> Ruandês </option>
<option value="RU"> Russo </option>
<option value="SP"> Saipan </option>
<option value="SB"> Salomônico </option>
<option value="SV"> Salvadorenho </option>
<option value="SW"> Samoano </option>
<option value="AS"> Samoense </option>
<option value="SH"> Santa Helena </option>
<option value="LC"> Santa-luciense </option>
<option value="SA"> Saudita </option>
<option value="SC"> Seichelense </option>
<option value="SN"> Senegalense </option>
<option value="SL"> Serra-leonês </option>
<option value="SG"> Singapurense </option>
<option value="SO"> Somali </option>
<option value="SZ"> Suazi </option>
<option value="SE"> Sueco </option>
<option value="ZA"> Sul-africano </option>
<option value="KR"> Sul-coreano </option>
<option value="SR"> Surinamês </option>
<option value="CH"> Suíço </option>
<option value="PM"> São Pedro e Miquelão </option>
<option value="VC"> São Vicente e Granadinas </option>
<option value="KN"> São-cristovense </option>
<option value="SM"> São-marinense </option>
<option value="ST"> São-tomense </option>
<option value="RS"> Sérvio </option>
<option value="TH"> Tailandês </option>
<option value="TW"> Taiwanês </option>
<option value="TJ"> Tajique </option>
<option value="TZ"> Tanzaniano </option>
<option value="CZ"> Tcheco </option>
<option value="TG"> Togolês </option>
<option value="TO"> Tonganês </option>
<option value="TK"> Toquelauana </option>
<option value="TT"> Trinitino-tobaguense </option>
<option value="TN"> Tunisiano </option>
<option value="TR"> Turco </option>
<option value="TM"> Turcomeno </option>
<option value="TV"> Tuvaluano </option>
<option value="UA"> Ucraniano </option>
<option value="UG"> Ugandense </option>
<option value="UY"> Uruguaio </option>
<option value="UZ"> Usbeque </option>
<option value="VU"> Vanuatuense </option>
<option value="VA"> Vaticanense </option>
<option value="VE"> Venezuelano </option>
<option value="VN"> Vietnamita </option>
<option value="VI"> Virginense (Americanas) </option>
<option value="VG"> Virginense (Britânicas) </option>
<option value="WF"> Wallis Ilhéu </option>
<option value="ZR"> Zairense </option>
<option value="ZM"> Zambiano </option>
<option value="ZT"> Zanzibar </option>
<option value="ZW"> Zimbabuense </option>
`;
