import { useTheme } from '../../../themes/ThemeContextParent/ThemeContextParent';
import styles from './FileCard.module.scss';
import Badge from '../../Badge/Badge';
import DeleteIcon from '../../../assets/iconsV2/Actions/DeleteIcon';
import DownloadIcon from '../../../assets/iconsV2/Actions/DownloadIcon';
import Loader from '../../Loader/Loader';
import ReloadIcon from '../../../assets/iconsV2/Actions/ReloadIcon';
import { FileStatus } from '../FileUploader';
import GenericDocumentIcon from '../../../assets/iconsV2/Documents/GenericDocumentIcon';
import { useIntl } from '../../../intl';
import * as translations from './intl';

export interface FileCardProps {
  file: File;
  fileStatus: FileStatus;
  removeFile: () => Promise<void>;
  reloadFile: () => Promise<void>;
}

const FileCard = ({
  file,
  fileStatus,
  removeFile,
  reloadFile,
}: FileCardProps) => {
  const { getGlobalTheme, getCurrentThemeColors, getColors } = useTheme();

  const { translate } = useIntl();
  const intl = translate(translations);

  const theme = getGlobalTheme();
  const { const_gray } = getCurrentThemeColors();
  const { genericColors } = getColors();

  const isLoading = fileStatus === 'SENDING';
  const isError = fileStatus === 'NOT SENT';
  const isSent = fileStatus === 'SENT';

  const badgeLabel = isSent ? 'Uploaded' : 'Error';
  const badgeStatus = isSent ? 'POSITIVE' : 'NEGATIVE';

  const handleDownloadFile = async () => {
    const url = window.URL.createObjectURL(file);

    const link = document.createElement('a');
    link.href = url;
    link.download = file.name;
    link.click();
  };

  const handleDeleteFile = async () => {
    await removeFile();
  };

  const handleReloadFile = async () => {
    await reloadFile();
  };

  return (
    <li className={styles.FileCardItem}>
      <div
        className={`${styles.content} ${styles[theme]}`}
        style={{ borderColor: isError ? genericColors.red_70 : const_gray }}
      >
        <GenericDocumentIcon />
        <span className={styles.fileName}>{file?.name}</span>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className={styles.actionsArea}>
              {isError ? (
                <button onClick={handleReloadFile}>
                  <ReloadIcon />
                </button>
              ) : (
                <>
                  <button onClick={handleDownloadFile}>
                    <DownloadIcon />
                  </button>
                </>
              )}
              <button onClick={handleDeleteFile}>
                <DeleteIcon />
              </button>
            </div>
            <Badge label={badgeLabel} type={badgeStatus} icon />
          </>
        )}
      </div>
      {isError && <span className={styles.error}>{intl.ERROR_TEXT}</span>}
    </li>
  );
};

export default FileCard;
